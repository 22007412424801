export const defineSourceAndUnit = (value: string | undefined) => {
  let source = "";
  let unit = "";
  let approximatelyEquals = "";
  let coefficient = ""
  switch (value) {
    case "0":
      source = "pages.savingCalculator.configurationBlock.oil";
      unit = "m³";
      approximatelyEquals = "10 225 kWh";
      coefficient = "80"
      break;
    case "1":
      source = "pages.savingCalculator.configurationBlock.naturalGas";
      unit = "nm³";
      approximatelyEquals = "10.3 kWh";
      coefficient = "65"
      break;
    case "2":
      source = "pages.savingCalculator.configurationBlock.electricity";
      unit = "kWh";
      coefficient = "95"
      break;
    case "3":
      source = "pages.savingCalculator.configurationBlock.districtHeating";
      unit = "kWh";
      coefficient = "95"
      break;
    case "4":
      source = "pages.savingCalculator.configurationBlock.other";
      unit = "kWh";
      coefficient = "100"
      break;
    default:
      break;
  }
  return { source, unit, approximatelyEquals, coefficient };
};
