import React, { useEffect, useState } from "react";
import useTheme from "../../../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAppDispatch } from "../../../../../../../../store";
import {
  installationApi,
  useGetInstallationSettingsQuery,
  useSetInstallationSettingsMutation,
} from "../../../../../../../../store/services/installation";
import TabWrapper from "../../../../TabWrapper";
import MainTitle from "../../../../components/MainTitle/MainTitle";
import {
  IInstallationSettingsByIdResponse,
  ISetDhwSettings,
  ISetInstallationSettings,
  ISetInstallerSettignsComissioningAdditionalDHWHeatingSource,
} from "../../../../../../../../store/services/models/installations/installationSettings";
import SettingsSwitcher from "../../../../components/SettingsSwitcher/SettingsSwitcher";
import { Box, Divider } from "@mui/material";
import NumberInput from "../../../../components/NumberInput/NumberInput";
import SecondaryTitle from "../../../../components/SecondaryTitle/SecondaryTitle";
import {
  ActiveOrInactiveEnum,
  IInstallationBaseValue,
} from "../../../../../../../../store/services/models/installations/installationsCommon";
import UnsavedChangesModal from "../../../../components/UnsavedChangesModal/UnsavedChangesModal";
import {
  baseFindDifferences,
  cleanValuesForRequest,
} from "../../../../helpers/findDifferences";
import ConfirmChangesModal from "../../../../components/ConfirmChangesModal/ConfirmChangesModal";
import { Tool02 } from "untitledui-js-base";
import NotificationModal from "../../../../components/NotificationModal/NotificationModal";
import DifferencesToShowInModal from "./DifferencesToShowInModal";

export interface IInstallerDHWSettingsData {
  preferredTemp: IInstallationBaseValue;
  deltaDeviationTemp: IInstallationBaseValue;
  state: ActiveOrInactiveEnum;
  inputPower: IInstallationBaseValue;
}

export default function InstallerDHW() {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  const {
    data: settings,
    isFetching: isFetchingSettings,
    isLoading: isLoadingSettings,
    refetch: refetchSettings,
  } = useGetInstallationSettingsQuery({
    uuid: id!,
    isRealData: params.get("dummyData") === "false",
  });

  const [setInstallationSettings] = useSetInstallationSettingsMutation();
  const [settingsParameters, setSettingsParameters] = useState<
    IInstallationSettingsByIdResponse | undefined
  >(settings);

  const [installerDHWSettingsData, setInstallerDHWSettingsData] =
    useState<IInstallerDHWSettingsData>();
  const [initialInstallerDHWSettingsData, setInitialInstallerDHWSettingsData] =
    useState<IInstallerDHWSettingsData>();

  const [initialSettings, setInitialSettings] =
    useState<IInstallationSettingsByIdResponse | null>(null);

  const [isSomethingChanged, setIsSomethingChanged] = useState(false);
  const [isConfirmChangesModalOpen, setIsConfirmChangesModalOpen] =
    useState<boolean>(false);
  const [differencesToShow, setDifferencesToShow] = useState<any>();
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (settings) {
      setSettingsParameters(settings);
    }
  }, [settings]);

  useEffect(() => {
    if (settingsParameters) {
      setInitialSettings(JSON.parse(JSON.stringify(settingsParameters)));
      const data = {
        preferredTemp: settingsParameters.dhw.preferredTemp,
        deltaDeviationTemp: settingsParameters.dhw.deltaDeviationTemp,
        state:
          settingsParameters.installerSettings.commissioning
            .additionalDhwHeatingSource.state,
        inputPower:
          settingsParameters.installerSettings.commissioning
            .additionalDhwHeatingSource.inputPower,
      };
      setInstallerDHWSettingsData(JSON.parse(JSON.stringify(data)));
      setInitialInstallerDHWSettingsData(JSON.parse(JSON.stringify(data)));
    }
  }, [settingsParameters]);

  const checkForChanges = () => {
    if (
      JSON.stringify(installerDHWSettingsData) !==
      JSON.stringify(initialInstallerDHWSettingsData)
    ) {
      setIsSomethingChanged(true);
    } else {
      setIsSomethingChanged(false);
    }
  };

  useEffect(() => {
    checkForChanges();
  }, [installerDHWSettingsData]);

  const updateInstallerDHWSettings = (field: string, value: any) => {
    const updatedSettings = { ...installerDHWSettingsData };
    (updatedSettings as any)[field] = value;
    setInstallerDHWSettingsData(updatedSettings as IInstallerDHWSettingsData);
  };

  const onDiscardChanges = () => {
    setInstallerDHWSettingsData(
      JSON.parse(JSON.stringify(initialInstallerDHWSettingsData))
    );
  };

  const onSaveChanges = () => {
    const differences = baseFindDifferences(
      initialInstallerDHWSettingsData!,
      installerDHWSettingsData!
    );
    setDifferencesToShow(differences);

    setIsConfirmChangesModalOpen(true);
  };

  const createRequest = () => {
    let newDHWSettings: Partial<
      ISetDhwSettings &
        ISetInstallerSettignsComissioningAdditionalDHWHeatingSource
    > = cleanValuesForRequest(differencesToShow);

    const result: any = {};

    if (newDHWSettings.preferredTemp || newDHWSettings.deltaDeviationTemp) {
      result.dhw = {};

      if (newDHWSettings.preferredTemp) {
        result.dhw.preferredTemp = newDHWSettings.preferredTemp;
      }
      if (newDHWSettings.deltaDeviationTemp) {
        result.dhw.deltaDeviationTemp = newDHWSettings.deltaDeviationTemp;
      }
    }

    if (newDHWSettings.state || newDHWSettings.inputPower) {
      result.installerSettings = {
        commissioning: {
          additionalDhwHeatingSource: {},
        },
      };

      if (newDHWSettings.state) {
        result.installerSettings.commissioning.additionalDhwHeatingSource.state =
          newDHWSettings.state;
      }

      if (newDHWSettings.inputPower) {
        result.installerSettings.commissioning.additionalDhwHeatingSource.inputPower =
          newDHWSettings.inputPower;
      }
    }

    return result;
  };

  const onConfirmSettigsChanges = () => {
    const updatedSettings = { ...initialSettings };
    updatedSettings.dhw = {
      ...(updatedSettings.dhw as any),
      deltaDeviationTemp: installerDHWSettingsData!.deltaDeviationTemp!,
      preferredTemp: installerDHWSettingsData!.preferredTemp!,
    };
    updatedSettings.installerSettings = {
      ...(updatedSettings.installerSettings as any),
      commissioning: {
        ...(updatedSettings.installerSettings?.commissioning as any),
        additionalDhwHeatingSource: {
          ...(updatedSettings.installerSettings?.commissioning
            .additionalDhwHeatingSource as any),
          inputPower: installerDHWSettingsData?.inputPower!,
          state: installerDHWSettingsData?.state!,
        },
      },
    };
    setIsConfirmChangesModalOpen(false);
    const requestBody = createRequest();

    setInstallationSettings({
      ...(requestBody as ISetInstallationSettings),
      uuid: id!,
    })
      .unwrap()
      .then(() => {
        dispatch(
          installationApi.util.updateQueryData(
            "getInstallationSettings",
            { uuid: id!, isRealData: params.get("dummyData") === "false" },
            (draftSettings) => {
              Object.assign(draftSettings, updatedSettings);
            }
          )
        );
        setIsNotificationModalOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <TabWrapper
      onDiscardPress={onDiscardChanges}
      onSaveChangesPress={onSaveChanges}
      isDiscardDisabled={!isSomethingChanged}
      isSaveDisabled={!isSomethingChanged}
    >
      <MainTitle
        title={t("pages.installationSettings.installerSettings.dhw.mainTitle")}
      />
      <Box sx={{ mt: theme.spacing.spacingXl }} />

      <SettingsSwitcher
        label={`${t(
          "pages.installationSettings.installerSettings.dhw.fieldsLabels.dhwTank"
        )}`}
        state={
          //   DHWSettingsData?.antiLegionella?.state === ActiveOrInactiveEnum.ACTIVE
          //     ? true
          //     : false
          true
        }
        changeState={(val) => {
          //   updateAntiLegionellaSettings(
          //     val ? ActiveOrInactiveEnum.ACTIVE : ActiveOrInactiveEnum.INACTIVE,
          //     "state"
          //   );
          {
          }
        }}
      />
      <NumberInput
        initialValue={installerDHWSettingsData?.preferredTemp.value}
        changeValue={(val) => {
          updateInstallerDHWSettings("preferredTemp", {
            ...installerDHWSettingsData?.preferredTemp,
            value: val,
          });
        }}
        decimalPlaces={1}
        step={0.5}
        hint={`${t(
          "pages.installationSettings.dhw.fieldsHints.DHWTemperatureSetPoint",
          {
            from: settings?.installerSettings.commissioning.dhw.dhwMinTemp
              .value,
            to: settings?.installerSettings.commissioning.dhw.dhwMaxTemp.value,
          }
        )}`}
        label={`${t(
          "pages.installationSettings.dhw.fieldsLabels.DHWTemperatureSetPoint"
        )}`}
        min={settings?.installerSettings.commissioning.dhw.dhwMinTemp.value}
        max={settings?.installerSettings.commissioning.dhw.dhwMaxTemp.value}
      />
      <NumberInput
        initialValue={installerDHWSettingsData?.deltaDeviationTemp.value}
        changeValue={(val) => {
          updateInstallerDHWSettings("deltaDeviationTemp", {
            ...installerDHWSettingsData?.deltaDeviationTemp,
            value: val,
          });
        }}
        decimalPlaces={1}
        step={0.5}
        label={`${t(
          "pages.installationSettings.installerSettings.dhw.fieldsLabels.dhwRestartDeltaT"
        )}`}
        min={-50}
        max={50}
      />
      <Divider
        sx={{
          width: "100%",
          borderColor: theme.colors.colorsBorderBorderSecondary,
        }}
      />
      <SecondaryTitle
        title={`${t(
          "pages.installationSettings.installerSettings.dhw.secondaryTitle"
        )}`}
        variant="md"
      />
      <SettingsSwitcher
        label={`${t(
          "pages.installationSettings.installerSettings.dhw.fieldsLabels.additionalDHWTankHeatingSource"
        )}`}
        state={
          installerDHWSettingsData?.state === ActiveOrInactiveEnum.ACTIVE
            ? true
            : false
        }
        changeState={(val) => {
          updateInstallerDHWSettings(
            "state",
            val ? ActiveOrInactiveEnum.ACTIVE : ActiveOrInactiveEnum.INACTIVE
          );
        }}
      />
      <NumberInput
        initialValue={installerDHWSettingsData?.inputPower.value}
        changeValue={(val) => {
          updateInstallerDHWSettings("inputPower", {
            ...installerDHWSettingsData?.inputPower,
            value: val,
          });
        }}
        decimalPlaces={1}
        unit={"kW"}
        step={0.5}
        hint={`${t(
          "pages.installationSettings.installerSettings.dhw.fieldsHints.inputPower"
        )}`}
        label={`${t(
          "pages.installationSettings.installerSettings.dhw.fieldsLabels.inputPower"
        )}`}
        min={0}
        max={10000}
        inputWidth="105px"
      />
      <UnsavedChangesModal
        tabName={t("pages.installationSettings.tabsNames.installerDHW")}
        condition={isSomethingChanged}
      />
      {isConfirmChangesModalOpen && (
        <ConfirmChangesModal
          isOpen={isConfirmChangesModalOpen}
          onCancel={() => setIsConfirmChangesModalOpen(false)}
          onOk={() => onConfirmSettigsChanges()}
          page={`${t("pages.installationSettings.tabsNames.installerDHW")}`}
          block={`${t(
            "pages.installationSettings.tabsNames.installerSettings"
          )}`}
          showHeatPumpNote={differencesToShow.state}
          icon={
            <Tool02
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        >
          <DifferencesToShowInModal differencesToShow={differencesToShow} />
          <></>
        </ConfirmChangesModal>
      )}
      {isNotificationModalOpen && (
        <NotificationModal
          isOpen={isNotificationModalOpen}
          onOkPress={() => setIsNotificationModalOpen(false)}
          icon={
            <Tool02
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        />
      )}
    </TabWrapper>
  );
}
