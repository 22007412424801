import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";

export interface OrganizationsState {
}

const initialState: OrganizationsState = {
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

// Action creators are generated for each case reducer function
// export const { logout, clearLoginError, clearSignUpError, updateToken } = installationSlice.actions;

export default organizationSlice.reducer;