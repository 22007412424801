import React, { useEffect, useState } from "react";
import TabWrapper from "../../TabWrapper";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAppDispatch } from "../../../../../../store";
import {
  installationApi,
  useGetInstallationSettingsQuery,
  useSetInstallationSettingsMutation,
} from "../../../../../../store/services/installation";
import MainTitle from "../../components/MainTitle/MainTitle";
import SecondaryTitle from "../../components/SecondaryTitle/SecondaryTitle";
import SpaceRadioGroup, {
  ISpaceRadioGroupItem,
} from "./SpaceRadioGroup/SpaceRadioGropup";
import {
  IInstallationSettingsByIdResponse,
  IInstallationsOperationSettings,
  ISetInstallationSettings,
  ISetOperationSettings,
  InstallationOperationSpaceModeEnum,
  InstallationZoneOperationModeEnum,
} from "../../../../../../store/services/models/installations/installationSettings";
import { ReactComponent as HeatingCoolingIcon } from "./SpaceRadioGroup/icons/operation-automatic-heating-cooling.svg";
import { ReactComponent as HeatingIcon } from "./SpaceRadioGroup/icons/operation-automatic-heating.svg";
import { ReactComponent as CoolingIcon } from "./SpaceRadioGroup/icons/operation-automatic-cooling.svg";
import { ReactComponent as AllOffIcon } from "./SpaceRadioGroup/icons/operation-all-off.svg";
import {
  OnOrOffEnum,
  ZoneOperationsKey,
} from "../../../../../../store/services/models/installations/installationsCommon";
import UnsavedChangesModal from "../../components/UnsavedChangesModal/UnsavedChangesModal";
import { Divider } from "@mui/material";
import SettingsSwitcher from "../../components/SettingsSwitcher/SettingsSwitcher";
import {
  baseFindDifferences,
  cleanValuesForRequest,
} from "../../helpers/findDifferences";
import ConfirmChangesModal from "../../components/ConfirmChangesModal/ConfirmChangesModal";
import DefaultChange from "../../components/ConfirmChangesModal/DefaultChage";
import { ReactComponent as SystemOperationIcon } from "../../../RealTimeData/SectionIcons/SystemOperationIcon.svg";
import NotificationModal from "../../components/NotificationModal/NotificationModal";

export default function Operation() {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.installationSettings.operation",
  });
  const { t: mainTranslation } = useTranslation("cloud_ui");
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  const {
    data: settings,
    isFetching: isFetchingSettings,
    isLoading: isLoadingSettings,
    refetch: refetchSettings,
  } = useGetInstallationSettingsQuery({
    uuid: id!,
    isRealData: params.get("dummyData") === "false",
  });

  const [setInstallationSettings] = useSetInstallationSettingsMutation();

  const [spaceRadioGroupOptions, setSpaceRadioGroupOptions] = useState<
    Array<ISpaceRadioGroupItem>
  >([
    {
      title: t("spaceRadioOptions.titles.heatingCooling"),
      description: t("spaceRadioOptions.descriptions.heatingCooling"),
      value: InstallationOperationSpaceModeEnum.AUTO,
      icon: <HeatingCoolingIcon strokeWidth={2} />,
    },
    {
      title: t("spaceRadioOptions.titles.heating"),
      description: t("spaceRadioOptions.descriptions.heating"),
      value: InstallationOperationSpaceModeEnum.HEATING,
      icon: <HeatingIcon strokeWidth={2} />,
    },
    {
      title: t("spaceRadioOptions.titles.cooling"),
      description: t("spaceRadioOptions.descriptions.cooling"),
      value: InstallationOperationSpaceModeEnum.COOLING,
      icon: <CoolingIcon strokeWidth={2} />,
    },
    {
      title: t("spaceRadioOptions.titles.off"),
      description: t("spaceRadioOptions.descriptions.off"),
      value: InstallationOperationSpaceModeEnum.OFF,
      icon: <AllOffIcon strokeWidth={2} />,
    },
  ]);

  const getSpaceTitle = (value: InstallationOperationSpaceModeEnum) => {
    switch (value) {
      case InstallationOperationSpaceModeEnum.AUTO:
        return t("spaceRadioOptions.titles.heatingCooling");
      case InstallationOperationSpaceModeEnum.HEATING:
        return t("spaceRadioOptions.titles.heating");
      case InstallationOperationSpaceModeEnum.COOLING:
        return t("spaceRadioOptions.titles.cooling");
      default:
        return t("spaceRadioOptions.titles.off");
    }
  };

  const [settingsParameters, setSettingsParameters] = useState<
    IInstallationSettingsByIdResponse | undefined
  >(settings);

  const [operationSettingsData, setOperationSettingsData] =
    useState<IInstallationsOperationSettings>();
  const [initalOperationSettingsData, setInitalOperationSettingsData] =
    useState<IInstallationsOperationSettings>();
  const [initialSettings, setInitialSettings] =
    useState<IInstallationSettingsByIdResponse | null>(null);

  const [zonesModes, setZonesModes] = useState<
    Array<InstallationZoneOperationModeEnum>
  >([]);

  const [isSomethingChanged, setIsSomethingChanged] = useState(false);
  const [isConfirmChangesModalOpen, setIsConfirmChangesModalOpen] =
    useState<boolean>(false);
  const [differencesToShow, setDifferencesToShow] = useState<any>();
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (settings) {
      setSettingsParameters(settings);
    }
  }, [settings]);

  useEffect(() => {
    if (settingsParameters) {
      setOperationSettingsData(
        JSON.parse(JSON.stringify(settingsParameters.operation))
      );
      setInitalOperationSettingsData(
        JSON.parse(JSON.stringify(settingsParameters.operation))
      );
      setInitialSettings(JSON.parse(JSON.stringify(settingsParameters)));

      const comissioningZones =
        settingsParameters.installerSettings?.commissioning?.zoneOperations;
      if (comissioningZones) {
        let modesArray: Array<InstallationZoneOperationModeEnum> = [];
        Object.keys(comissioningZones).map((zoneKey) => {
          modesArray.push(
            comissioningZones[zoneKey as ZoneOperationsKey]?.mode!
          );
        });
        const isHeatingAndCooling = modesArray.includes(
          InstallationZoneOperationModeEnum.BOTH
        );
        const isHeating = modesArray.includes(
          InstallationZoneOperationModeEnum.COOLING
        );
        const isCooling = modesArray.includes(
          InstallationZoneOperationModeEnum.HEATING
        );
        setSpaceRadioGroupOptions((prev) =>
          prev.map((option) => {
            switch (option.value) {
              case InstallationOperationSpaceModeEnum.AUTO:
                return {
                  ...option,
                  disabled: !(isHeatingAndCooling || (isHeating && isCooling)),
                };
              case InstallationOperationSpaceModeEnum.HEATING:
                return {
                  ...option,
                  disabled: !(isHeatingAndCooling || isCooling),
                };
              case InstallationOperationSpaceModeEnum.COOLING:
                return {
                  ...option,
                  disabled: !(isHeatingAndCooling || isHeating),
                };
              default:
                return option;
            }
          })
        );
      }
    }
  }, [settingsParameters]);

  const checkForChanges = () => {
    if (
      JSON.stringify(operationSettingsData) !==
      JSON.stringify(initalOperationSettingsData)
    ) {
      setIsSomethingChanged(true);
    } else {
      setIsSomethingChanged(false);
    }
  };

  useEffect(() => {
    checkForChanges();
  }, [operationSettingsData]);

  const onDiscardChanges = () => {
    setOperationSettingsData(
      JSON.parse(JSON.stringify(initalOperationSettingsData))
    );
  };

  const onSaveChanges = () => {
    const differences = baseFindDifferences(
      initalOperationSettingsData!,
      operationSettingsData!
    );

    setDifferencesToShow(differences);

    setIsConfirmChangesModalOpen(true);
  };

  const createRequest = () => {
    let newOperationSettings: Partial<ISetOperationSettings> =
      cleanValuesForRequest(differencesToShow);

    return {
      operation: newOperationSettings,
    };
  };

  const onConfirmSettigsChanges = () => {
    const updatedSettings = { ...initialSettings };
    updatedSettings.operation = operationSettingsData;
    setIsConfirmChangesModalOpen(false);
    const requestBody = createRequest();
    setInstallationSettings({
      ...(requestBody as ISetInstallationSettings),
      uuid: id!,
    })
      .unwrap()
      .then(() => {
        dispatch(
          installationApi.util.updateQueryData(
            "getInstallationSettings",
            { uuid: id!, isRealData: params.get("dummyData") === "false" },
            (draftSettings) => {
              Object.assign(draftSettings, updatedSettings);
            }
          )
        );
        setIsNotificationModalOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <TabWrapper
      onDiscardPress={onDiscardChanges}
      onSaveChangesPress={onSaveChanges}
      isDiscardDisabled={!isSomethingChanged}
      isSaveDisabled={!isSomethingChanged}
    >
      <MainTitle title={t("mainTitle")} />
      <SecondaryTitle title={t("spaceTitle")} variant="md" />
      <SpaceRadioGroup
        radioOptions={spaceRadioGroupOptions}
        selectedValue={operationSettingsData?.spaceMode}
        onChange={(val: InstallationOperationSpaceModeEnum) => {
          const updatedOperationSettingsData = { ...operationSettingsData };
          updatedOperationSettingsData.spaceMode = val;
          setOperationSettingsData(
            updatedOperationSettingsData as IInstallationsOperationSettings
          );
        }}
      />
      <Divider
        sx={{
          width: "100%",
          borderColor: theme.colors.colorsBorderBorderSecondary,
        }}
      />
      <SecondaryTitle title={t("DHWTitle")} variant="md" />
      <SettingsSwitcher
        state={
          operationSettingsData?.dhwState === OnOrOffEnum.ON ? true : false
        }
        changeState={(val) => {
          const updatedOperationSettingsData = { ...operationSettingsData };
          updatedOperationSettingsData.dhwState = val
            ? OnOrOffEnum.ON
            : OnOrOffEnum.OFF;
          setOperationSettingsData(
            updatedOperationSettingsData as IInstallationsOperationSettings
          );
        }}
      />
      {/* <Divider
        sx={{
          width: "100%",
          borderColor: theme.colors.colorsBorderBorderSecondary,
        }}
      /> */}
      <UnsavedChangesModal
        tabName={mainTranslation(
          "pages.installationSettings.tabsNames.operation"
        )}
        condition={isSomethingChanged}
      />
      {isConfirmChangesModalOpen && (
        <ConfirmChangesModal
          isOpen={isConfirmChangesModalOpen}
          onCancel={() => setIsConfirmChangesModalOpen(false)}
          onOk={() => onConfirmSettigsChanges()}
          page={`${mainTranslation(
            "pages.installationSettings.tabsNames.operation"
          )}`}
          block={`${mainTranslation(
            "pages.installationSettings.tabsNames.operation"
          )}`}
          icon={
            <SystemOperationIcon
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        >
          <>
            {differencesToShow?.spaceMode && (
              <DefaultChange
                changedValue={getSpaceTitle(
                  differencesToShow?.spaceMode.newValue
                )}
                previousValue={getSpaceTitle(
                  differencesToShow?.spaceMode.previousValue
                )}
                title={`${t("spaceTitle")}`}
              />
            )}
            {differencesToShow?.dhwState && (
              <DefaultChange
                changedValue={differencesToShow?.dhwState.newValue}
                previousValue={differencesToShow?.dhwState.previousValue}
                title={`${t("DHWTitle")}`}
              />
            )}
          </>
        </ConfirmChangesModal>
      )}
      {isNotificationModalOpen && (
        <NotificationModal
          isOpen={isNotificationModalOpen}
          onOkPress={() => setIsNotificationModalOpen(false)}
          icon={
            <SystemOperationIcon
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        />
      )}
    </TabWrapper>
  );
}
