import React, { useEffect, useState } from "react";
import useTheme from "../../../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAppDispatch } from "../../../../../../../../store";
import {
  installationApi,
  useGetInstallationSettingsQuery,
  useSetInstallationSettingsMutation,
} from "../../../../../../../../store/services/installation";
import {
  IInstallationSettingsByIdResponse,
  IPriorities,
  ISetInstallationSettings,
  ISetInstallerSettignsComissioningPriorities,
} from "../../../../../../../../store/services/models/installations/installationSettings";
import TabWrapper from "../../../../TabWrapper";
import MainTitle from "../../../../components/MainTitle/MainTitle";
import NumberInput from "../../../../components/NumberInput/NumberInput";
import { Box, Divider, Typography } from "@mui/material";
import SecondaryTitle from "../../../../components/SecondaryTitle/SecondaryTitle";
import {
  baseFindDifferences,
  cleanValuesForRequest,
} from "../../../../helpers/findDifferences";
import UnsavedChangesModal from "../../../../components/UnsavedChangesModal/UnsavedChangesModal";
import ConfirmChangesModal from "../../../../components/ConfirmChangesModal/ConfirmChangesModal";
import { Tool02 } from "untitledui-js-base";
import DefaultChange from "../../../../components/ConfirmChangesModal/DefaultChage";
import NotificationModal from "../../../../components/NotificationModal/NotificationModal";

export default function ShiftingPriorities() {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  const {
    data: settings,
    isFetching: isFetchingSettings,
    isLoading: isLoadingSettings,
    refetch: refetchSettings,
  } = useGetInstallationSettingsQuery({
    uuid: id!,
    isRealData: params.get("dummyData") === "false",
  });

  const [setInstallationSettings] = useSetInstallationSettingsMutation();
  const [settingsParameters, setSettingsParameters] = useState<
    IInstallationSettingsByIdResponse | undefined
  >(settings);

  const [shiftingPrioritiesSettingsData, setShiftingPrioritiesSettingsData] =
    useState<IPriorities>();
  const [
    initialShiftingPrioritiesSettingsData,
    setInitialShiftingPrioritiesSettingsData,
  ] = useState<IPriorities>();

  const [initialSettings, setInitialSettings] =
    useState<IInstallationSettingsByIdResponse | null>(null);

  const [isSomethingChanged, setIsSomethingChanged] = useState(false);
  const [isConfirmChangesModalOpen, setIsConfirmChangesModalOpen] =
    useState<boolean>(false);
  const [differencesToShow, setDifferencesToShow] = useState<any>();
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (settings) {
      setSettingsParameters(settings);
    }
  }, [settings]);

  useEffect(() => {
    if (settingsParameters) {
      setInitialSettings(JSON.parse(JSON.stringify(settingsParameters)));
      setShiftingPrioritiesSettingsData(
        JSON.parse(
          JSON.stringify(
            settingsParameters.installerSettings.commissioning.priorities
          )
        )
      );
      setInitialShiftingPrioritiesSettingsData(
        JSON.parse(
          JSON.stringify(
            settingsParameters.installerSettings.commissioning.priorities
          )
        )
      );
    }
  }, [settingsParameters]);

  const checkForChanges = () => {
    if (
      JSON.stringify(shiftingPrioritiesSettingsData) !==
      JSON.stringify(initialShiftingPrioritiesSettingsData)
    ) {
      setIsSomethingChanged(true);
    } else {
      setIsSomethingChanged(false);
    }
  };

  useEffect(() => {
    checkForChanges();
  }, [shiftingPrioritiesSettingsData]);

  const updateSettings = (field: string, value: any) => {
    const updatedSettings = { ...shiftingPrioritiesSettingsData };
    (updatedSettings as any)[field] = value;
    setShiftingPrioritiesSettingsData(updatedSettings as IPriorities);
  };

  const onDiscardChanges = () => {
    setShiftingPrioritiesSettingsData(
      JSON.parse(JSON.stringify(initialShiftingPrioritiesSettingsData))
    );
  };

  const onSaveChanges = () => {
    const differences = baseFindDifferences(
      initialShiftingPrioritiesSettingsData!,
      shiftingPrioritiesSettingsData!
    );
    setDifferencesToShow(differences);

    setIsConfirmChangesModalOpen(true);
  };

  const createRequest = () => {
    let newSettings: Partial<ISetInstallerSettignsComissioningPriorities> =
      cleanValuesForRequest(differencesToShow);

    return {
      installerSettings: {
        commissioning: {
          priorities: newSettings,
        },
      },
    };
  };

  const onConfirmSettigsChanges = () => {
    const updatedSettings = { ...initialSettings };
    updatedSettings.installerSettings = {
      ...(updatedSettings.installerSettings as any),
      commissioning: {
        ...(updatedSettings.installerSettings?.commissioning as any),
        priorities: shiftingPrioritiesSettingsData,
      },
    };
    setIsConfirmChangesModalOpen(false);
    const requestBody = createRequest();
    setInstallationSettings({
      ...(requestBody as ISetInstallationSettings),
      uuid: id!,
    })
      .unwrap()
      .then(() => {
        dispatch(
          installationApi.util.updateQueryData(
            "getInstallationSettings",
            { uuid: id!, isRealData: params.get("dummyData") === "false" },
            (draftSettings) => {
              Object.assign(draftSettings, updatedSettings);
            }
          )
        );
        setIsNotificationModalOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <TabWrapper
      onDiscardPress={onDiscardChanges}
      onSaveChangesPress={onSaveChanges}
      isDiscardDisabled={!isSomethingChanged}
      isSaveDisabled={!isSomethingChanged}
    >
      <MainTitle
        title={t(
          "pages.installationSettings.installerSettings.shiftingPriorities.mainTitle"
        )}
      />
      <Box sx={{ mt: theme.spacing.spacingXl }} />
      <SecondaryTitle
        title={`${t(
          "pages.installationSettings.installerSettings.shiftingPriorities.heating"
        )}`}
        variant="md"
      />
      <NumberInput
        initialValue={shiftingPrioritiesSettingsData?.heatingMinTemp.value}
        changeValue={(val) =>
          updateSettings("heatingMinTemp", {
            ...shiftingPrioritiesSettingsData?.heatingMinTemp,
            value: val,
          })
        }
        decimalPlaces={1}
        unit={""}
        step={0.5}
        label={`${t(
          "pages.installationSettings.installerSettings.shiftingPriorities.fieldsLabels.heatingMin"
        )}`}
        min={0}
        max={10000}
      />
      <NumberInput
        initialValue={shiftingPrioritiesSettingsData?.heatingDhwMinTemp.value}
        changeValue={(val) =>
          updateSettings("heatingDhwMinTemp", {
            ...shiftingPrioritiesSettingsData?.heatingDhwMinTemp,
            value: val,
          })
        }
        decimalPlaces={1}
        unit={""}
        step={0.5}
        label={`${t(
          "pages.installationSettings.installerSettings.shiftingPriorities.fieldsLabels.dhwMin"
        )}`}
        min={0}
        max={10000}
      />
      <Divider
        sx={{
          width: "100%",
          borderColor: theme.colors.colorsBorderBorderSecondary,
        }}
      />
      <SecondaryTitle
        title={`${t(
          "pages.installationSettings.installerSettings.shiftingPriorities.cooling"
        )}`}
        variant="md"
      />
      <NumberInput
        initialValue={shiftingPrioritiesSettingsData?.coolingMinTemp?.value}
        changeValue={(val) =>
          updateSettings("coolingMinTemp", {
            ...shiftingPrioritiesSettingsData?.coolingMinTemp,
            value: val,
          })
        }
        decimalPlaces={1}
        unit={""}
        step={0.5}
        label={`${t(
          "pages.installationSettings.installerSettings.shiftingPriorities.fieldsLabels.coolingMin"
        )}`}
        min={0}
        max={10000}
      />
      <NumberInput
        initialValue={shiftingPrioritiesSettingsData?.coolingDhwMinTemp?.value}
        changeValue={(val) =>
          updateSettings("coolingDhwMinTemp", {
            ...shiftingPrioritiesSettingsData?.coolingDhwMinTemp,
            value: val,
          })
        }
        decimalPlaces={1}
        unit={""}
        step={0.5}
        label={`${t(
          "pages.installationSettings.installerSettings.shiftingPriorities.fieldsLabels.dhwMin"
        )}`}
        min={0}
        max={10000}
      />
      <UnsavedChangesModal
        tabName={t(
          "pages.installationSettings.tabsNames.installerShiftingPriorities"
        )}
        condition={isSomethingChanged}
      />
      {isConfirmChangesModalOpen && (
        <ConfirmChangesModal
          isOpen={isConfirmChangesModalOpen}
          onCancel={() => setIsConfirmChangesModalOpen(false)}
          onOk={() => onConfirmSettigsChanges()}
          page={`${t(
            "pages.installationSettings.tabsNames.installerShiftingPriorities"
          )}`}
          block={`${t(
            "pages.installationSettings.tabsNames.installerSettings"
          )}`}
          // showHeatPumpNote={differencesToShow.state}
          icon={
            <Tool02
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        >
          <>
            {(differencesToShow.heatingDhwMinTemp ||
              differencesToShow.heatingMinTemp) && (
              <Typography
                sx={{
                  ...theme.typography[".text-md-semibold"],
                  color: theme.colors.colorsTextTextPrimary,
                  mb: theme.spacing.spacingXl,
                }}
              >
                {t(
                  "pages.installationSettings.installerSettings.shiftingPriorities.heating"
                )}
              </Typography>
            )}
            {differencesToShow?.heatingMinTemp && (
              <DefaultChange
                changedValue={differencesToShow?.heatingMinTemp.value.newValue}
                previousValue={
                  differencesToShow?.heatingMinTemp.value.previousValue
                }
                title={`${t(
                  "pages.installationSettings.installerSettings.shiftingPriorities.fieldsLabels.heatingMin"
                )}`}
              />
            )}
            {differencesToShow?.heatingDhwMinTemp && (
              <DefaultChange
                changedValue={
                  differencesToShow?.heatingDhwMinTemp.value.newValue
                }
                previousValue={
                  differencesToShow?.heatingDhwMinTemp.value.previousValue
                }
                title={`${t(
                  "pages.installationSettings.installerSettings.shiftingPriorities.fieldsLabels.dhwMin"
                )}`}
              />
            )}
            {(differencesToShow.heatingDhwMinTemp ||
              differencesToShow.heatingMinTemp) && (
              <Divider
                sx={{
                  width: "100%",
                  borderColor: theme.colors.colorsBorderBorderSecondary,
                }}
              />
            )}

            {(differencesToShow.coolingMinTemp ||
              differencesToShow.coolingDhwMinTemp) && (
              <Typography
                sx={{
                  ...theme.typography[".text-md-semibold"],
                  color: theme.colors.colorsTextTextPrimary,
                  mb: theme.spacing.spacingXl,
                  mt: theme.spacing.spacingXl,
                }}
              >
                {t(
                  "pages.installationSettings.installerSettings.shiftingPriorities.cooling"
                )}
              </Typography>
            )}
            {differencesToShow?.coolingMinTemp && (
              <DefaultChange
                changedValue={
                  differencesToShow?.coolingMinTemp?.value?.newValue
                }
                previousValue={
                  differencesToShow?.coolingMinTemp?.value?.previousValue
                }
                title={`${t(
                  "pages.installationSettings.installerSettings.shiftingPriorities.fieldsLabels.coolingMin"
                )}`}
              />
            )}
            {differencesToShow?.coolingDhwMinTemp && (
              <DefaultChange
                changedValue={
                  differencesToShow?.coolingDhwMinTemp?.value?.newValue
                }
                previousValue={
                  differencesToShow?.coolingDhwMinTemp?.value?.previousValue
                }
                title={`${t(
                  "pages.installationSettings.installerSettings.shiftingPriorities.fieldsLabels.dhwMin"
                )}`}
              />
            )}
          </>
        </ConfirmChangesModal>
      )}
      {isNotificationModalOpen && (
        <NotificationModal
          isOpen={isNotificationModalOpen}
          onOkPress={() => setIsNotificationModalOpen(false)}
          icon={
            <Tool02
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        />
      )}
    </TabWrapper>
  );
}
