import React, { useEffect, useState } from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import {
  AlertType,
  OrganizationAlertItem,
} from "../../../../../../store/services/models/installations/installationsCommon";
import {
  AlertOctagon,
  AlertTriangle,
  InfoSquare,
  XClose,
} from "untitledui-js-base";
import { formatDate } from "../../AlertsPage";

export interface IAlertsInfoModalProps {
  isOpen: boolean;
  closeModal: () => void;
  alertData: OrganizationAlertItem;
}

export default function AlertsInfoModal({
  isOpen,
  closeModal,
  alertData,
}: IAlertsInfoModalProps) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  const { t: alertsT } = useTranslation("cloud_ui", { keyPrefix: "alerts" });

  const [alertInfo, setAlertInfo] = useState<OrganizationAlertItem>(alertData);

  useEffect(() => {
    setAlertInfo(alertData);
    console.log(alertData);
  }, [alertData]);

  const renderAlertTypeIcon = (alertType: AlertType) => {
    switch (alertType) {
      case AlertType.ERROR:
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "48px",
              width: "48px",
              borderRadius: theme.radius.radiusFull,
              backgroundColor:
                theme.colors.componentColorsUtilityErrorUtilityError100,
            }}
          >
            <AlertOctagon
              size="20"
              style={{
                color: theme.colors.colorsBorderBorderErrorSolid,
              }}
              strokeWidth={2}
            />
          </Box>
        );
      case AlertType.WARNING:
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "48px",
              width: "48px",
              borderRadius: theme.radius.radiusFull,
              backgroundColor:
                theme.colors.componentColorsUtilityErrorUtilityError100,
            }}
          >
            <AlertTriangle
              size="20"
              style={{
                color: theme.colors.colorsBorderBorderErrorSolid,
              }}
              strokeWidth={2}
            />
          </Box>
        );
      default:
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "48px",
              width: "48px",
              borderRadius: theme.radius.radiusFull,
              backgroundColor:
                theme.colors.componentColorsUtilityIndigoUtilityIndigo100,
            }}
          >
            <InfoSquare
              size="20"
              style={{
                color:
                  theme.colors.componentColorsUtilityIndigoUtilityIndigo700,
              }}
              strokeWidth={2}
            />
          </Box>
        );
    }
  };

  const renderAlertTypeText = (alertType: AlertType) => {
    switch (alertType) {
      case AlertType.ERROR:
        return (
          <Typography
            sx={{
              ...theme.typography[".text-sm-regular"],
              color: theme.colors.colorsTextTextErrorPrimary,
            }}
          >
            {t("pages.alertsPage.infoDropdown.alarm")}
          </Typography>
        );
      case AlertType.WARNING:
        return (
          <Typography
            sx={{
              ...theme.typography[".text-sm-regular"],
              color: theme.colors.colorsTextTextWarningPrimary,
            }}
          >
            {t("pages.alertsPage.infoDropdown.warning")}
          </Typography>
        );
      default:
        return (
          <Typography
            sx={{
              ...theme.typography[".text-sm-regular"],
              color: theme.colors.componentColorsUtilityIndigoUtilityIndigo700,
            }}
          >
            {t("pages.alertsPage.infoDropdown.information")}
          </Typography>
        );
    }
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "850px",
          minWidth: "300px",
          minHeight: "300px",
          p: theme.spacing.spacing2xl,
        },
      }}
    >
      <DialogTitle sx={{ p: 0 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {renderAlertTypeIcon(alertInfo.type)}
          <Box sx={{ ml: theme.spacing.spacingMd }}>
            <Typography
              sx={{
                ...theme.typography[".text-lg-semibold"],
                color: theme.colors.colorsTextTextPrimary,
                maxWidth: "500px",
              }}
            >{`${alertData.code} - ${alertsT(
              `${alertData.code}.name`
            )}`}</Typography>
            {renderAlertTypeText(alertInfo.type)}
          </Box>
          <Box
            component={"button"}
            onClick={() => closeModal()}
            sx={{
              border: "none",
              borderRadius: theme.radius.radiusFull,
              backgroundColor: theme.colors.colorsBackgroundBgTertiary,
              height: "44px",
              width: "44px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              ml: "auto",
            }}
          >
            <XClose
              size="18"
              strokeWidth={2}
              style={{ color: theme.colors.colorsForegroundFgQuinary }}
            />
          </Box>
        </Box>
        <Divider
          sx={{
            color: theme.colors.colorsBorderBorderSecondary,
            m: `${theme.spacing.spacingXl} 0`,
          }}
        />
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "calc(50% - 10px)",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  ...theme.typography[".text-sm-medium"],
                  color: theme.colors.colorsTextTextTertiary,
                }}
              >
                {t("pages.alertsPage.infoDropdown.occurred")}
              </Typography>
              <Typography
                sx={{
                  ...theme.typography[".text-sm-medium"],
                  color: theme.colors.colorsTextTextPrimary,
                }}
              >
                {alertInfo.alertStartTimestamp
                  ? formatDate(alertInfo.alertStartTimestamp)
                  : "-"}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                mt: theme.spacing.spacingSm,
              }}
            >
              <Typography
                sx={{
                  ...theme.typography[".text-sm-medium"],
                  color: theme.colors.colorsTextTextTertiary,
                }}
              >
                {t("pages.alertsPage.infoDropdown.acknowledged")}
              </Typography>
              <Typography
                sx={{
                  ...theme.typography[".text-sm-medium"],
                  color: theme.colors.colorsTextTextPrimary,
                }}
              >
                {alertInfo.alertAcknowledgedTimestamp
                  ? formatDate(alertInfo.alertAcknowledgedTimestamp)
                  : "-"}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                mt: theme.spacing.spacingSm,
              }}
            >
              <Typography
                sx={{
                  ...theme.typography[".text-sm-medium"],
                  color: theme.colors.colorsTextTextTertiary,
                }}
              >
                {t("pages.alertsPage.infoDropdown.resolved")}
              </Typography>
              <Typography
                sx={{
                  ...theme.typography[".text-sm-medium"],
                  color: theme.colors.colorsTextTextPrimary,
                }}
              >
                {alertInfo.alertEndTimestamp
                  ? formatDate(alertInfo.alertEndTimestamp)
                  : "-"}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "calc(50% - 10px)",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  ...theme.typography[".text-sm-medium"],
                  color: theme.colors.colorsTextTextTertiary,
                }}
              >
                {t("pages.alertsPage.infoDropdown.origin")}
              </Typography>
              <Typography
                sx={{
                  ...theme.typography[".text-sm-medium"],
                  color: theme.colors.colorsTextTextPrimary,
                }}
              >
                {"-"}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                mt: theme.spacing.spacingSm,
              }}
            >
              <Typography
                sx={{
                  ...theme.typography[".text-sm-medium"],
                  color: theme.colors.colorsTextTextTertiary,
                }}
              >
                {t("pages.alertsPage.infoDropdown.needToBeAcknowleged")}
              </Typography>
              <Typography
                sx={{
                  ...theme.typography[".text-sm-medium"],
                  color: theme.colors.colorsTextTextPrimary,
                }}
              >
                {t(
                  `common_variants.${alertInfo.acknowledgeable ? "yes" : "no"}`
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider
          sx={{
            color: theme.colors.colorsBorderBorderSecondary,
            m: `${theme.spacing.spacingXl} 0`,
          }}
        />
      </DialogTitle>
      <DialogContent
        sx={{
          p: 0,
          pb: theme.spacing.spacing3xl,
          maxHeight: "500px",
          "&::-webkit-scrollbar": {
            width: "4px",
            height: "4px",
            backgroundColor: theme.colors.colorsBackgroundBgSecondaryAlt,
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
            backgroundColor: "rgba(0,0,0,0.2)",
            minHeight: "24px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: theme.colors.colorsTextTextQuaternary,
          },
        }}
      >
        {alertsT(`${alertData.code}.description`) && (
          <>
            <Typography
              sx={{
                ...theme.typography[".text-md-semibold"],
                color: theme.colors.colorsTextTextPrimary,
              }}
            >
              {t("pages.alertsPage.infoDropdown.description")}
            </Typography>
            <Typography
              sx={{
                ...theme.typography[".text-sm-regular"],
                color: theme.colors.colorsTextTextSecondary,
                mb: theme.spacing.spacingXl,
              }}
            >
              {alertsT(`${alertData.code}.description`)}
            </Typography>
          </>
        )}
        {alertsT(`${alertData.code}.action`) && (
          <>
            <Typography
              sx={{
                ...theme.typography[".text-md-semibold"],
                color: theme.colors.colorsTextTextPrimary,
              }}
            >
              {t("pages.alertsPage.infoDropdown.actions")}
            </Typography>
            <Typography
              sx={{
                ...theme.typography[".text-sm-regular"],
                color: theme.colors.colorsTextTextSecondary,
                mb: theme.spacing.spacingXl,
              }}
            >
              {alertsT(`${alertData.code}.action`)}
            </Typography>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
