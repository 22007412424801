import { Box, SxProps, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { Minus, Plus } from "untitledui-js-base";

interface NumberInputProps {
  min?: number;
  max?: number;
  step?: number;
  initialValue?: number;
  decimalPlaces?: 0 | 1 | 2;
  label?: string;
  labelColor?: string;
  hint?: string;
  changeValue: (val: number) => void;
  unit?: string;
  inputWidth?: string;
}

const NumberInput: React.FC<NumberInputProps> = ({
  min,
  max,
  step = 1,
  initialValue = 0,
  decimalPlaces = 0,
  label,
  labelColor,
  hint,
  changeValue,
  unit = "°C",
  inputWidth = "95px"
}) => {
  const theme = useTheme();
  const buttonStyles: SxProps = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor:
      theme.colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBg,
    width: "36px",
    height: "36px",
    cursor: "pointer",
    outline: "none",
    borderRadius: "50%",
    border: `1px solid ${theme.colors.componentColorsComponentsButtonsSecondaryButtonSecondaryBorder}`,
    "&:hover": {
      backgroundColor: theme.colors.colorsBackgroundBgSecondaryHover,
    },
  };

  const [value, setValue] = useState<number | string>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    changeValue(value as number);
  }, [value]);

  const handleIncrement = () => {
    if (max === undefined || (value as number) + step <= max) {
      setValue((prev) => +((prev as number) + step).toFixed(decimalPlaces));
    }

    if (max === undefined || (value as number) + step > max) {
      setValue(+(max as number).toFixed(decimalPlaces));
    }

    if (min === undefined || (value as number) + step < min) {
      setValue(+(min as number).toFixed(decimalPlaces));
    }
  };

  const handleDecrement = () => {
    if (min === undefined || (value as number) - step >= min) {
      setValue((prev) => +((prev as number) - step).toFixed(decimalPlaces));
    }

    if (min === undefined || (value as number) - step < min) {
      setValue(+(min as number).toFixed(decimalPlaces));
    }

    if (max === undefined || (value as number) - step > max) {
      setValue((max as number).toFixed(decimalPlaces));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = +e.target.value;
    if (e.target.value === "") {
      setValue("");
    } else {
      setValue(newValue);
    }
  };

  const onInputBlur = () => {
    if (min !== undefined && (value as number) < min) {
      setValue(+min.toFixed(decimalPlaces));
    } else if (max !== undefined && (value as number) > max) {
      setValue(+max.toFixed(decimalPlaces));
    } else {
      setValue(+(value as number).toFixed(decimalPlaces));
    }
  };

  return (
    <Box sx={{ mb: theme.spacing.spacingXl }}>
      <Typography
        sx={{
          ...theme.typography[".text-sm-medium"],
          color: labelColor || theme.colors.colorsTextTextSecondary,
          mb: theme.spacing.spacingSm,
        }}
      >
        {label}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          component={"button"}
          sx={{ ...buttonStyles, mr: theme.spacing.spacingXs }}
          onClick={handleDecrement}
        >
          <Minus
            size="24"
            strokeWidth={2}
            style={{ color: theme.colors.colorsBorderBorderBrandSolid }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: inputWidth,
            height: "40px",
            border: `1px solid ${theme.colors.colorsBorderBorderPrimary}`,
            borderRadius: "8px",
            padding: `${theme.spacing.spacingMd} ${theme.spacing.spacingLg}`,
            input: {
              ...theme.typography[".text-md-regular"],
              color: theme.colors.colorsTextTextPrimary,
              border: "none",
              width: "100%",
              outline: "none",
              textAlign: "center",
            },
          }}
        >
          <input
            type="number"
            value={value}
            onChange={handleChange}
            step={step}
            onBlur={onInputBlur}
            pattern="[0-9]*"
          />
          <Box
            sx={{
              ...theme.typography[".text-md-regular"],
              color: theme.colors.colorsTextTextPrimary,
            }}
          >
            {unit}
          </Box>
        </Box>
        <Box
          component={"button"}
          sx={{ ...buttonStyles, ml: theme.spacing.spacingXs }}
          onClick={handleIncrement}
        >
          <Plus
            size="24"
            strokeWidth={2}
            style={{ color: theme.colors.colorsBorderBorderBrandSolid }}
          />
        </Box>
      </Box>
      {hint && (
        <Typography
          sx={{
            ...theme.typography[".text-sm-regular"],
            color: theme.colors.colorsTextTextTertiary,
            mt: theme.spacing.spacingSm,
          }}
        >
          {hint}
        </Typography>
      )}
    </Box>
  );
};

export default NumberInput;
