import React, { useEffect, useState } from "react";
import { IInstallationZoneOperationAsArrayItem } from "../Zones";
import { Box, Divider, SxProps, Typography } from "@mui/material";
import useTheme from "../../../../../../../theme/hooks/useTheme";
import SecondaryTitle from "../../../components/SecondaryTitle/SecondaryTitle";
import { useTranslation } from "react-i18next";
import CurveSetPointSection from "./CurveSetPointSection";
import FixedFlowPointSection from "./FixedFlowPointSection";
import {
  IPointValues,
  InstallationZoneOperationControllerEnum,
  InstallationZoneOperationModeEnum,
  PointKey,
} from "../../../../../../../store/services/models/installations/installationSettings";
import { InstallationValueUnit } from "../../../../../../../store/services/models/installations/installationsCommon";
import DisabledField from "../../../components/DisabledField/DisabledField";
import NumberInput from "../../../components/NumberInput/NumberInput";

export interface IPointsSection {
  curveTempsMode: string;
  curveTemps: { [key in PointKey]?: IPointValues };
  title: string;
  fixedFlowPropertyName: string;
  maxFlowTemp: number;
  minFlowTemp: number;
  preferredFixedFlowTemp: number;
  controllerPropertyName: string;
  controller: InstallationZoneOperationControllerEnum;
}

export interface ISetPointSectionProps {
  zone: IInstallationZoneOperationAsArrayItem;
  index: number;
  updateNestedZoneSetting: (
    index: number,
    field: string,
    pointKey: string,
    subField: string,
    value: any
  ) => void;
  updateZoneSetting: (index: number, field: string, value: any) => void;
  roomTemperature?: string;
  desiredRoomTemperature?: number;
  setDesiredRoomTemperature?: (val: number | undefined) => void;
}

export default function SetPointsSection({
  zone,
  index,
  updateNestedZoneSetting,
  updateZoneSetting,
  roomTemperature,
  setDesiredRoomTemperature,
  desiredRoomTemperature,
}: ISetPointSectionProps) {
  const theme = useTheme();
  const baseRadioIconStyles: SxProps = {
    width: "16px",
    minWidth: "16px",
    height: "16px",
    border: `1px solid ${theme.colors.colorsBorderBorderPrimary}`,
    borderRadius: "50%",
  };

  const selectedRadioIconStyles: SxProps = {
    ...baseRadioIconStyles,
    border: `5px solid ${theme.colors.colorsBackgroundBgBrandSolid}`,
  };

  const { t } = useTranslation("cloud_ui");

  const [setPointsSections, setSetPointsSections] =
    useState<Array<IPointsSection>>();

  const [desiredRoomTemperatureValue, setDesiredRoomTemperatureValue] =
    useState<number>(desiredRoomTemperature || 0);

  useEffect(() => {
    if (desiredRoomTemperature) {
      setDesiredRoomTemperatureValue(desiredRoomTemperature || 0);
    }
  }, [desiredRoomTemperature]);

  useEffect(() => {
    if (zone) {
      const heatingSection: IPointsSection = {
        curveTempsMode: "heatingCurve",
        curveTemps: zone.heatingCurve,
        title: "Heating",
        fixedFlowPropertyName: "preferredFixedFlowHeatingTemp",
        maxFlowTemp: zone.maxFlowHeatTemp?.value || 50,
        minFlowTemp: zone.minFlowHeatTemp?.value || -50,
        preferredFixedFlowTemp: zone.preferredFixedFlowHeatingTemp.value,
        controller: zone.heatingController,
        controllerPropertyName: "heatingController",
      };
      const coolingSection: IPointsSection = {
        curveTempsMode: "coolingCurve",
        curveTemps: zone.coolingCurve,
        title: "Cooling",
        fixedFlowPropertyName: "preferredFixedFlowCoolingTemp",
        maxFlowTemp: zone.maxFlowCoolTemp?.value || 50,
        minFlowTemp: zone.minFlowCoolTemp?.value || -50,
        preferredFixedFlowTemp: zone.preferredFixedFlowCoolingTemp.value,
        controller: zone.coolingController,
        controllerPropertyName: "coolingController",
      };

      switch (zone.mode) {
        case InstallationZoneOperationModeEnum.HEATING:
          setSetPointsSections([heatingSection]);
          break;
        case InstallationZoneOperationModeEnum.COOLING:
          setSetPointsSections([coolingSection]);
          break;
        case InstallationZoneOperationModeEnum.BOTH:
          setSetPointsSections([heatingSection, coolingSection]);
          break;
        default:
          setSetPointsSections([]);
          break;
      }
    }
  }, [
    zone,
    zone.mode,
    zone.heatingController,
    zone.coolingController,
    zone.preferredFixedFlowCoolingTemp.value,
    zone.preferredFixedFlowHeatingTemp.value,
    zone.minFlowCoolTemp?.value,
    zone.maxFlowCoolTemp?.value,
    zone.minFlowHeatTemp?.value,
    zone.maxFlowHeatTemp?.value,
  ]);

  return (
    <>
      {setPointsSections?.map((section) => {
        return (
          <React.Fragment
            key={`zone${zone.zoneNumber}_curveType-${section.curveTempsMode}`}
          >
            {section.title === "Cooling" &&
              zone.mode === InstallationZoneOperationModeEnum.BOTH && (
                <Divider
                  sx={{
                    width: "100%",
                    borderColor: theme.colors.colorsBorderBorderSecondary,
                  }}
                />
              )}
            <SecondaryTitle
              title={`${t("pages.installationSettings.zones.zoneTitle")} ${
                zone.zoneNumber
              } - ${t(
                "pages.installationSettings.zones.flowTemperatureSetPoint"
              )}  - ${t(`pages.installationSettings.zones.${section.title}`)}`}
              variant="sm"
            />
            <Box
              component={"button"}
              onClick={() =>
                updateZoneSetting(
                  index,
                  section.controllerPropertyName,
                  InstallationZoneOperationControllerEnum.CURVE
                )
              }
              sx={{
                mb: theme.spacing.spacingXl,
                display: "flex",
                alignItems: "center",
                border: "none",
                backgroundColor: "initial",
                cursor: "pointer",
              }}
            >
              <Box
                sx={
                  section.controller ===
                  InstallationZoneOperationControllerEnum.CURVE
                    ? { ...selectedRadioIconStyles }
                    : {
                        ...baseRadioIconStyles,
                      }
                }
              />
              <Typography
                sx={{
                  ...theme.typography[".text-md-medium"],
                  color: theme.colors.colorsTextTextSecondary,
                  ml: theme.spacing.spacingLg,
                }}
              >
                {t(
                  `pages.installationSettings.zones.buttons.${
                    section.title === "Cooling"
                      ? "useCoolingCurve"
                      : "useHeatingCurve"
                  }`
                )}
              </Typography>
            </Box>

            {section.controller ===
              InstallationZoneOperationControllerEnum.CURVE && (
              <Box sx={{ pl: `calc(22px + ${theme.spacing.spacingLg})` }}>
                {section.title === "Heating" &&
                  desiredRoomTemperature &&
                  roomTemperature &&
                  setDesiredRoomTemperature && (
                    <>
                      {/* <Typography
                        sx={{
                          ...theme.typography[".text-sm-semibold"],
                          color: theme.colors.colorsTextTextPrimary,
                          mb: theme.spacing.spacingXl,
                        }}
                      >
                        {t(
                          "pages.installationSettings.zones.fieldsLabels.sensor"
                        )}
                      </Typography> */}
                      <DisabledField
                        label={`${t(
                          "pages.installationSettings.zones.fieldsLabels.roomTemperature"
                        )}`}
                        value={roomTemperature}
                      />
                      <NumberInput
                        initialValue={desiredRoomTemperatureValue}
                        changeValue={(val) => {
                          setDesiredRoomTemperature(val);
                        }}
                        min={12}
                        max={30}
                        label={`${t(
                          "pages.installationSettings.zones.fieldsLabels.desiredRoomTemperature"
                        )}`}
                        hint={`${t(
                          "pages.installationSettings.zones.fieldHints.desiredRoomTemperature",
                          { from: "12", to: "30" }
                        )}`}
                      />
                    </>
                  )}
                <Typography
                  sx={{
                    ...theme.typography[".text-sm-semibold"],
                    color: theme.colors.colorsTextTextPrimary,
                    mb: theme.spacing.spacingXl,
                  }}
                >
                  {t(
                    `pages.installationSettings.zones.fieldsLabels.${
                      section.title === "Cooling"
                        ? "coolingCurve"
                        : "heatingCurve"
                    }`
                  )}
                </Typography>
                <CurveSetPointSection
                  title={section.title}
                  updateTypeToFixedFlow={() =>
                    updateZoneSetting(
                      index,
                      section.controllerPropertyName,
                      InstallationZoneOperationControllerEnum.FIXED
                    )
                  }
                  changePointsValues={(val, pointKey, type) => {
                    updateNestedZoneSetting(
                      index,
                      section.curveTempsMode,
                      pointKey,
                      type === "flow" ? "flowTemp" : "outdoorTemp",
                      { value: val, unit: InstallationValueUnit.CELSIUS }
                    );
                  }}
                  points={section.curveTemps}
                  maxFlowTemp={section.maxFlowTemp}
                  minFlowTemp={section.minFlowTemp}
                />
              </Box>
            )}
            <Box
              component={"button"}
              onClick={() =>
                updateZoneSetting(
                  index,
                  section.controllerPropertyName,
                  InstallationZoneOperationControllerEnum.FIXED
                )
              }
              sx={{
                mb: theme.spacing.spacingXl,
                display: "flex",
                alignItems: "center",
                border: "none",
                backgroundColor: "initial",
                cursor: "pointer",
              }}
            >
              <Box
                sx={
                  section.controller ===
                  InstallationZoneOperationControllerEnum.FIXED
                    ? { ...selectedRadioIconStyles }
                    : {
                        ...baseRadioIconStyles,
                      }
                }
              />
              <Typography
                sx={{
                  ...theme.typography[".text-md-medium"],
                  color: theme.colors.colorsTextTextSecondary,
                  ml: theme.spacing.spacingLg,
                }}
              >
                {t(
                  `pages.installationSettings.zones.buttons.${
                    section.title === "Cooling"
                      ? "useFixedFlowTemperatureForCooling"
                      : "useFixedFlowTemperatureForHeating"
                  }`
                )}
              </Typography>
            </Box>
            {section.controller ===
              InstallationZoneOperationControllerEnum.FIXED && (
              <Box sx={{ pl: `calc(22px + ${theme.spacing.spacingLg})` }}>
                <Typography
                  sx={{
                    ...theme.typography[".text-sm-semibold"],
                    color: theme.colors.colorsTextTextPrimary,
                    mb: theme.spacing.spacingXl,
                  }}
                >
                  {t(
                    `pages.installationSettings.zones.fieldsLabels.${
                      section.title === "Cooling"
                        ? "fixedFlowTemperatureForHeating"
                        : "fixedFlowTemperatureForCooling"
                    }`
                  )}
                </Typography>
                <FixedFlowPointSection
                  flowTemperature={section.preferredFixedFlowTemp}
                  maxFlowTemp={section.maxFlowTemp}
                  minFlowTemp={section.minFlowTemp}
                  setFlowTemperature={(val) => {
                    updateZoneSetting(
                      index,
                      section.fixedFlowPropertyName,
                      val
                        ? {
                            ...zone.preferredRoomTemp,
                            value: Number(val),
                          }
                        : { unit: zone.preferredRoomTemp.unit }
                    );
                  }}
                  updateTypeToCurve={() =>
                    updateZoneSetting(
                      index,
                      section.controllerPropertyName,
                      InstallationZoneOperationControllerEnum.CURVE
                    )
                  }
                />
              </Box>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}
