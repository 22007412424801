import React, { useEffect, useState } from "react";
import useTheme from "../../../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAppDispatch } from "../../../../../../../../store";
import {
  installationApi,
  useGetInstallationSettingsQuery,
  useSetInstallationSettingsMutation,
} from "../../../../../../../../store/services/installation";
import {
  IAdditionalInlineHeatingSource,
  IInstallationSettingsByIdResponse,
  ISetInstallationSettings,
  ISetInstallerSettignsComissioningAdditionalInlineHeatingSource,
  StageStateEnum,
} from "../../../../../../../../store/services/models/installations/installationSettings";
import MainTitle from "../../../../components/MainTitle/MainTitle";
import TabWrapper from "../../../../TabWrapper";
import { Box, Divider } from "@mui/material";
import SettingsSwitcher from "../../../../components/SettingsSwitcher/SettingsSwitcher";
import NumberInput from "../../../../components/NumberInput/NumberInput";
import SecondaryTitle from "../../../../components/SecondaryTitle/SecondaryTitle";
import SimpleDropdown from "../../../../components/SimpleDropdown/SimpleDropdown";
import {
  baseFindDifferences,
  cleanValuesForRequest,
} from "../../../../helpers/findDifferences";
import { ActiveOrInactiveEnum } from "../../../../../../../../store/services/models/installations/installationsCommon";
import NotificationModal from "../../../../components/NotificationModal/NotificationModal";
import { Tool02 } from "untitledui-js-base";
import UnsavedChangesModal from "../../../../components/UnsavedChangesModal/UnsavedChangesModal";
import ConfirmChangesModal from "../../../../components/ConfirmChangesModal/ConfirmChangesModal";
import DifferencesToShowInModal from "./DifferencesToShowInModal";

export default function AdditionalInlineHeatingSource() {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  const {
    data: settings,
    isFetching: isFetchingSettings,
    isLoading: isLoadingSettings,
    refetch: refetchSettings,
  } = useGetInstallationSettingsQuery({
    uuid: id!,
    isRealData: params.get("dummyData") === "false",
  });

  const [setInstallationSettings] = useSetInstallationSettingsMutation();
  const [settingsParameters, setSettingsParameters] = useState<
    IInstallationSettingsByIdResponse | undefined
  >(settings);

  const [
    additionalInlineHeatingSourceSettingsData,
    setAdditionalInlineHeatingSourceSettingsData,
  ] = useState<IAdditionalInlineHeatingSource>();
  const [
    initialAdditionalInlineHeatingSourceSettingsData,
    setInitialAdditionalInlineHeatingSourceSettingsData,
  ] = useState<IAdditionalInlineHeatingSource>();

  const [initialSettings, setInitialSettings] =
    useState<IInstallationSettingsByIdResponse | null>(null);

  const [isSomethingChanged, setIsSomethingChanged] = useState(false);
  const [isConfirmChangesModalOpen, setIsConfirmChangesModalOpen] =
    useState<boolean>(false);
  const [differencesToShow, setDifferencesToShow] = useState<any>();
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);

  const stagesDropdownOptions = [
    {
      label: t(
        "pages.installationSettings.installerSettings.additionalInlineHeatingSource.stages.off"
      ),
      value: StageStateEnum.OFF,
    },
    {
      label: t(
        "pages.installationSettings.installerSettings.additionalInlineHeatingSource.stages.onCompressorOff"
      ),
      value: StageStateEnum.ON_COMPRESSOR_OFF,
    },
    {
      label: t(
        "pages.installationSettings.installerSettings.additionalInlineHeatingSource.stages.available"
      ),
      value: StageStateEnum.AVAILABLE,
    },
  ];

  useEffect(() => {
    if (settings) {
      setSettingsParameters(settings);
    }
  }, [settings]);

  useEffect(() => {
    if (settingsParameters) {
      setInitialSettings(JSON.parse(JSON.stringify(settingsParameters)));
      setAdditionalInlineHeatingSourceSettingsData(
        JSON.parse(
          JSON.stringify(
            settingsParameters.installerSettings.commissioning
              .additionalInlineHeatingSource
          )
        )
      );
      setInitialAdditionalInlineHeatingSourceSettingsData(
        JSON.parse(
          JSON.stringify(
            settingsParameters.installerSettings.commissioning
              .additionalInlineHeatingSource
          )
        )
      );
    }
  }, [settingsParameters]);

  const checkForChanges = () => {
    if (
      JSON.stringify(additionalInlineHeatingSourceSettingsData) !==
      JSON.stringify(initialAdditionalInlineHeatingSourceSettingsData)
    ) {
      setIsSomethingChanged(true);
    } else {
      setIsSomethingChanged(false);
    }
  };

  useEffect(() => {
    checkForChanges();
  }, [additionalInlineHeatingSourceSettingsData]);

  const updateSettings = (field: string, value: any) => {
    const updatedSettings = { ...additionalInlineHeatingSourceSettingsData };
    (updatedSettings as any)[field] = value;
    setAdditionalInlineHeatingSourceSettingsData(
      updatedSettings as IAdditionalInlineHeatingSource
    );
  };

  const onDiscardChanges = () => {
    setAdditionalInlineHeatingSourceSettingsData(
      JSON.parse(
        JSON.stringify(initialAdditionalInlineHeatingSourceSettingsData)
      )
    );
  };

  const onSaveChanges = () => {
    const differences = baseFindDifferences(
      initialAdditionalInlineHeatingSourceSettingsData!,
      additionalInlineHeatingSourceSettingsData!
    );
    setDifferencesToShow(differences);

    setIsConfirmChangesModalOpen(true);
  };

  const createRequest = () => {
    let newSettings: Partial<ISetInstallerSettignsComissioningAdditionalInlineHeatingSource> =
      cleanValuesForRequest(differencesToShow);

    return {
      installerSettings: {
        commissioning: {
          additionalInlineHeatingSource: newSettings,
        },
      },
    };
  };

  const onConfirmSettigsChanges = () => {
    const updatedSettings = { ...initialSettings };
    updatedSettings.installerSettings = {
      ...(updatedSettings.installerSettings as any),
      commissioning: {
        ...(updatedSettings.installerSettings?.commissioning as any),
        additionalInlineHeatingSource:
          additionalInlineHeatingSourceSettingsData,
      },
    };
    setIsConfirmChangesModalOpen(false);
    const requestBody = createRequest();
    setInstallationSettings({
      ...(requestBody as ISetInstallationSettings),
      uuid: id!,
    })
      .unwrap()
      .then(() => {
        dispatch(
          installationApi.util.updateQueryData(
            "getInstallationSettings",
            { uuid: id!, isRealData: params.get("dummyData") === "false" },
            (draftSettings) => {
              Object.assign(draftSettings, updatedSettings);
            }
          )
        );
        setIsNotificationModalOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <TabWrapper
      onDiscardPress={onDiscardChanges}
      onSaveChangesPress={onSaveChanges}
      isDiscardDisabled={!isSomethingChanged}
      isSaveDisabled={!isSomethingChanged}
    >
      <MainTitle
        title={t(
          "pages.installationSettings.installerSettings.additionalInlineHeatingSource.mainTitle"
        )}
      />
      <Box sx={{ mt: theme.spacing.spacingXl }} />

      <SettingsSwitcher
        label={`${t(
          "pages.installationSettings.installerSettings.additionalInlineHeatingSource.fieldsLabels.additionalInlineHeatingSource"
        )}`}
        state={
          additionalInlineHeatingSourceSettingsData?.state ===
          ActiveOrInactiveEnum.ACTIVE
            ? true
            : false
        }
        changeState={(val) => {}}
      />
      <NumberInput
        initialValue={
          additionalInlineHeatingSourceSettingsData?.inputPower.value
        }
        changeValue={(val) =>
          updateSettings("inputPower", {
            ...additionalInlineHeatingSourceSettingsData?.inputPower,
            value: val,
          })
        }
        decimalPlaces={1}
        unit={"kW"}
        step={0.5}
        hint={`${t(
          "pages.installationSettings.installerSettings.dhw.fieldsHints.inputPower"
        )}`}
        label={`${t(
          "pages.installationSettings.installerSettings.dhw.fieldsLabels.inputPower"
        )}`}
        min={0}
        max={10000}
        inputWidth="108px"
      />
      <Divider
        sx={{
          width: "100%",
          borderColor: theme.colors.colorsBorderBorderSecondary,
        }}
      />
      <SecondaryTitle
        title={`${t(
          "pages.installationSettings.installerSettings.additionalInlineHeatingSource.secondaryTitle"
        )}`}
        variant="md"
      />
      <SimpleDropdown
        label={`${t(
          "pages.installationSettings.installerSettings.additionalInlineHeatingSource.fieldsLabels.stage",
          {
            num: 1,
          }
        )}`}
        currentValue={additionalInlineHeatingSourceSettingsData?.stage1}
        dropdownOptions={stagesDropdownOptions}
        onChange={(val) => updateSettings("stage1", val)}
        sx={{
          ".MuiInputBase-root ": {
            width: "100%",
            maxWidth: "590px",
          },
        }}
      />
      <SimpleDropdown
        label={`${t(
          "pages.installationSettings.installerSettings.additionalInlineHeatingSource.fieldsLabels.stage",
          {
            num: 2,
          }
        )}`}
        currentValue={additionalInlineHeatingSourceSettingsData?.stage2}
        dropdownOptions={stagesDropdownOptions}
        onChange={(val) => updateSettings("stage2", val)}
        sx={{
          ".MuiInputBase-root ": {
            width: "100%",
            maxWidth: "590px",
          },
        }}
      />
      <SimpleDropdown
        label={`${t(
          "pages.installationSettings.installerSettings.additionalInlineHeatingSource.fieldsLabels.stage",
          {
            num: 3,
          }
        )}`}
        currentValue={additionalInlineHeatingSourceSettingsData?.stage3}
        dropdownOptions={stagesDropdownOptions}
        onChange={(val) => updateSettings("stage3", val)}
        sx={{
          ".MuiInputBase-root ": {
            width: "100%",
            maxWidth: "590px",
          },
        }}
      />
      <UnsavedChangesModal
        tabName={t(
          "pages.installationSettings.tabsNames.installerAdditionalInlineHeatingSource"
        )}
        condition={isSomethingChanged}
      />
      {isConfirmChangesModalOpen && (
        <ConfirmChangesModal
          isOpen={isConfirmChangesModalOpen}
          onCancel={() => setIsConfirmChangesModalOpen(false)}
          onOk={() => onConfirmSettigsChanges()}
          page={`${t(
            "pages.installationSettings.tabsNames.installerAdditionalInlineHeatingSource"
          )}`}
          block={`${t(
            "pages.installationSettings.tabsNames.installerSettings"
          )}`}
          // showHeatPumpNote={differencesToShow.state}
          icon={
            <Tool02
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        >
          <DifferencesToShowInModal differencesToShow={differencesToShow} />
        </ConfirmChangesModal>
      )}
      {isNotificationModalOpen && (
        <NotificationModal
          isOpen={isNotificationModalOpen}
          onOkPress={() => setIsNotificationModalOpen(false)}
          icon={
            <Tool02
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        />
      )}
    </TabWrapper>
  );
}
