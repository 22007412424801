import React from "react";
import { useTranslation } from "react-i18next";
import { Divider, Typography } from "@mui/material";
import useTheme from "../../../../../../../../theme/hooks/useTheme";
import DefaultChange from "../../../../components/ConfirmChangesModal/DefaultChage";
import { StageStateEnum } from "../../../../../../../../store/services/models/installations/installationSettings";

export default function DifferencesToShowInModal({
  differencesToShow,
}: {
  differencesToShow: any;
}) {
  const { t } = useTranslation("cloud_ui");
  const theme = useTheme();

  const stageOptionsToShow = {
    [StageStateEnum.OFF]: t(
      "pages.installationSettings.installerSettings.additionalInlineHeatingSource.stages.off"
    ),
    [StageStateEnum.ON_COMPRESSOR_OFF]: t(
      "pages.installationSettings.installerSettings.additionalInlineHeatingSource.stages.onCompressorOff"
    ),
    [StageStateEnum.AVAILABLE]: t(
      "pages.installationSettings.installerSettings.additionalInlineHeatingSource.stages.available"
    ),
  };

  return (
    <>
      {differencesToShow.inputPower && (
        <Typography
          sx={{
            ...theme.typography[".text-md-semibold"],
            color: theme.colors.colorsTextTextPrimary,
            mb: theme.spacing.spacingXl,
          }}
        >
          {t(
            "pages.installationSettings.installerSettings.additionalInlineHeatingSource.mainTitle"
          )}
        </Typography>
      )}
      {differencesToShow?.inputPower && (
        <DefaultChange
          changedValue={differencesToShow?.inputPower.value.newValue + "kW"}
          previousValue={
            differencesToShow?.inputPower.value.previousValue + "kW"
          }
          title={`${t(
            "pages.installationSettings.installerSettings.dhw.fieldsLabels.inputPower"
          )}`}
        />
      )}
      {differencesToShow.inputPower && (
        <Divider
          sx={{
            width: "100%",
            borderColor: theme.colors.colorsBorderBorderSecondary,
          }}
        />
      )}
      {(differencesToShow.stage1 ||
        differencesToShow.stage2 ||
        differencesToShow.stage3) && (
        <Typography
          sx={{
            ...theme.typography[".text-md-semibold"],
            color: theme.colors.colorsTextTextPrimary,
            mb: theme.spacing.spacingLg,
            mt: theme.spacing.spacingLg,
          }}
        >
          {t(
            "pages.installationSettings.installerSettings.additionalInlineHeatingSource.secondaryTitle"
          )}
        </Typography>
      )}
      {differencesToShow?.stage1 && (
        <DefaultChange
          changedValue={
            stageOptionsToShow[
              differencesToShow?.stage1.newValue as StageStateEnum
            ]
          }
          previousValue={
            stageOptionsToShow[
              differencesToShow?.stage1.previousValue as StageStateEnum
            ]
          }
          title={`${t(
            "pages.installationSettings.installerSettings.additionalInlineHeatingSource.fieldsLabels.stage",
            { num: 1 }
          )}`}
          isVertical
        />
      )}
      {differencesToShow?.stage2 && (
        <DefaultChange
          changedValue={
            stageOptionsToShow[
              differencesToShow?.stage2.newValue as StageStateEnum
            ]
          }
          previousValue={
            stageOptionsToShow[
              differencesToShow?.stage2.previousValue as StageStateEnum
            ]
          }
          title={`${t(
            "pages.installationSettings.installerSettings.additionalInlineHeatingSource.fieldsLabels.stage",
            { num: 2 }
          )}`}
          isVertical
        />
      )}
      {differencesToShow?.stage3 && (
        <DefaultChange
          changedValue={
            stageOptionsToShow[
              differencesToShow?.stage3.newValue as StageStateEnum
            ]
          }
          previousValue={
            stageOptionsToShow[
              differencesToShow?.stage3.previousValue as StageStateEnum
            ]
          }
          title={`${t(
            "pages.installationSettings.installerSettings.additionalInlineHeatingSource.fieldsLabels.stage",
            { num: 3 }
          )}`}
          isVertical
        />
      )}
    </>
  );
}
