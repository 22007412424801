import React, { useEffect, useState } from "react";
import useTheme from "../../../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";
import { useAppDispatch } from "../../../../../../../../store";
import {
  installationApi,
  useGetInstallationSettingsQuery,
  useSetInstallationSettingsMutation,
} from "../../../../../../../../store/services/installation";
import TabWrapper from "../../../../TabWrapper";
import MainTitle from "../../../../components/MainTitle/MainTitle";
import {
  IInstallationSettingsByIdResponse,
  InstallationComissioningZoneCircuitTypeEnum,
  InstallationZoneOperationModeEnum,
  ISetInstallationSettings,
  ISetInstallerSettignsComissioningZoneOperation,
  ISetOperationZonesParams,
} from "../../../../../../../../store/services/models/installations/installationSettings";
import {
  IInstallationBaseValue,
  InstallationValueUnit,
  ZoneOperationsKey,
} from "../../../../../../../../store/services/models/installations/installationsCommon";
import SecondaryTitle from "../../../../components/SecondaryTitle/SecondaryTitle";
import { Box, Divider, Typography } from "@mui/material";
import SetPointsSection from "../../../ZonesTab/SetPointSections/SetPointsSection";
import DisabledField from "../../../../components/DisabledField/DisabledField";
import SimpleDropdown from "../../../../components/SimpleDropdown/SimpleDropdown";
import NumberInput from "../../../../components/NumberInput/NumberInput";
import {
  cleanValuesForRequest,
  findZonesSettingsDifferences,
  mapZonesArrayToResponse,
} from "../../../../helpers/findDifferences";
import UnsavedChangesModal from "../../../../components/UnsavedChangesModal/UnsavedChangesModal";
import ConfirmChangesModal from "../../../../components/ConfirmChangesModal/ConfirmChangesModal";
import { IInstallationZoneOperationAsArrayItem } from "../../../ZonesTab/Zones";
import { ITabData } from "../../../../../../../../components/TabsNavigation/TabsNavigation";
import ZonesTabs from "../../../../components/ZonesTabs/ZonesTabs";
import PrivateRoute from "../../../../../../../../components/PrivateRoute/PrivateRoute";
import NotificationModal from "../../../../components/NotificationModal/NotificationModal";
import { Tool02 } from "untitledui-js-base";
import SettingsSwitcher from "../../../../components/SettingsSwitcher/SettingsSwitcher";
import DifferencesToShowInModal from "./DifferencesToShowInModal";

export default function InstallerZones() {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  const {
    data: settings,
    isFetching: isFetchingSettings,
    isLoading: isLoadingSettings,
    refetch: refetchSettings,
  } = useGetInstallationSettingsQuery({
    uuid: id!,
    isRealData: params.get("dummyData") === "false",
  });

  const [zonesCount, setZonesCount] = useState<number>(0);

  const [setInstallationSettings] = useSetInstallationSettingsMutation();
  const [settingsParameters, setSettingsParameters] = useState<
    IInstallationSettingsByIdResponse | undefined
  >(settings);
  const [zonesSettingsAsArray, setZonesSettingsAsArray] = useState<
    Array<IInstallationZoneOperationAsArrayItem>
  >([]);
  const [initialZonesSettingsAsArray, setInitialZonesSettingsAsArray] =
    useState<Array<IInstallationZoneOperationAsArrayItem>>([]);
  const [isSomethingChanged, setIsSomethingChanged] = useState(false);

  const [differencesToShow, setDifferencesToShow] = useState<Array<any>>([]);
  const [isConfirmChangesModalOpen, setIsConfirmChangesModalOpen] =
    useState<boolean>(false);

  const [initialSettings, setInitialSettings] =
    useState<IInstallationSettingsByIdResponse | null>(null);
  const [zonesRoutes, setZonesRoutes] = useState<Array<ITabData>>([]);
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);

  const [isSecondZoneActive, setIsSecondZoneActive] = useState<boolean>(
    !!(settings?.zonesCount?.value! > 1)
  ); //TODO!!! MOCK DATA? QUESTIONS
  const [initialIsSecondZoneActive, setInitialIsSecondZoneActive] =
    useState<boolean>(!!(settings?.zonesCount?.value! > 1));

  useEffect(() => {
    if (settings) {
      setSettingsParameters(settings);
    }
  }, [settings]);

  useEffect(() => {
    if (settingsParameters) {
      setInitialSettings(JSON.parse(JSON.stringify(settingsParameters)));
      setInitialIsSecondZoneActive(!!(settings?.zonesCount?.value! > 1));
      setIsSecondZoneActive(!!(settings?.zonesCount?.value! > 1));
      const zoneOperations = settingsParameters?.zoneOperations;
      const comissioningZones =
        settingsParameters.installerSettings?.commissioning?.zoneOperations;
      const extractZoneNumber = (zoneKey: string): number | null => {
        const match = zoneKey.match(/zone(\d+)Operations/);
        return match ? parseInt(match[1], 10) : null;
      };
      let mappedZones: Array<any> = [];
      if (zoneOperations) {
        mappedZones = Object.keys(zoneOperations).map((zoneKey) => {
          return {
            ...zoneOperations[zoneKey as ZoneOperationsKey],
            zonePropertyName: zoneKey,
            zoneNumber: extractZoneNumber(zoneKey),
            isZoneActivated:
              extractZoneNumber(zoneKey) === 1 ||
              (extractZoneNumber(zoneKey) !== 1 &&
                settingsParameters.zonesCount.value > 1),
          };
        });
      }

      if (mappedZones?.length && comissioningZones) {
        Object.keys(comissioningZones).map((zoneKey) => {
          mappedZones = mappedZones.map(
            (zone: IInstallationZoneOperationAsArrayItem) => {
              if (zone.zonePropertyName === zoneKey) {
                const currentZone =
                  comissioningZones[zoneKey as ZoneOperationsKey];
                return {
                  ...zone,
                  mode: currentZone?.mode,
                  circuitType: currentZone?.circuitType,
                  minFlowHeatTemp: currentZone?.minFlowHeatTemp,
                  maxFlowHeatTemp: currentZone?.maxFlowHeatTemp,
                  minFlowCoolTemp: currentZone?.minFlowCoolTemp,
                  maxFlowCoolTemp: currentZone?.maxFlowCoolTemp,
                  minIndoorHeatTemp: currentZone?.minIndoorHeatTemp,
                  maxIndoorHeatTemp: currentZone?.maxIndoorHeatTemp,
                  minIndoorCoolTemp: currentZone?.minIndoorCoolTemp,
                  maxIndoorCoolTemp: currentZone?.maxIndoorCoolTemp,
                };
              } else {
                return zone;
              }
            }
          );
        });
      }

      setZonesSettingsAsArray(JSON.parse(JSON.stringify(mappedZones)));
      setInitialZonesSettingsAsArray(JSON.parse(JSON.stringify(mappedZones)));
      setZonesCount(settings?.zonesCount.value || 0);

      let zonesTabs: Array<ITabData> = mappedZones.map((zone) => {
        return {
          href: `/portal/residential-installations/${id}/settings/installation-settings-installer-settings/zones/zone${zone.zoneNumber}${location.search}`,
          label: `${t("pages.installationSettings.zones.zoneTitle")} ${
            zone.zoneNumber
          }`,
        };
      });
      setZonesRoutes(zonesTabs);
      if (zonesTabs.length) {
        const baseUrl = `/portal/residential-installations/${id}/settings/installation-settings-installer-settings/zones/`;
        if (location.pathname.endsWith("/zones")) {
          navigate(`${baseUrl}zone1${location.search}`);
        }
      }
    }
  }, [settingsParameters]);

  const checkForChanges = () => {
    if (
      JSON.stringify(zonesSettingsAsArray) !==
        JSON.stringify(initialZonesSettingsAsArray) ||
      initialIsSecondZoneActive !== isSecondZoneActive
    ) {
      setIsSomethingChanged(true);
    } else {
      setIsSomethingChanged(false);
    }
  };

  useEffect(() => {
    checkForChanges();
  }, [zonesSettingsAsArray, isSecondZoneActive]);

  const updateZoneSetting = (index: number, field: string, value: any) => {
    const updatedZones = [...zonesSettingsAsArray];
    (updatedZones[index] as any)[field] = value;
    setZonesSettingsAsArray(updatedZones);
  };

  const updateNestedZoneSetting = (
    index: number,
    field: string,
    pointKey: string,
    subField: string,
    value: any
  ) => {
    const updatedZones = [...zonesSettingsAsArray];
    (updatedZones[index] as any)[field][pointKey][subField] = value;
    setZonesSettingsAsArray(updatedZones);
  };

  const onDiscardChanges = () => {
    setZonesSettingsAsArray(
      JSON.parse(JSON.stringify(initialZonesSettingsAsArray))
    );
    setIsSecondZoneActive(initialIsSecondZoneActive);
  };

  const onSaveChanges = () => {
    const differences = findZonesSettingsDifferences(
      initialZonesSettingsAsArray,
      zonesSettingsAsArray
    );
    setDifferencesToShow(differences);

    setIsConfirmChangesModalOpen(true);
  };

  const createRequest = () => {
    const newZoneOperations: {
      [key: string]: Partial<ISetOperationZonesParams>;
    } = {};
    const newInstallerZoneOperations: {
      [key: string]: Partial<ISetInstallerSettignsComissioningZoneOperation>;
    } = {};
    let requestBody = {};
    differencesToShow.forEach((zone) => {
      const zoneKey = zone.zonePropertyName as ZoneOperationsKey;

      const {
        minFlowHeatTemp,
        maxFlowHeatTemp,
        minFlowCoolTemp,
        maxFlowCoolTemp,
        mode,
        isZoneActivated,
        ...restZone
      } = zone.differences;

      if (zoneKey) {
        if (Object.keys(restZone).length !== 0) {
          newZoneOperations[zoneKey] = cleanValuesForRequest(restZone);

          requestBody = {
            ...requestBody,
            zoneOperations: newZoneOperations,
          };
        }
        if (
          mode ||
          minFlowHeatTemp ||
          maxFlowHeatTemp ||
          minFlowCoolTemp ||
          maxFlowCoolTemp
        ) {
          const installerZoneChanges: any = {
            mode,
            minFlowHeatTemp,
            maxFlowHeatTemp,
            minFlowCoolTemp,
            maxFlowCoolTemp,
          };
          for (const key in installerZoneChanges) {
            if (installerZoneChanges[key] === undefined) {
              delete installerZoneChanges[key];
            }
          }
          newInstallerZoneOperations[zoneKey] =
            cleanValuesForRequest(installerZoneChanges);
          requestBody = {
            ...requestBody,
            installerSettings: {
              commissioning: {
                zoneOperations: newInstallerZoneOperations,
              },
            },
          };
        }
      }
    });

    if (initialIsSecondZoneActive !== isSecondZoneActive) {
      requestBody = {
        ...requestBody,
        zonesCount: initialSettings?.zonesCount.value === 2 ? 1 : 2,
      };
    }

    return requestBody;
  };

  const onConfirmSettigsChanges = () => {
    if (initialSettings) {
      let responseForCache = mapZonesArrayToResponse(
        zonesSettingsAsArray,
        initialSettings
      );

      if (initialIsSecondZoneActive !== isSecondZoneActive) {
        responseForCache = {
          ...responseForCache,
          zonesCount: {
            value: initialSettings?.zonesCount.value === 2 ? 1 : 2,
            unit: InstallationValueUnit.COUNT,
          },
        };
      }

      setIsConfirmChangesModalOpen(false);
      const requestBody = createRequest();

      setInstallationSettings({
        ...(requestBody as ISetInstallationSettings),
        uuid: id!,
      })
        .unwrap()
        .then(() => {
          dispatch(
            installationApi.util.updateQueryData(
              "getInstallationSettings",
              { uuid: id!, isRealData: params.get("dummyData") === "false" },
              (draftSettings) => {
                Object.assign(draftSettings, responseForCache);
              }
            )
          );
          setIsNotificationModalOpen(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Typography
        sx={{
          ...theme.typography[".text-lg-semibold"],
          color: theme.colors.colorsTextTextPrimary,
          mb: theme.spacing.spacingXl,
          p: `${theme.spacing.spacingMd} ${theme.spacing.spacing4xl} 0`,
        }}
      >
        {t("pages.installationSettings.zones.mainTitle")}
      </Typography>
      <Box sx={{ p: `0 ${theme.spacing.spacing4xl}` }}>
        <ZonesTabs tabs={zonesRoutes} />
      </Box>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Routes>
          {zonesSettingsAsArray.map((zone, index) => {
            const modeDropdownOptions = [
              {
                label: t("pages.installationSettings.zones.onlyHeating"),
                value: InstallationZoneOperationModeEnum.HEATING,
              },
              {
                label: t("pages.installationSettings.zones.onlyCooling"),
                value: InstallationZoneOperationModeEnum.COOLING,
              },
              {
                label: t("pages.installationSettings.zones.HeatingAndCooling"),
                value: InstallationZoneOperationModeEnum.BOTH,
              },
              {
                label: t("pages.installationSettings.zones.none"),
                value: InstallationZoneOperationModeEnum.NONE,
              },
            ];

            const circuitTypeToDisplay = {
              [InstallationComissioningZoneCircuitTypeEnum.DIRECT]: t(
                "pages.installationSettings.zones.circuitTypes.direct"
              ),
              [InstallationComissioningZoneCircuitTypeEnum.MIXING]: t(
                "pages.installationSettings.zones.circuitTypes.mixing"
              ),
            };
            return (
              <Route
                key={`zone${zone.zoneNumber}`}
                path={`/zone${zone.zoneNumber}`}
                element={
                  <PrivateRoute
                    element={
                      <TabWrapper
                        onDiscardPress={onDiscardChanges}
                        onSaveChangesPress={onSaveChanges}
                        isDiscardDisabled={!isSomethingChanged}
                        isSaveDisabled={!isSomethingChanged}
                        topHeight={"170"}
                      >
                        {zonesCount > 0 && (index === 0 || index === 1) && (
                          <React.Fragment key={`zone${zone.zoneNumber}`}>
                            {/*                               
                              <SecondaryTitle
                                title={`${t(
                                  "pages.installationSettings.zones.zoneTitle"
                                )} ${zone.zoneNumber}`}
                                variant="md"
                              /> */}
                            {zone.zoneNumber! > 1 && (
                              <SettingsSwitcher
                                label={`${t(
                                  "pages.installationSettings.zones.zoneTitle"
                                )} - ${zone.zoneNumber}`}
                                state={zone.isZoneActivated}
                                changeState={(val) => {
                                  setIsSecondZoneActive(val);
                                  updateZoneSetting(
                                    index,
                                    "isZoneActivated",
                                    val
                                  );
                                }}
                                statusOn={`${t("actions.activate")}`}
                                statusOff={`${t("actions.deactivate")}`}
                              />
                            )}
                            {(zone.zoneNumber === 1 ||
                              (zone.zoneNumber === 2 &&
                                zone.isZoneActivated)) && (
                              <>
                                <DisabledField
                                  label={`${t(
                                    "pages.installationSettings.zones.fieldsLabels.circuitType"
                                  )}`}
                                  value={
                                    circuitTypeToDisplay[
                                      zone.circuitType as InstallationComissioningZoneCircuitTypeEnum
                                    ]
                                  }
                                />
                                <SimpleDropdown
                                  label={`${t(
                                    "pages.installationSettings.zones.fieldsLabels.zoneHeatingCooling"
                                  )}`}
                                  currentValue={zone.mode}
                                  dropdownOptions={modeDropdownOptions}
                                  onChange={(val) =>
                                    updateZoneSetting(index, "mode", val)
                                  }
                                />
                                <Divider
                                  sx={{
                                    width: "100%",
                                    borderColor:
                                      theme.colors.colorsBorderBorderSecondary,
                                  }}
                                />
                                {(zone.mode ===
                                  InstallationZoneOperationModeEnum.BOTH ||
                                  zone.mode ===
                                    InstallationZoneOperationModeEnum.HEATING) && (
                                  <>
                                    <SecondaryTitle
                                      title={`${t(
                                        "pages.installationSettings.zones.zoneTitle"
                                      )} ${zone.zoneNumber} - ${t(
                                        "pages.installationSettings.zones.flowTemperatureLimits"
                                      )} - ${t(
                                        "pages.installationSettings.zones.Heating"
                                      )}`}
                                      variant="sm"
                                    />
                                    <NumberInput
                                      initialValue={zone.minFlowHeatTemp?.value}
                                      changeValue={(val) => {
                                        updateZoneSetting(
                                          index,
                                          "minFlowHeatTemp",
                                          {
                                            ...zone.minFlowHeatTemp,
                                            value: val,
                                          }
                                        );
                                      }}
                                      decimalPlaces={2}
                                      step={0.5}
                                      min={-200}
                                      max={
                                        (zone.maxFlowHeatTemp?.value || 201) -
                                          1 || 200
                                      }
                                      label={`${t(
                                        "pages.installationSettings.zones.fieldsLabels.flowTemperatureMinLimit"
                                      )}`}
                                    />
                                    <NumberInput
                                      initialValue={zone.maxFlowHeatTemp?.value}
                                      changeValue={(val) => {
                                        updateZoneSetting(
                                          index,
                                          "maxFlowHeatTemp",
                                          {
                                            ...zone.maxFlowHeatTemp,
                                            value: val,
                                          }
                                        );
                                      }}
                                      decimalPlaces={2}
                                      step={0.5}
                                      min={
                                        (zone.minFlowHeatTemp?.value || -199) +
                                          1 || -200
                                      }
                                      max={200}
                                      label={`${t(
                                        "pages.installationSettings.zones.fieldsLabels.flowTemperatureMaxLimit"
                                      )}`}
                                    />
                                    <Divider
                                      sx={{
                                        width: "100%",
                                        borderColor:
                                          theme.colors
                                            .colorsBorderBorderSecondary,
                                      }}
                                    />
                                  </>
                                )}
                                {(zone.mode ===
                                  InstallationZoneOperationModeEnum.BOTH ||
                                  zone.mode ===
                                    InstallationZoneOperationModeEnum.COOLING) && (
                                  <>
                                    <SecondaryTitle
                                      title={`${t(
                                        "pages.installationSettings.zones.zoneTitle"
                                      )} ${zone.zoneNumber} - ${t(
                                        "pages.installationSettings.zones.flowTemperatureLimits"
                                      )} - ${t(
                                        "pages.installationSettings.zones.Cooling"
                                      )}`}
                                      variant="sm"
                                    />
                                    <NumberInput
                                      initialValue={zone.minFlowCoolTemp?.value}
                                      changeValue={(val) => {
                                        updateZoneSetting(
                                          index,
                                          "minFlowCoolTemp",
                                          {
                                            ...zone.minFlowCoolTemp,
                                            value: val,
                                          }
                                        );
                                      }}
                                      decimalPlaces={2}
                                      step={0.5}
                                      min={-200}
                                      max={
                                        (zone.maxFlowCoolTemp?.value || 201) -
                                          1 || 200
                                      }
                                      label={`${t(
                                        "pages.installationSettings.zones.fieldsLabels.flowTemperatureMinLimit"
                                      )}`}
                                    />
                                    <NumberInput
                                      initialValue={zone.maxFlowCoolTemp?.value}
                                      changeValue={(val) => {
                                        updateZoneSetting(
                                          index,
                                          "maxFlowCoolTemp",
                                          {
                                            ...zone.maxFlowCoolTemp,
                                            value: val,
                                          }
                                        );
                                      }}
                                      decimalPlaces={2}
                                      step={0.5}
                                      min={
                                        (zone.minFlowCoolTemp?.value || -199) +
                                          1 || -200
                                      }
                                      max={200}
                                      label={`${t(
                                        "pages.installationSettings.zones.fieldsLabels.flowTemperatureMaxLimit"
                                      )}`}
                                    />

                                    <Divider
                                      sx={{
                                        width: "100%",
                                        borderColor:
                                          theme.colors
                                            .colorsBorderBorderSecondary,
                                      }}
                                    />
                                  </>
                                )}
                                <SetPointsSection
                                  index={index}
                                  updateNestedZoneSetting={
                                    updateNestedZoneSetting
                                  }
                                  updateZoneSetting={updateZoneSetting}
                                  zone={zone}
                                />
                              </>
                            )}
                          </React.Fragment>
                        )}
                        <UnsavedChangesModal
                          tabName={t(
                            "pages.installationSettings.tabsNames.zones"
                          )}
                          condition={isSomethingChanged}
                          discardChanges={onDiscardChanges}
                        />
                        {isConfirmChangesModalOpen && (
                          <ConfirmChangesModal
                            isOpen={isConfirmChangesModalOpen}
                            onCancel={() => setIsConfirmChangesModalOpen(false)}
                            onOk={() => onConfirmSettigsChanges()}
                            page={`${t(
                              "pages.installationSettings.tabsNames.zonesInInstaller"
                            )}`}
                            block={`${t(
                              "pages.installationSettings.tabsNames.installerSettings"
                            )}`}
                            icon={
                              <Tool02
                                size="24"
                                style={{
                                  color:
                                    theme.colors
                                      .colorsForegroundFgWarningPrimary,
                                }}
                                strokeWidth={2}
                              />
                            }
                            showHeatPumpNote={
                              initialIsSecondZoneActive !== isSecondZoneActive
                            }
                          >
                            <DifferencesToShowInModal
                              differencesToShow={differencesToShow}
                            />
                          </ConfirmChangesModal>
                        )}
                        {isNotificationModalOpen && (
                          <NotificationModal
                            isOpen={isNotificationModalOpen}
                            onOkPress={() => setIsNotificationModalOpen(false)}
                            icon={
                              <Tool02
                                size="24"
                                style={{
                                  color:
                                    theme.colors
                                      .colorsForegroundFgWarningPrimary,
                                }}
                                strokeWidth={2}
                              />
                            }
                          />
                        )}
                      </TabWrapper>
                    }
                    rolesToCheck={["INSTALLATION_READ"]}
                  />
                }
              />
            );
          })}
        </Routes>
      </Box>
    </Box>
    // <TabWrapper
    //   onDiscardPress={onDiscardChanges}
    //   onSaveChangesPress={onSaveChanges}
    //   isDiscardDisabled={!isSomethingChanged}
    //   isSaveDisabled={!isSomethingChanged}
    // >
    //   <MainTitle title={t("pages.installationSettings.zones.mainTitle")} />
    //   {zonesSettingsAsArray.map((zone, index) => {
    // const modeDropdownOptions = [
    //   {
    //     label: t("pages.installationSettings.zones.Heating"),
    //     value: InstallationZoneOperationModeEnum.HEATING,
    //   },
    //   {
    //     label: t("pages.installationSettings.zones.Cooling"),
    //     value: InstallationZoneOperationModeEnum.COOLING,
    //   },
    //   {
    //     label: t("pages.installationSettings.zones.HeatingAndCooling"),
    //     value: InstallationZoneOperationModeEnum.BOTH,
    //   },
    //   {
    //     label: t("pages.installationSettings.zones.disabled"),
    //     value: InstallationZoneOperationModeEnum.NONE,
    //   },
    // ];

    // const circuitTypeToDisplay = {
    //   [InstallationComissioningZoneCircuitTypeEnum.DIRECT]: t(
    //     "pages.installationSettings.zones.circuitTypes.direct"
    //   ),
    //   [InstallationComissioningZoneCircuitTypeEnum.MIXING]: t(
    //     "pages.installationSettings.zones.circuitTypes.mixing"
    //   ),
    // };
    //     return (
    //   zonesCount > 0 &&
    //   (index === 0 || (index === 1 && zonesCount > 1)) && (
    //     <React.Fragment key={`zone${zone.zoneNumber}`}>
    //       {zone.zoneNumber! > 1 && (
    //         <Divider
    //           sx={{
    //             width: "100%",
    //             borderColor: theme.colors.colorsBorderBorderSecondary,
    //           }}
    //         />
    //       )}
    //       <SecondaryTitle
    //         title={`${t("pages.installationSettings.zones.zoneTitle")} ${
    //           zone.zoneNumber
    //         }`}
    //         variant="md"
    //       />
    //       <DisabledField
    //         label={`${t(
    //           "pages.installationSettings.zones.fieldsLabels.circuitType"
    //         )}`}
    //         value={
    //           circuitTypeToDisplay[
    //             zone.circuitType as InstallationComissioningZoneCircuitTypeEnum
    //           ]
    //         }
    //       />
    //       <SimpleDropdown
    //         label={`${t(
    //           "pages.installationSettings.zones.fieldsLabels.zoneHeatingCooling"
    //         )}`}
    //         currentValue={zone.mode}
    //         dropdownOptions={modeDropdownOptions}
    //         onChange={(val) => updateZoneSetting(index, "mode", val)}
    //       />
    //       <Divider
    //         sx={{
    //           width: "100%",
    //           borderColor: theme.colors.colorsBorderBorderSecondary,
    //         }}
    //       />
    //       <SecondaryTitle
    //         title={`${t("pages.installationSettings.zones.zoneTitle")} ${
    //           zone.zoneNumber
    //         } - ${t(
    //           "pages.installationSettings.zones.flowTemperatureLimits"
    //         )}`}
    //         variant="sm"
    //       />
    //       <NumberInput
    //         initialValue={zone.minFlowHeatTemp?.value}
    //         changeValue={(val) => {
    //           updateZoneSetting(index, "minFlowHeatTemp", {
    //             ...zone.minFlowHeatTemp,
    //             value: val,
    //           });
    //         }}
    //         decimalPlaces={2}
    //         min={-200}
    //         max={(zone.maxFlowHeatTemp?.value || 201) - 1 || 200}
    //         label={`${t(
    //           "pages.installationSettings.zones.fieldsLabels.heatingFlowTemperatureMinLimit"
    //         )}`}
    //       />
    //       <NumberInput
    //         initialValue={zone.maxFlowHeatTemp?.value}
    //         changeValue={(val) => {
    //           updateZoneSetting(index, "maxFlowHeatTemp", {
    //             ...zone.maxFlowHeatTemp,
    //             value: val,
    //           });
    //         }}
    //         decimalPlaces={2}
    //         min={(zone.minFlowHeatTemp?.value || -199) + 1 || -200}
    //         max={200}
    //         label={`${t(
    //           "pages.installationSettings.zones.fieldsLabels.heatingFlowTemperatureMaxLimit"
    //         )}`}
    //       />
    //       <NumberInput
    //         initialValue={zone.minFlowCoolTemp?.value}
    //         changeValue={(val) => {
    //           updateZoneSetting(index, "minFlowCoolTemp", {
    //             ...zone.minFlowCoolTemp,
    //             value: val,
    //           });
    //         }}
    //         decimalPlaces={2}
    //         min={-200}
    //         max={(zone.maxFlowCoolTemp?.value || 201) - 1 || 200}
    //         label={`${t(
    //           "pages.installationSettings.zones.fieldsLabels.coolingFlowTemperatureMinLimit"
    //         )}`}
    //       />
    //       <NumberInput
    //         initialValue={zone.maxFlowCoolTemp?.value}
    //         changeValue={(val) => {
    //           updateZoneSetting(index, "maxFlowCoolTemp", {
    //             ...zone.maxFlowCoolTemp,
    //             value: val,
    //           });
    //         }}
    //         decimalPlaces={2}
    //         min={(zone.minFlowCoolTemp?.value || -199) + 1 || -200}
    //         max={200}
    //         label={`${t(
    //           "pages.installationSettings.zones.fieldsLabels.coolingFlowTemperatureMaxLimit"
    //         )}`}
    //       />
    //       {zone.mode !== InstallationZoneOperationModeEnum.NONE && (
    //         <Divider
    //           sx={{
    //             width: "100%",
    //             borderColor: theme.colors.colorsBorderBorderSecondary,
    //           }}
    //         />
    //       )}
    //       <SetPointsSection
    //         index={index}
    //         updateNestedZoneSetting={updateNestedZoneSetting}
    //         updateZoneSetting={updateZoneSetting}
    //         zone={zone}
    //       />
    //     </React.Fragment>
    //   )
    // );
    //   })}
    // <UnsavedChangesModal
    //   tabName={t("pages.installationSettings.tabsNames.zones")}
    //   condition={isSomethingChanged}
    // />
    // {isConfirmChangesModalOpen && (
    //   <ConfirmChangesModal
    //     isOpen={isConfirmChangesModalOpen}
    //     onCancel={() => setIsConfirmChangesModalOpen(false)}
    //     onOk={() => onConfirmSettigsChanges()}
    //     page={`${t("pages.installationSettings.tabsNames.zones")}`}
    //   >
    //     <DifferencesToShowInModal differencesToShow={differencesToShow} />
    //   </ConfirmChangesModal>
    // )}
    // </TabWrapper>
  );
}
