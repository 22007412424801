import React, { useEffect, useState } from "react";
import useTheme from "../../../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useAppDispatch } from "../../../../../../../../store";
import {
  installationApi,
  useGetInstallationSettingsQuery,
  useSetInstallationSettingsMutation,
} from "../../../../../../../../store/services/installation";
import {
  AdditionalSourcePriorityForDhwEnum,
  AdditionalSourcePriorityForHeatingEnum,
  IAdditionalInlineHeatingSource,
  IInstallationSettingsByIdResponse,
  ISetInstallationSettings,
  ISetInstallerSettignsComissioningAdditionalInlineHeatingSource,
} from "../../../../../../../../store/services/models/installations/installationSettings";
import TabWrapper from "../../../../TabWrapper";
import MainTitle from "../../../../components/MainTitle/MainTitle";
import { Box, Divider } from "@mui/material";
import SecondaryTitle from "../../../../components/SecondaryTitle/SecondaryTitle";
import RadioGroup, {
  IRadioGroupItem,
} from "../../../../components/RadioGroup/RadioGroup";
import NumberInput from "../../../../components/NumberInput/NumberInput";
import {
  baseFindDifferences,
  cleanValuesForRequest,
} from "../../../../helpers/findDifferences";
import UnsavedChangesModal from "../../../../components/UnsavedChangesModal/UnsavedChangesModal";
import ConfirmChangesModal from "../../../../components/ConfirmChangesModal/ConfirmChangesModal";
import { Tool02 } from "untitledui-js-base";
import DifferencesToShowInModal from "./DifferencesToShowInModal";
import NotificationModal from "../../../../components/NotificationModal/NotificationModal";

export interface IAdditionalInlineHeatingSourcePrioritiesAndThresholds
  extends IAdditionalInlineHeatingSource {
  additionalSourcePriorityForHeating: AdditionalSourcePriorityForHeatingEnum;
  additionalSourcePriorityForDhw: AdditionalSourcePriorityForDhwEnum;
}

export default function AdditionalHeatingSourcePrioritiesAndThresholds() {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  const { t: heatingRadioT } = useTranslation("cloud_ui", {
    keyPrefix:
      "pages.installationSettings.installerSettings.additionalHeatingSourcePrioritiesAndThresholds.heatingRadio",
  });
  const { t: DHWRadioT } = useTranslation("cloud_ui", {
    keyPrefix:
      "pages.installationSettings.installerSettings.additionalHeatingSourcePrioritiesAndThresholds.dhwRadio",
  });
  const { t: fieldsLabels } = useTranslation("cloud_ui", {
    keyPrefix:
      "pages.installationSettings.installerSettings.additionalHeatingSourcePrioritiesAndThresholds.fieldsLabels",
  });
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  const {
    data: settings,
    isFetching: isFetchingSettings,
    isLoading: isLoadingSettings,
    refetch: refetchSettings,
  } = useGetInstallationSettingsQuery({
    uuid: id!,
    isRealData: params.get("dummyData") === "false",
  });

  const [setInstallationSettings] = useSetInstallationSettingsMutation();
  const [settingsParameters, setSettingsParameters] = useState<
    IInstallationSettingsByIdResponse | undefined
  >(settings);

  const [
    additionalInlineHeatingSourceSettingsData,
    setAdditionalInlineHeatingSourceSettingsData,
  ] = useState<IAdditionalInlineHeatingSourcePrioritiesAndThresholds>();
  const [
    initialAdditionalInlineHeatingSourceSettingsData,
    setInitialAdditionalInlineHeatingSourceSettingsData,
  ] = useState<IAdditionalInlineHeatingSourcePrioritiesAndThresholds>();

  const [initialSettings, setInitialSettings] =
    useState<IInstallationSettingsByIdResponse | null>(null);

  const [isSomethingChanged, setIsSomethingChanged] = useState(false);
  const [isConfirmChangesModalOpen, setIsConfirmChangesModalOpen] =
    useState<boolean>(false);
  const [differencesToShow, setDifferencesToShow] = useState<any>();
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);

  const heatingRadioOptions: Array<IRadioGroupItem> = [
    {
      title: heatingRadioT("labels.additionalInlineHeatingSource"),
      description: heatingRadioT("descriptions.additionalInlineHeatingSource"),
      value: AdditionalSourcePriorityForHeatingEnum.INLINE,
    },
    {
      title: heatingRadioT("labels.additionalBufferTankHeatingSource"),
      description: heatingRadioT(
        "descriptions.additionalBufferTankHeatingSource"
      ),
      value: AdditionalSourcePriorityForHeatingEnum.BUFFER_TANK,
    },
  ];

  const DHWRadioOptions: Array<IRadioGroupItem> = [
    {
      title: DHWRadioT("labels.additionalInlineHeatingSource"),
      description: DHWRadioT("descriptions.additionalInlineHeatingSource"),
      value: AdditionalSourcePriorityForDhwEnum.INLINE,
    },
    {
      title: DHWRadioT("labels.additionalBufferTankHeatingSource"),
      description: DHWRadioT("descriptions.additionalBufferTankHeatingSource"),
      value: AdditionalSourcePriorityForDhwEnum.DHW_TANK,
    },
  ];

  useEffect(() => {
    if (settings) {
      setSettingsParameters(settings);
    }
  }, [settings]);

  useEffect(() => {
    if (settingsParameters) {
      setInitialSettings(JSON.parse(JSON.stringify(settingsParameters)));
      setAdditionalInlineHeatingSourceSettingsData(
        JSON.parse(
          JSON.stringify({
            ...settingsParameters.installerSettings.commissioning
              .additionalInlineHeatingSource,
            additionalSourcePriorityForHeating:
              settingsParameters.installerSettings.commissioning.priorities
                .additionalSourcePriorityForHeating,
            additionalSourcePriorityForDhw:
              settingsParameters.installerSettings.commissioning.priorities
                .additionalSourcePriorityForDhw,
          })
        )
      );
      setInitialAdditionalInlineHeatingSourceSettingsData(
        JSON.parse(
          JSON.stringify({
            ...settingsParameters.installerSettings.commissioning
              .additionalInlineHeatingSource,
            additionalSourcePriorityForHeating:
              settingsParameters.installerSettings.commissioning.priorities
                .additionalSourcePriorityForHeating,
            additionalSourcePriorityForDhw:
              settingsParameters.installerSettings.commissioning.priorities
                .additionalSourcePriorityForDhw,
          })
        )
      );
    }
  }, [settingsParameters]);

  const checkForChanges = () => {
    if (
      JSON.stringify(additionalInlineHeatingSourceSettingsData) !==
      JSON.stringify(initialAdditionalInlineHeatingSourceSettingsData)
    ) {
      setIsSomethingChanged(true);
    } else {
      setIsSomethingChanged(false);
    }
  };

  useEffect(() => {
    checkForChanges();
  }, [additionalInlineHeatingSourceSettingsData]);

  const updateSettings = (field: string, value: any) => {
    const updatedSettings = { ...additionalInlineHeatingSourceSettingsData };
    (updatedSettings as any)[field] = value;
    setAdditionalInlineHeatingSourceSettingsData(
      updatedSettings as IAdditionalInlineHeatingSourcePrioritiesAndThresholds
    );
  };

  const onDiscardChanges = () => {
    setAdditionalInlineHeatingSourceSettingsData(
      JSON.parse(
        JSON.stringify(initialAdditionalInlineHeatingSourceSettingsData)
      )
    );
  };

  const onSaveChanges = () => {
    const differences = baseFindDifferences(
      initialAdditionalInlineHeatingSourceSettingsData!,
      additionalInlineHeatingSourceSettingsData!
    );
    setDifferencesToShow(differences);

    setIsConfirmChangesModalOpen(true);
  };

  const createRequest = () => {
    let newSettings: Partial<any> = cleanValuesForRequest(differencesToShow);

    return {
      installerSettings: {
        commissioning: {
          additionalInlineHeatingSource: {
            additionalSourcePriorityForDhw:
              newSettings.additionalSourcePriorityForDhw,
            additionalSourcePriorityForHeating:
              newSettings.additionalSourcePriorityForHeating,
            outdoorThresholdTemp: newSettings.outdoorThresholdTemp,
            heatBalanceThresholdForDwh: newSettings.heatBalanceForDwh,
            heatBalanceThresholdForHeating: newSettings.heatBalanceForHeating,
          },
        },
      },
    };
  };

  const onConfirmSettigsChanges = () => {
    const updatedSettings = { ...initialSettings };
    updatedSettings.installerSettings = {
      ...(updatedSettings.installerSettings as any),
      commissioning: {
        ...(updatedSettings.installerSettings?.commissioning as any),
        additionalInlineHeatingSource: {
          ...(updatedSettings.installerSettings?.commissioning
            .additionalInlineHeatingSource as any),
          outdoorThresholdTemp:
            additionalInlineHeatingSourceSettingsData?.outdoorThresholdTemp,
          heatBalanceForDwh:
            additionalInlineHeatingSourceSettingsData?.heatBalanceForDwh,
          heatBalanceForHeating:
            additionalInlineHeatingSourceSettingsData?.heatBalanceForHeating,
        },
        priorities: {
          ...(updatedSettings.installerSettings?.commissioning
            .priorities as any),
          additionalSourcePriorityForHeating:
            additionalInlineHeatingSourceSettingsData?.additionalSourcePriorityForHeating,
          additionalSourcePriorityForDhw:
            additionalInlineHeatingSourceSettingsData?.additionalSourcePriorityForDhw,
        },
      },
    };
    setIsConfirmChangesModalOpen(false);
    const requestBody = createRequest();

    setInstallationSettings({
      ...(requestBody as ISetInstallationSettings),
      uuid: id!,
    })
      .unwrap()
      .then(() => {
        dispatch(
          installationApi.util.updateQueryData(
            "getInstallationSettings",
            { uuid: id!, isRealData: params.get("dummyData") === "false" },
            (draftSettings) => {
              Object.assign(draftSettings, updatedSettings);
            }
          )
        );
        setIsNotificationModalOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <TabWrapper
      onDiscardPress={onDiscardChanges}
      onSaveChangesPress={onSaveChanges}
      isDiscardDisabled={!isSomethingChanged}
      isSaveDisabled={!isSomethingChanged}
    >
      <MainTitle
        title={t(
          "pages.installationSettings.installerSettings.additionalHeatingSourcePrioritiesAndThresholds.mainTitle"
        )}
      />
      <Box sx={{ mt: theme.spacing.spacingXl }} />
      <SecondaryTitle
        title={`${t(
          "pages.installationSettings.installerSettings.additionalHeatingSourcePrioritiesAndThresholds.priorityForHeating"
        )}`}
        variant="md"
      />
      <RadioGroup
        onChange={(val) =>
          updateSettings("additionalSourcePriorityForHeating", val)
        }
        radioOptions={heatingRadioOptions}
        selectedValue={
          additionalInlineHeatingSourceSettingsData?.additionalSourcePriorityForHeating
        }
      />
      <Divider
        sx={{
          width: "100%",
          borderColor: theme.colors.colorsBorderBorderSecondary,
        }}
      />
      <SecondaryTitle
        title={`${t(
          "pages.installationSettings.installerSettings.additionalHeatingSourcePrioritiesAndThresholds.priorityForDHW"
        )}`}
        variant="md"
      />
      <RadioGroup
        onChange={(val) =>
          updateSettings("additionalSourcePriorityForDhw", val)
        }
        radioOptions={DHWRadioOptions}
        selectedValue={
          additionalInlineHeatingSourceSettingsData?.additionalSourcePriorityForDhw
        }
      />
      <Divider
        sx={{
          width: "100%",
          borderColor: theme.colors.colorsBorderBorderSecondary,
        }}
      />
      <SecondaryTitle
        title={`${t(
          "pages.installationSettings.installerSettings.additionalHeatingSourcePrioritiesAndThresholds.thresholds"
        )}`}
        variant="md"
      />
      <NumberInput
        initialValue={
          additionalInlineHeatingSourceSettingsData?.outdoorThresholdTemp.value
        }
        changeValue={(val) =>
          updateSettings("outdoorThresholdTemp", {
            ...additionalInlineHeatingSourceSettingsData?.outdoorThresholdTemp,
            value: val,
          })
        }
        decimalPlaces={1}
        step={0.5}
        label={`${fieldsLabels("outdorTempThreshold")}`}
        min={-1000}
        max={10000}
      />
      <NumberInput
        initialValue={
          additionalInlineHeatingSourceSettingsData?.heatBalanceForHeating.value
        }
        changeValue={(val) =>
          updateSettings("heatBalanceForHeating", {
            ...additionalInlineHeatingSourceSettingsData?.heatBalanceForHeating,
            value: val,
          })
        }
        decimalPlaces={1}
        step={0.5}
        label={`${fieldsLabels("heatBalanceThresholdForHeating")}`}
        min={-1000}
        max={10000}
      />
      <NumberInput
        initialValue={
          additionalInlineHeatingSourceSettingsData?.heatBalanceForDwh.value
        }
        changeValue={(val) =>
          updateSettings("heatBalanceForDwh", {
            ...additionalInlineHeatingSourceSettingsData?.heatBalanceForDwh,
            value: val,
          })
        }
        decimalPlaces={1}
        step={0.5}
        label={`${fieldsLabels("heatBalanceThresholdForDHW")}`}
        min={-1000}
        max={10000}
      />
      <UnsavedChangesModal
        tabName={t(
          "pages.installationSettings.tabsNames.installerAdditionalHeatingSourcePrioritiesAndThresholds"
        )}
        condition={isSomethingChanged}
      />
      {isConfirmChangesModalOpen && (
        <ConfirmChangesModal
          isOpen={isConfirmChangesModalOpen}
          onCancel={() => setIsConfirmChangesModalOpen(false)}
          onOk={() => onConfirmSettigsChanges()}
          page={`${t(
            "pages.installationSettings.tabsNames.installerAdditionalHeatingSourcePrioritiesAndThresholds"
          )}`}
          block={`${t(
            "pages.installationSettings.tabsNames.installerSettings"
          )}`}
          // showHeatPumpNote={differencesToShow.state}
          icon={
            <Tool02
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        >
          <DifferencesToShowInModal differencesToShow={differencesToShow} />
        </ConfirmChangesModal>
      )}
      {isNotificationModalOpen && (
        <NotificationModal
          isOpen={isNotificationModalOpen}
          onOkPress={() => setIsNotificationModalOpen(false)}
          icon={
            <Tool02
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        />
      )}
    </TabWrapper>
  );
}
