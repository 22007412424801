import { Box, Divider, InputLabel } from "@mui/material";
import React from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import MainTitle from "../MainTitle/MainTitle";
import DisabledField from "../DisabledField/DisabledField";
import TextFieldWithLabel from "../TextFieldWithLabel/TextFieldWithLabel";
import {
  IInstallationCommmissioningInfo,
  IInstallationDetailsData,
} from "../../InfoDetailsPage";
import AddressAutocompleteComponent from "../../../../../../components/AddressAutocompleteComponent/AddressAutocompleteComponent";
import OrganizationSection from "../OrganizationSection/OrganizationSection";
import { IInstallationDetailSharingInfo } from "../../../../../../store/services/models/installations/installationsCommon";

export interface IInstallationDetailsColumnProps {
  installationDetails: IInstallationDetailsData | undefined;
  setInstallationDetails: (fieldName: string, newValue: any) => void;
  commissioningInfo: IInstallationCommmissioningInfo | undefined;
  sharingInfo: IInstallationDetailSharingInfo | undefined;
}

export default function InstallationDetailsColumn({
  installationDetails,
  setInstallationDetails,
  commissioningInfo,
  sharingInfo,
}: IInstallationDetailsColumnProps) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.infoDetailsPage.installationDetails",
  });

  return (
    <Box
      sx={{
        height: "100%",
        maxWidth: "610px",
        width: "50%",
        borderRight: `1px solid ${theme.colors.colorsBorderBorderSecondary}`,
        overflow: "auto",
        "&::-webkit-scrollbar": {
          width: "4px",
          height: "4px",
          backgroundColor: theme.colors.colorsBackgroundBgSecondaryAlt,
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "4px",
          backgroundColor: "rgba(0,0,0,0.2)",
          minHeight: "24px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: theme.colors.colorsTextTextQuaternary,
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "415px",
          pr: theme.spacing.spacing3xl,
        }}
      >
        <MainTitle
          text={t("mainTitle")}
          sx={{ mb: theme.spacing.spacing2xl }}
        />
        <DisabledField
          label={t("fieldsLabels.connectivityId")}
          value={installationDetails?.connectivityId}
        />
        <TextFieldWithLabel
          value={installationDetails?.name || ""}
          setValue={(newValue) => setInstallationDetails("name", newValue)}
          label={t("fieldsLabels.name")}
          placeholder={t("fieldsPlaceholders.name")}
        />
        <Box sx={{ width: "100%", mb: theme.spacing.spacingLg }}>
          <InputLabel
            shrink
            sx={{
              ...theme.typography[".text-sm-medium"],
              color: theme.colors.colorsTextTextSecondary,
            }}
          >
            {t("fieldsLabels.address")}
          </InputLabel>
          <AddressAutocompleteComponent
            onAddressSelect={(address) => {
              setInstallationDetails("location", address);
            }}
            address={installationDetails?.location || ""}
            placeholder={`${t("fieldsPlaceholders.address")}`}
          />
        </Box>
        {commissioningInfo?.commissionedDate && (
          <DisabledField
            label={t("fieldsLabels.registrationDate")}
            value={commissioningInfo?.commissionedDate}
          />
        )}
        <DisabledField
          label={t("fieldsLabels.commissionedBy")}
          value={
            commissioningInfo?.commissioner ||
            t("fieldsPlaceholders.notAvailable")
          }
        />
        <Divider
          sx={{
            mt: theme.spacing.spacing3xl,
            mb: theme.spacing.spacingXl,
            width: "100%",
            borderColor: theme.colors.colorsBorderBorderSecondary,
          }}
        />
        <MainTitle
          text={t("responsibleOrganizationsTitle")}
          sx={{ mb: theme.spacing.spacingXl }}
        />
        {sharingInfo && (
          <>
            {sharingInfo.parentOrganization && (
              <OrganizationSection
                title={t("responsibleOrganizations.parentOrganization")}
                name={sharingInfo.parentOrganization.name}
                phone={sharingInfo.parentOrganization.phone}
                address={sharingInfo.parentOrganization.address}
              />
            )}
            <OrganizationSection
              title={t("responsibleOrganizations.myOrganization")}
              name={sharingInfo.myOrganization.name}
              phone={sharingInfo.myOrganization.phone}
              address={sharingInfo.myOrganization.address}
              showArrow={!!sharingInfo.parentOrganization}
            />
            {sharingInfo.childrenOrganizations?.length &&
              sharingInfo.childrenOrganizations.map((org, index) => (
                <OrganizationSection
                  title={t("responsibleOrganizations.childrenOrganizations")}
                  name={org.name}
                  address={org.address}
                  phone={org.phone}
                  showArrow
                  key={`childOrg_${index}`}
                />
              ))}
          </>
        )}
      </Box>
    </Box>
  );
}
