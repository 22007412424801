import React from "react";
import { useTranslation } from "react-i18next";
import { Divider, Typography } from "@mui/material";
import useTheme from "../../../../../../../../theme/hooks/useTheme";
import DefaultChange from "../../../../components/ConfirmChangesModal/DefaultChage";

export default function DifferencesToShowInModal({
  differencesToShow,
}: {
  differencesToShow: any;
}) {
  const { t } = useTranslation("cloud_ui");
  const theme = useTheme();
  
  return (
    <>
      {(differencesToShow.preferredTemp ||
        differencesToShow.deltaDeviationTemp) && (
        <Typography
          sx={{
            ...theme.typography[".text-md-semibold"],
            color: theme.colors.colorsTextTextPrimary,
            mb: theme.spacing.spacingXl,
          }}
        >
          {t("pages.installationSettings.installerSettings.dhw.mainTitle")}
        </Typography>
      )}
      {differencesToShow?.preferredTemp && (
        <DefaultChange
          changedValue={differencesToShow?.preferredTemp.value.newValue + "°C"}
          previousValue={
            differencesToShow?.preferredTemp.value.previousValue + "°C"
          }
          title={`${t(
            "pages.installationSettings.dhw.fieldsLabels.DHWTemperatureSetPoint"
          )}`}
        />
      )}
      {differencesToShow?.deltaDeviationTemp && (
        <DefaultChange
          changedValue={
            differencesToShow?.deltaDeviationTemp.value.newValue + "°C"
          }
          previousValue={
            differencesToShow?.deltaDeviationTemp.value.previousValue + "°C"
          }
          title={`${t(
            "pages.installationSettings.installerSettings.dhw.fieldsLabels.dhwRestartDeltaT"
          )}`}
        />
      )}
      {(differencesToShow.preferredTemp ||
        differencesToShow.deltaDeviationTemp) && (
        <Divider
          sx={{
            width: "100%",
            borderColor: theme.colors.colorsBorderBorderSecondary,
          }}
        />
      )}
      {(differencesToShow.state || differencesToShow.inputPower) && (
        <Typography
          sx={{
            ...theme.typography[".text-md-semibold"],
            color: theme.colors.colorsTextTextPrimary,
            mb: theme.spacing.spacingLg,
            mt: theme.spacing.spacingLg,
          }}
        >
          {t("pages.installationSettings.installerSettings.dhw.secondaryTitle")}
        </Typography>
      )}
      {differencesToShow?.state && (
        <DefaultChange
          changedValue={differencesToShow?.state.newValue}
          previousValue={differencesToShow?.state.previousValue}
          title={`${t(
            "pages.installationSettings.installerSettings.dhw.fieldsLabels.additionalDHWTankHeatingSource"
          )}`}
        />
      )}
      {differencesToShow?.inputPower && (
        <DefaultChange
          changedValue={differencesToShow?.inputPower.value.newValue + " kW"}
          previousValue={
            differencesToShow?.inputPower.value.previousValue + " kW"
          }
          title={`${t(
            "pages.installationSettings.installerSettings.dhw.fieldsLabels.inputPower"
          )}`}
        />
      )}
    </>
  );
}
