export const alertsEn = {
  P01: {
    name: "Outdoor unit - high current detected",
    description:
      "When the unit detects that the input current is higher than the value set by the Eeprom of the outdoor unit, the unit will report a fault and shutdown for protection.",
    action: "",
  },
  P02: {
    name: "Compressor - high current detected",
    description:
      "When the compressor driver PCB detects that the compressor phase current exceeds the compressor phase protection current value, the unit will report a fault and shutdown.",
    action: "Accessories and special tools: Multimeter",
  },
  P03: {
    name: "Compressor driver overheating",
    description:
      "During compressor operation, the compressor driver PCB is detected to be overheating (the protection value is generally 95-100 degrees) or over-current protected, and the unit reports a fault and shutdown;",
    action:
      "This protection is generally caused by excessive system load. You can power on again and observe the operation of the unit:",
  },
  P04: {
    name: "Oil return function",
    description:
      "Running low frequency below F3 for more than 20 minutes will trigger the Oil return function which will speed up the compressor speed for a short period to make sure the oil circulates back to the compressor. When function finished, the compressor speed will be adjusted accordingly to the normal state.",
    action:
      "1. If the temperature difference between the inlet and outlet water is greater than 8 degrees during operation, check whether the water pump speed is set to high speed (if it is low speed, please adjust to medium speed or high speed), and also check whether the filter in the water system is blocked;",
  },
  P05: {
    name: "Condensing pressure high - switch",
    description:
      "This is normal protection and does not require any treatment.",
    action: "",
  },
  P06: {
    name: "Condensing pressure high - sensor Pd",
    description:
      "Fault shutdown is reported when a high pressure switch port disconnection is detected for 5 seconds (Compressor must run for min 1 minute)",
    action:
      "2. If it is triggered in the sanitary hot water mode (DHW), check whether the domestic water tank coil is too small (the minimum heat exchange area is 0,125㎡ / KWnominal). If it is too small, it may lead to low heat exchange capacity, so heat will continue to accumulate, and eventually due to the water temperature rising too fast also the current rises.",
  },
  P07: {
    name: "Compressor preheating",
    description:
      "If the high pressure detected by the high pressure sensor Pd is higher than the system pressure protection value after the compressor has been running for 1 minute, the unit will report faulty shutdown protection;",
    action: "",
  },
  P08: {
    name: "High compressor discharge temperature - Td",
    description:
      "When the unit is powered on, if the outdoor temperature is lower than -5 degrees, the unit will warm up the compressor oil for 30 minutes before start of the compressor.System will use a electric heater (crankcase heater) on the bottom part of the compressor, to heat up the compressor oil.",
    action:
      "3. During operation, observe the difference between the condenser coil temperature (TUP) and the outlet water temperature (TUO). Normally, the condenser coil temperature (TUP) is 1 to 2 degrees lower than the outlet water temperature (TUO). If TUP is higher than TUO, it may because the heat pump is unable to exchange heat, due to fouling of the plate heat changer (condenser). So the current rises and causes a fault, so as long as the plate changer is descaled and cleaned, the problem can be solved; Clean the plate heat exchanger (condenser).",
  },
  P09: {
    name: "High evaporating temperature - Tp",
    description:
      "When the discharge temperature (Td) is higher than the value of protection shutdown point during unit operation, compressor stop.",
    action: "",
  },
  P10: {
    name: "Input voltage out of range",
    description:
      "In the cooling mode, the evaporator coil temperature (Tp) is higher than the evaporator coil over-temperature protection value (62 degrees), then the unit reports a fault and shutdown;",
    action:
      "4. In cooling mode this fault can be caused by reduced air flow on the evaporator -  dirty evaporator, obstacles in front or behind evaporator, faulty fan motor, broken fan blades...",
  },
  P11: {
    name: "Outdoor temperature out of range",
    description:
      "When the unit is with power supply (either running or standby) and the input voltage is detected to be lower than 140V or higher than 270V, the unit will report a fault and shutdown;",
    action: "",
  },
  P12: {
    name: "Outdoor temperature compressor speed limitation",
    description:
      "Heating mode: when the outdoor temperature is lower than -25°C, or the outdoor temperature is higher than 45°C, the unit will stop the compressor;",
    action:
      "5. False EEPROM settings - upload new EEPROM for the outdoor unit.",
  },
  P13: {
    name: "Evaporating pressure switch",
    description:
      "Cooling mode: when the outdoor temperature is lower than 8 degrees, or the outdoor temperature is higher than 65 degrees, the unit will report a fault and shutdown;",
    action: "",
  },
  P101: {
    name: "Evaporating pressure low - sensor Ps",
    description:
      "The unit is following the envelope of the compressor running conditions. At certain outdoor temperatures the max compressor speed is automatically limited.",
    action:
      "6. Check whether the input voltage is normal. If it is lower than the rated voltage by more than 10%, the overcurrent protection may be triggered. Fix the power supplier to adjust the power supply voltage.",
  },
  F01: {
    name: "Outdoor temp. sensor failure - Ta",
    description:
      "This is a normal protection function, which is signaled form the OU to the IU controller as Informationrmation.",
    action: "Accessories and special tools: clamp flow meter. Multimeter",
  },
  F02: {
    name: "Evaporating temp. sensor failure - Ts",
    description:
      "When the unit has been up and running for 3 minutes, if a low pressure switch disconnection is detected, a fault is reported;",
    action: "1. Refer to the P02 troubleshooting method",
  },
  F03: {
    name: "Compressor discharge temp. sensor failure - Td",
    description:
      "Three minutes after compressor start, if the low-pressure value is less than EEPROM protection value for 20 seconds continuously, then the low pressure  protection will be reported and unit will STOP.",
    action: "",
  },
  F04: {
    name: "Compressor suction temp. sensor failure - Ts",
    description:
      "When entering defrost or within 2 minutes after defrosting,  low pressure protection will be detected.",
    action:
      "2. In cooling mode, please check whether the fan is running normally (whether the speed is low, such as below 500 rpm) or only one fan is running in the dual fan system, if yes please refer to the handling of fan failure, check and replace the motor if needed.",
  },
  F05: {
    name: "Evaporator pressure sensor failure - Ps",
    description:
      "When the outdoor main PCB detects a short circuit or disconnection of the outdoor temp. sensor port, the unit reorts a fault and shuts down;",
    action: "",
  },
  F06: {
    name: "Condensing pressure sensor failure - Pd",
    description:
      "When the outdoor main PCB detects a short circuit or disconnection of the outdoor coil temp. sensor - Tp, the unit reports a fault and shuts down;",
    action:
      "3. In cooling mode this fault can be caused by reduced air flow on the evaporator -  dirty evaporator, obstacles in front or behind evaporator, faulty fan motor, broken fan blades...",
  },
  F07: {
    name: "Condensing pressure too high - switch",
    description:
      "When the outdoor main PCB detects a short circuit of the discharge temp. sensor or after compressor switching on 10min, detect a broken of discharge temp. sensor, the unit reports a fault and shuts down;",
    action: "",
  },
  F08: {
    name: "Low pressure switch failure",
    description:
      "When the outdoor main PCB detects a short circuit or disconnection of the suction tempe. sensor port, the unit reports a fault and shuts down;",
    action: "4. Exchange for compressor driver PCB",
  },
  F09: {
    name: "Fan motor 1 failure",
    description:
      "When the outdoor main PCB detects that the low pressure sensor is disconnected or out of range, the unit reports a fault and shuts down;",
    action:
      "This is normal protection function and does not require any treatment.",
  },
  F10: {
    name: "Fan motor 2 failure",
    description:
      "When the outdoor main PCB detects that the high pressure sensor is disconnected, the unit reports a fault and shuts down;",
    action: "Accessories and specialized tools:/",
  },
  F11: {
    name: "Evaporating pressure too low - Ps",
    description:
      "When the unit has 3 consecutive high pressure switch protections (P05) within 30 minutes, F07 will be reported and the unit will shut down, and the unit cannot be startup unless re-power.",
    action:
      "1. Please check error history to see if the high pressure value is higher than 42 Bar when unit report this error; if so, please check the value of high pressure sensor (please refer to High Pressure Sensor Failure section for description). If the latest high pressure protection value was less than 41 bar, then the high pressure switch may failed, pls replace high pressure switch.",
  },
  F12: {
    name: "Condensing pressure too high - sensor Pd",
    description: "Fault on the outdoor unit main PCB.",
    action: "",
  },
  E01: {
    name: "Communication failure between indoor - outdoor units",
    description:
      "The unit with only one fan (Capacity ≤ 12kW): If the fan motor startup failed when the outdoor main PCB send fan's running command, the unit will report DC fan A failure, then the unit will shutdown.",
    action:
      "2. Check if there is insufficient heat dissipation on the high-pressure side (condensing side)",
  },
  E02: {
    name: "Communication failure - Main OU PCB and Compressor driver",
    description:
      "The unit with 2 fan (Capaciy ≥ 15kW): If the fan motor startup failed when the outdoor main PCB send fan's running command, the unit will report DC fan A failure, then the unit will keep running but compressor frequency will be limited.",
    action:
      "Data recording, after confirming the temperature difference between the inlet and outlet water, shut down and repower the unit. Then the following judgments are made:",
  },
  E03: {
    name: "Compressor driver damage",
    description:
      "The unit with only one fan (Capacity ≤ 12kW): If the fan motor startup failed when the outdoor main PCB send fan's running command, the unit will report DC fan A failure, then the unit will shutdown.",
    action: "",
  },
  E04: {
    name: "Compressor driver too high current",
    description:
      "The unit with 2 fan (Capaciy ≥ 15kW): If the fan motor startup failed when the outdoor main PCB send fan's running command, the unit will report DC fan A failure, then the unit will keep running but compressor frequency will be limited.",
    action:
      "2.1. Confirm that the temperature difference between the water inlet and outlet is within 5 degrees, if it exceeds 8 degrees, please check whether the water pump is set to low speed, if yes please try to let the water pump run at medium or high speed, to ensure that the water flow is within the normal range; Also check the filters in the water system, whether there is a dirty blockage, and if needed, clean it;",
  },
  E05: {
    name: "Compressor failed to start",
    description:
      "Low pressure protection happens three times within 30min (Unit recovers automatically in first two times and no failure displayed). Unit stops and failure can only be cleared by repowering.",
    action: "",
  },
  E06: {
    name: "Compressor driver too high voltage",
    description:
      "If High pressure protection P06 happens three times within 30min, unit stops and failure can only be cleared by repowering.",
    action:
      "2.2. Confirm the temperature difference between the outlet water temperature and the TC temperature or TW temperature. Normally, the outlet water temperature will be 3 to 5 degrees higher than the TC or TW, if it is more than 5 degrees, please check whether the installation position of the TC and TW sensors is installed in an inappropriate position, the TC or TW should be installed in the middle and upper part of the water tank;",
  },
  E07: {
    name: "Outdoor unit current reading failure",
    description:
      "When the main PCB detects that there is no communication with the master unit (indoor unit) and continues for 120 seconds, the main PCB will report a failure and the compressor will also stop.",
    action: "",
  },
  E08: {
    name: "Outdoor unit EEPROM reading failure",
    description:
      "When there is no communication between the outdoor main PCB and compressor driver PCB for 30s, the outdoor main PCB will report a failure and unit will be shut down while the driver PCB will also stop working.",
    action:
      "2.3. During the operation of the unit, observe whether the temperature of the condenser coil (TUP) is higher than the temperature of the outlet water (TUO). If the temperature of the condenser coil (TUP) is higher than the temperature of the outlet water by more than 3 degrees, check if there is fouling of the plate heat exchanger (condenser). As long as the plate heat exchanger (condenser) is descaled and cleaned, the problem can be solved;",
  },
  E10: {
    name: "Fan motor driver failure",
    description:
      "Hardware Damage of compressor driver PCB-  Compressor Phase Current Sampling Component",
    action: "",
  },
  F17: {
    name: "Condenser inlet water temp. sensor failure - TUI",
    description:
      "When compressor is operating, if the current of the compressor is higher than the protection value of the driver, unit will report a failure and be shut shown;",
    action:
      "2.4. In cooling mode this fault can be caused by reduced air flow on the evaporator -  dirty evaporator, obstacles in front or behind evaporator, faulty fan motor, broken fan blades...",
  },
  F16: {
    name: "Condenser outlet water temp. sensor failure - TUO",
    description:
      "When the compressor driver PCB fails to activate compressor, unit will report a failure and stop.",
    action: "",
  },
  F18: {
    name: "Condensing temperature sensor failure - TUP",
    description:
      "When the driver PCB detects an excessively high or low rectified DC voltage, drive PCB will stop working and the unit will stop.",
    action:
      '3. If all of above solutions can not solve the problem, the EEV may be abnormal, please refer to the "Electronic Expansion Valve Investigation Part".',
  },
  F28: {
    name: "Unknown, Modbus register 2122: BIT13",
    description:
      "If input current is over 4A when compressor is not working or input current is less than 1A when compressor is running at F4 or higher step, unit will report a failure and stop.",
    action: "Accessories and specialized tools:/",
  },
  F19: {
    name: "Unknown, Modbus register 2122: BIT14",
    description:
      "When the outdoor main PCB chip cannot read the parameters or there is a parameter verification error, unit will report a failure and stop.",
    action:
      "1. Determine if there is insufficient heat dissipation on the high-pressure side (condensing side).",
  },
  F20: {
    name: "Unknown, Modbus register 2122: BIT15",
    description:
      "When the Main outdoor PCB detects that there is no communication with the fan motor driver PCB and continues for 30 seconds, the main PCB will report a failure and the unit will stop.",
    action:
      "After recording the data and confirming the temperature difference between the inlet and outlet water (TUO and TUI) and the outlet water temperature at the time of the unit's protection shutdown, power down and restart the unit to allow the unit to run again, and make the following judgments during the operation process:",
  },
  P16: {
    name: "Unknown, Modbus register 2120: BIT14",
    description:
      "When display detects that the water inlet temperature sensor is disconnected or shorted, it will report a failure but unit will not be shut down. Unit keeps working with water inlet temperauter + stop delta T as target temperature. If both the water inlet and outlet water temperature fails, it will be shut down;",
    action: "",
  },
  C03: {
    name: "Communication failure - Indoor/Outdoor unit",
    description:
      "When display detects that the water outlet temperature sensor is disconnected or shorted, it will report a failure but unit will not be shut down. Unit keeps working with water inlet temperauter + stop delta T as target temperature. If both the water inlet and outlet water temperature fails, it will be shut down;",
    action:
      "1.1. Confirm that the temperature difference between the inlet and outlet water (TUO and TUI) of the unit is within 5 degrees, if it exceeds 8 degrees, please check whether the water pump (P0) is set to low speed, and try to let the water pump run at medium or high speed to ensure that the water flow is within the normal range; check the filters in the water system, whether there is a dirty blockage, and if needed clean it;",
  },
  C04: {
    name: "Unknown, Modbus register 100201",
    description:
      "When cooling mode is active, if the indoor coil temperature sensor is detected to be disconnected or shorted, the unit will report a fault and be shut down. When heating mode or hot water mode is active,failure remains but unit will continue to run for heating or hot water;",
    action: "",
  },
  C05: {
    name: "Unknown, Modbus register 100202",
    description:
      "When the P0 water pump is set to be controlled by PWM pump, if without feedback signal is detected after the water pump runs for 120 seconds, F28 is reported and the unit is shut down.",
    action:
      "1.2. Confirm the temperature difference between the outlet water temperature (TUO) of the unit and the TC temperature or TW temperature, under normal circumstances, the outlet water temperature (TUO) will be 3 to 5 degrees higher than the TC or TW, if it is more than 5 degrees, please check whether the installation position of the TC and TW sensors is checked off or installed in an inappropriate position, the TC or TW should be installed in the middle and upper part of the water tank as far as possible;",
  },
  C06: {
    name: "Unknown, Modbus register 100203",
    description:
      "When the water flow sensor is installed on the outdoor main PCB, if there is no feedback signal is detected from the water flow sensor, it means the water flow sensor is failure, the unit will report a fault and be shut down.",
    action: "",
  },
  C07: {
    name: "Unknown, Modbus register 100204",
    description:
      "When equipped with refrigerant leakage detection function, if refrigerant leakage is detected, after 3 times reported the P16 , F20 will then be reported, at this time, the unit will be locked up and cannot be recovered until repower;",
    action:
      "1.3. During the operation of the unit, observe whether the temperature of the condenser coil (TUP) is higher than the temperature of the outlet water (TUO). If the temperature of the condenser coil (TUP) is higher than the temperature of the outlet water (TUO) by more than 3 degrees, there is scaling in the plate exchange, and it is necessary to clean the water side of the plate exchange to remove the scale;",
  },
  C08: {
    name: "Unknown, Modbus register 100205",
    description:
      "NOTE: For units with propane sensor only. When refrigerant leakage is detected, this fault will be reported.",
    action: "",
  },
  C09: {
    name: "Unknown, Modbus register 100206",
    description: "Bad or none communication between indoor and outdoor unit",
    action:
      "1.4. If it occurs in cooling mode, check for poor ventilation around the unit, check the evaporator of the outdoor unit for dirt and blockage, and clean debris from the outdoor heat exchanger (evaporator);",
  },
  C10: {
    name: "Unknown, Modbus register 100207",
    description: "Ambient sensor is disconnected",
    action:
      "Check for poor ventilation around the outside unit, compare the outdoor temperature sensor display and the measured outdoor temperature on site to see if there is a big difference, if the difference between the two is big, check the Outdoro temperature sensor.",
  },
  C11: {
    name: "Unknown, Modbus register 100208",
    description: "Ambient sensor report a short circuit",
    action: "",
  },
  C12: {
    name: "DHW temp. sensor disconnected - TDW",
    description: "Outdoor unit TUO sensor malfunction.",
    action:
      '2. If the above troubleshooting is unable to solve the problem, the electronic expansion valve of the refrigerant system may be abnormal, please refer to the "Electronic Expansion Valve Investigation Part".',
  },
  C13: {
    name: "DHW temp. sensor Short-circuit - TDW",
    description: "Outdoor unit TUO sensor malfunction.",
    action:
      "This is normal protection function and does not require any treatment.",
  },
  C14: {
    name: "Buffer tank temp. sensor disconnected - THC",
    description: "Outdoor unit TUI sensor malfunction",
    action:
      "Accessories and special tools: discharge temperature sensor (Td), refrigerant, refrigerant leak detector, pressure gauges. electronic scale, multimeter",
  },
  C15: {
    name: "Buffer tank temp. sensor Short-circuit - THC",
    description: "Outdoor unit TUI sensor malfunction",
    action: "1. Examine the factors of insufficient refrigerant:",
  },
  C16: {
    name: "Mixing Zone 1 temp. sensor disconnected - TV1",
    description: "Outdoor unit TUP sensor malfunction",
    action: "",
  },
  C17: {
    name: "Mixing Zone 1 temp. sensor Short-circuit - TV1",
    description: "Outdoor unit TUP sensor malfunction",
    action:
      "1.1. Under the standby mode (in stand-by for min 30 mins), check the refrigerant pressure value, and confirm whether there is serious leakage of refrigerant quantity initially. The judgment way is: whether the saturation temperature corresponding to the pressure value currently displayed, is the same as the outdoor temperature, and if it is lower than the outdoor temperature by more than 5 degrees, it can be judged that there is a leakage of refrigerant basically;",
  },
  C18: {
    name: "Mixing Zone 2 temp. sensor disconnected - TV2",
    description: "DHW sensor is disconnected",
    action: "",
  },
  C19: {
    name: "Mixing Zone 2 temp. sensor Short-circuit - TV2",
    description: "DHW sensor short circuit",
    action:
      "1.2. For split units, check whether the refrigerant piping exceeds the max refrigerant piping lengths or if refrigerant was not added based on the longer piping as factory prefiiling; if so, add refrigerant according to the length of the piping;",
  },
  C20: {
    name: "Room temp. sensor 1 disconnected - TR1",
    description: "Buffer tank sensor is disconnected",
    action: "",
  },
  C21: {
    name: "Room temp. sensor 1 Short-circuit - TR1",
    description: "Buffer tank sensor short circuit",
    action:
      "1.3. Start the heat pump, make sure that the evaporator is clean, and observe the change of low pressure, if the low pressure is too low (i.e. the evaporating temperature is lower than the outdoor temperature by more than 10K), and the running time is more than 5 minutes, you can initially judge that it is suspected that the refrigerant is leaking, and you can temporarily add approx. 100-200g of refrigerant to see whether the low pressure of the system is rising or not. And whether the discharge temperature is dropping. If so, there is a leakage point in the unit, repair the leakage and fill the unit with new refrigerant. Re-evacuate the unit and refill the refrigerant according to the refrigerant quantity on the nameplate;",
  },
  C22: {
    name: "Room temp. sensor 2 disconnected - TR2",
    description: "Heating zone 1 supply line sensor is disconnected",
    action: "",
  },
  C23: {
    name: "Room temp. sensor 2 Short-circuit - TR2",
    description: "Heating zone 1 supply line sensor short circuit",
    action: "2. Examine the factors of insufficient heat transfer",
  },
  C24: {
    name: "Unknown, Modbus address: 100229",
    description: "Heating zone 2 supply line sensor is disconnected",
    action:
      "Please check the error history, if there is no high pressure protection, the effect of poor heat exchange can be ruled out.",
  },
  C25: {
    name: "Unknown, Modbus address: 100230",
    description: "Heating zone 2 supply line sensor short circuit",
    action: "",
  },
  C26: {
    name: "Unknown, Modbus address: 100231",
    description: "Zone 1 Room sensor is disconnected",
    action:
      "3. Sensor problem: Disconnect the temp. sensor (Td) from the Main outdoor PCB, and use a multimeter to measure the resistance of the Td temp. sensor, and compare it with the NTC50kOhm temp. sensor resistance table. If there is a large deviation, then replace the discharge temperature sensor;",
  },
  C27: {
    name: "Unknown, Modbus address: 100232",
    description: "Zone 1 Room sensor short circuit",
    action: "",
  },
  C28: {
    name: "Unknown, Modbus address: 100233",
    description: "Zone 2 Room sensor is disconnected",
    action:
      '4. If the above troubleshooting fails to solve the problem, the electronic expansion valve of the refrigerant system may be abnormal, please refer to "Electronic Expansion Valve Troubleshooting Part".',
  },
  C29: {
    name: "Unknown, Modbus address: 100234",
    description: "Zone 2 Room sensor short circuit",
    action: "Accessories and Specialized Tools: Motors",
  },
  C30: {
    name: "Unknown, Modbus address: 100235",
    description: "Logging temperature sensor failure - sensor 1",
    action:
      "1. In cooling mode, please confirm whether the fan motor is running normally (whether the speed is low, such as less than 500 rpm) or only one fan is running in the dual fan system. If yes, please refer to the solution of fan motor failure, investigate and replace the motor.",
  },
  C31: {
    name: "Unknown, Modbus address: 100236",
    description: "Logging temperature sensor failure - sensor 1",
    action: "",
  },
  C32: {
    name: "Unknown, Modbus address: 100237",
    description: "Logging temperature sensor failure - sensor 2",
    action:
      "2. In cooling mode, you can check whether the evaporator is dirty or blocked or the heat island effect is caused by the installation position, resulting in high current. You can clean the evaporator, adjust the installation location, or install an discharge duct to eliminate the heat island effect;",
  },
  C33: {
    name: "Unknown, Modbus address: 100238",
    description: "Logging temperature sensor failure - sensor 2",
    action: "Accessories and special tools: main PCB, fan motor, multimeter",
  },
  C34: {
    name: "Unknown, Modbus address: 100239",
    description: "Logging temperature sensor failure - sensor 3",
    action:
      "1.1. Using a multimeter, measure whether the voltage between L and N line is normal nor not, and the voltage range of the single phase unit should be in the range of 140V-270V.",
  },
  C35: {
    name: "Unknown, Modbus address: 100240",
    description: "Logging temperature sensor failure - sensor 3",
    action: "",
  },
  C36: {
    name: "Unknown, Modbus address: 100241",
    description: "Logging temperature sensor failure - sensor 4",
    action:
      "2. Disconnect the power, unplug the DC fan from the PCB, and then re-power to confirm whether the voltage detection abnormality is caused by the damage of the motor. If the fault is cleared, then replace the DC fan, if the fault is not cleared, then replace the main PCB;",
  },
  C37: {
    name: "Unknown, Modbus address: 100242",
    description: "Logging temperature sensor failure - sensor 4",
    action: "If the temperature sensor would make false reading:",
  },
  C38: {
    name: "Unknown, Modbus address: 100243",
    description: "Logging temperature sensor failure - sensor 5",
    action:
      "Accessories and special tools: outdoor temperature sensor, multimeter",
  },
  C39: {
    name: "Unknown, Modbus address: 100244",
    description: "Logging temperature sensor failure - sensor 5",
    action:
      "1. Through the multimeter, test if the outdoor temperature sensor resistance value corresponding to temperature resistance table, if there is a deviation, then replace the sensor",
  },
  C40: {
    name: "Unknown, Modbus address: 100245",
    description: "Logging temperature sensor failure - sensor 6",
    action: "",
  },
  C41: {
    name: "Unknown, Modbus address: 100246",
    description: "Logging temperature sensor failure - sensor 6",
    action:
      "2. Confirm whether the current actual outdoor temperature reaches the limitation point of heat pump outdoor temperature. If so, please wait for the outdoor temperature reach to a normal range, and the unit will back to normal;",
  },
  C42: {
    name: "Unknown, Modbus address: 100247",
    description: "Logging temperature sensor failure - sensor 7",
    action: "",
  },
  C43: {
    name: "Unknown, Modbus address: 100248",
    description: "Logging temperature sensor failure - sensor 7",
    action:
      "3. Check whether the installation position of the ambient sensor is blocked by ice or exposed to direct sunlight, if so, please protect the outdoor temperature sensor, to prevent icing or exposure to direct sunlight;",
  },
  C44: {
    name: "Communication failure - External electrical meter",
    description: "Logging temperature sensor failure - sensor 8",
    action: "",
  },
  C45: {
    name: "Mixing valve Zone 1 failure",
    description: "Logging temperature sensor failure - sensor 8",
    action:
      "4. Check whether there is any heat island effect (cold island effect) in the installation position of the unit, and adjust the installation position or install an discharge duct to eliminate the heat island or cold island effect;",
  },
  C46: {
    name: "Mixing valve Zone 2 failure",
    description: "Logging temperature sensor failure - sensor 9",
    action:
      "This error will not show in display, but if target water temperature have a big difference with real water temperature, and unit could not run in high speed, you can consider if it because the outdoor temperature is too high, so frequency is limited. You can confirm the accuracy of the outdoor temperature check according to the troubleshooting of P11;",
  },
  C47: {
    name: "Low water flow",
    description: "Logging temperature sensor failure - sensor 9",
    action: "Accessories and specialized tools: short cables, main PCB",
  },
  C48: {
    name: "Water flow too low",
    description: "Logging temperature sensor failure - sensor 10",
    action:
      "According to F11 low pressure checking this error, since the unit does not have low pressure switch, this protection does not normally occur. If it does, follow the wiring diagram to check whether the reserved input port has not been shorted, resulting in a false alarm;",
  },
  C49: {
    name: "Condenser outlet water temp. too high - TUO",
    description: "Logging temperature sensor failure - sensor 10",
    action: "1. Check whether the outdoor temperature is too low.",
  },
  C50: {
    name: "High condenser outlet water temp. - TUO",
    description: "Communication failure with external electrical meter",
    action:
      "2. Check the refrigerant pressure value during standby (min standby time must be 30 min), and initially confirm whether there is a serious leakage of the refrigerant amount. The judgment method is: Check whether the saturation temperature corresponding to the currently displayed pressure value is the same as the outdoor temperature. If it is more than 5 degrees lower than the outdoor temperature, it can be a sign that the refrigerant is leaking.",
  },
  C51: {
    name: "Condenser outlet water temp. too low - TUO",
    description:
      "Measured temperature in heating zone 1 is above the calculated set point for the zone",
    action:
      "3. Power on the unit and observe the changes in low-pressure. If the low-pressure is too low (that is, the evaporating temperature is more than 10 degrees lower than the outdoor temperature), and the running time is more than 5 minutes, it can be sign that there is a refrigerant leakage. 100 to 200g of regrigerant can be temporarily added, and see if the system low pressure rises. If so, vacuum the unit again and refill the refrigerant according to the refrigerant amount on the nameplate;",
  },
  C52: {
    name: "Low condenser outlet water temp. - TUO",
    description:
      "Measured temperature in heating zone 2 is above the calculated set point for the zone",
    action:
      "4. If there was severe frosting or unclean defrosting before the unit failure, you can refer to the defrosting failure troubleshooting method.",
  },
  C53: {
    name: "Unknown, Modbus address: 100166",
    description:
      "The flow generated by the P0 circulation pump in the outdoor unit is below the limit set for this outdoor unit.",
    action:
      '5. If the above troubleshooting fails to solve the problem, it may be caused by an abnormality in the electronic expansion valve of the refrigerant system. Please refer to the "Troubleshooting - EEV',
  },
  C54: {
    name: "Unknown, Modbus address: 100167",
    description:
      "The flow generated by the P0 circulation pump in the outdoor unit is below the limit set for this outdoor unit.",
    action:
      "Accessories and tools: temp. sensor, outdoor main PCB, Multi-meter",
  },
  C55: {
    name: "Unknown, Modbus address: 100168",
    description: "If “Compressor stop” happens more than 3 times in 30min:",
    action:
      "1.1. Poor connect: find the connector of the outdoor temp. sensor according to the wiring diagram, then check whether the sensor terminal and outdoor main PCB terminal have poor connect, pull the sensor out again and install it back in after checking, if the fault code is cleared, then pull the sensor lead towards the electrical box to ensure that there is no pulling influence between the sensor terminal and the PCB terminal. If the fault is not cleared,check according to steps as below.",
  },
  C56: {
    name: "Unknown, Modbus address: 100169",
    description: "Stop compressor permanently.",
    action: "",
  },
  C57: {
    name: "Condensing temperature too low - TUP",
    description: "Sensor TUO reports too high temperature in heating mode.",
    action:
      "1.2. Sensor fault: disconnect the temperature sensor from the outdoor main PCB, then measure the resistance value of the sensor. If there is no resistance value or the resistance value is infinite,it means that the sensor is damaged. Therefore, the sensor should be replaced..",
  },
  C58: {
    name: "Low condensing temperature - TUP",
    description: "If “Compressor stop” happens more than 3 times in 30min:",
    action: "",
  },
  C59: {
    name: "Anti-legionella mode failure",
    description: "Stop compressor permanently.",
    action:
      "1.3. Outdoor main PCB fault: disconnect the temperature sensor of the outdoor main PCB and re-wire a spare sensor, then observe whether the fault on the control panel is cleared. If the fault is not cleared, it means that the outdoor main PCB is damaged and the outdoor main PCB should be replaced.",
  },
  C68: {
    name: "Outlet temp. too low in Defrost - TUO",
    description:
      "Sensor TUO reports too low temperature in heating or cooling mode.",
    action:
      "Accessories and tools: temp. sensor, outdoor main PCB, Multi-meter",
  },
  C69: {
    name: "Low outlet temp. in Defrost - TUO",
    description: "If “Compressor stop” happens more than 3 times in 30min:",
    action:
      "1.1. Poor connect: find the connector of the outdoor coil temp. sensor according to the wiring diagram, then check whether the sensor terminal and the outdoor main PCB terminal have poor connect, pull the sensor out again and install it back in after checking, if the fault code is cleared, then pull the sensor lead towards the electrical box to ensure that there is no pulling influence between the sensor terminal and the PCB terminal; if the fault is not cleared,check according to steps as below.",
  },
  "N/A": {
    name: "Unknown, Modbus register 100056",
    description: "Preserve",
    action: "",
  },
  "": {
    name: "",
    description: "",
    action: "",
  },
};
