import React, { useEffect, useState } from "react";
import useTheme from "../../../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  installationApi,
  useGetInstallationSettingsQuery,
  useSetInstallationSettingsMutation,
} from "../../../../../../../../store/services/installation";
import {
  IBufferTank,
  IInstallationSettingsByIdResponse,
  ISetInstallationSettings,
  ISetInstallerSettignsComissioningBufferTank,
} from "../../../../../../../../store/services/models/installations/installationSettings";
import TabWrapper from "../../../../TabWrapper";
import MainTitle from "../../../../components/MainTitle/MainTitle";
import { Box, Divider, SxProps, Typography } from "@mui/material";
import SettingsSwitcher from "../../../../components/SettingsSwitcher/SettingsSwitcher";
import SecondaryTitle from "../../../../components/SecondaryTitle/SecondaryTitle";
import NumberInput from "../../../../components/NumberInput/NumberInput";
import {
  baseFindDifferences,
  cleanValuesForRequest,
} from "../../../../helpers/findDifferences";
import { ActiveOrInactiveEnum } from "../../../../../../../../store/services/models/installations/installationsCommon";
import UnsavedChangesModal from "../../../../components/UnsavedChangesModal/UnsavedChangesModal";
import ConfirmChangesModal from "../../../../components/ConfirmChangesModal/ConfirmChangesModal";
import { Tool02 } from "untitledui-js-base";
import DefaultChange from "../../../../components/ConfirmChangesModal/DefaultChage";
import NotificationModal from "../../../../components/NotificationModal/NotificationModal";
import { useAppDispatch } from "../../../../../../../../store";

export default function BufferTank() {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  const {
    data: settings,
    isFetching: isFetchingSettings,
    isLoading: isLoadingSettings,
    refetch: refetchSettings,
  } = useGetInstallationSettingsQuery({
    uuid: id!,
    isRealData: params.get("dummyData") === "false",
  });

  const [setInstallationSettings] = useSetInstallationSettingsMutation();
  const [settingsParameters, setSettingsParameters] = useState<
    IInstallationSettingsByIdResponse | undefined
  >(settings);

  const [bufferTankSettingsData, setBufferTankSettingsData] =
    useState<IBufferTank>();
  const [initialBufferTankSettingsData, setInitialBufferTankSettingsData] =
    useState<IBufferTank>();

  const [initialSettings, setInitialSettings] =
    useState<IInstallationSettingsByIdResponse | null>(null);

  const [isSomethingChanged, setIsSomethingChanged] = useState(false);
  const [isConfirmChangesModalOpen, setIsConfirmChangesModalOpen] =
    useState<boolean>(false);
  const [differencesToShow, setDifferencesToShow] = useState<any>();
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (settings) {
      setSettingsParameters(settings);
    }
  }, [settings]);

  useEffect(() => {
    if (settingsParameters) {
      setInitialSettings(JSON.parse(JSON.stringify(settingsParameters)));
      setBufferTankSettingsData(
        JSON.parse(
          JSON.stringify(
            settingsParameters.installerSettings.commissioning.bufferTank
          )
        )
      );
      setInitialBufferTankSettingsData(
        JSON.parse(
          JSON.stringify(
            settingsParameters.installerSettings.commissioning.bufferTank
          )
        )
      );
    }
  }, [settingsParameters]);

  const checkForChanges = () => {
    if (
      JSON.stringify(bufferTankSettingsData) !==
      JSON.stringify(initialBufferTankSettingsData)
    ) {
      setIsSomethingChanged(true);
    } else {
      setIsSomethingChanged(false);
    }
  };

  useEffect(() => {
    checkForChanges();
  }, [bufferTankSettingsData]);

  const updateSettings = (field: string, value: any) => {
    const updatedSettings = { ...bufferTankSettingsData };
    (updatedSettings as any)[field] = value;
    setBufferTankSettingsData(updatedSettings as IBufferTank);
  };

  const onDiscardChanges = () => {
    setBufferTankSettingsData(
      JSON.parse(JSON.stringify(initialBufferTankSettingsData))
    );
  };

  const onSaveChanges = () => {
    const differences = baseFindDifferences(
      initialBufferTankSettingsData!,
      bufferTankSettingsData!
    );
    setDifferencesToShow(differences);

    setIsConfirmChangesModalOpen(true);
  };

  const createRequest = () => {
    let newSettings: Partial<ISetInstallerSettignsComissioningBufferTank> =
      cleanValuesForRequest(differencesToShow);

    return {
      installerSettings: {
        commissioning: {
          bufferTank: newSettings,
        },
      },
    };
  };

  const onConfirmSettigsChanges = () => {
    const updatedSettings = { ...initialSettings };
    updatedSettings.installerSettings = {
      ...(updatedSettings.installerSettings as any),
      commissioning: {
        ...(updatedSettings.installerSettings?.commissioning as any),
        bufferTank: bufferTankSettingsData,
      },
    };
    setIsConfirmChangesModalOpen(false);
    const requestBody = createRequest();
    setInstallationSettings({
      ...(requestBody as ISetInstallationSettings),
      uuid: id!,
    })
      .unwrap()
      .then(() => {
        dispatch(
          installationApi.util.updateQueryData(
            "getInstallationSettings",
            { uuid: id!, isRealData: params.get("dummyData") === "false" },
            (draftSettings) => {
              Object.assign(draftSettings, updatedSettings);
            }
          )
        );
        setIsNotificationModalOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <TabWrapper
      onDiscardPress={onDiscardChanges}
      onSaveChangesPress={onSaveChanges}
      isDiscardDisabled={!isSomethingChanged}
      isSaveDisabled={!isSomethingChanged}
    >
      <MainTitle
        title={t(
          "pages.installationSettings.installerSettings.bufferTank.mainTitle"
        )}
      />
      <Box sx={{ mt: theme.spacing.spacingXl }} />

      <SettingsSwitcher
        label={`${t(
          "pages.installationSettings.installerSettings.bufferTank.fieldsLabels.bufferTank"
        )}`}
        state={
          //   DHWSettingsData?.antiLegionella?.state === ActiveOrInactiveEnum.ACTIVE
          //     ? true
          //     : false
          bufferTankSettingsData?.state === ActiveOrInactiveEnum.ACTIVE
            ? true
            : false
        }
        changeState={(val) =>
          updateSettings(
            "state",
            val ? ActiveOrInactiveEnum.ACTIVE : ActiveOrInactiveEnum.INACTIVE
          )
        }
      />
      <Divider
        sx={{
          width: "100%",
          borderColor: theme.colors.colorsBorderBorderSecondary,
        }}
      />
      <SecondaryTitle
        title={`${t(
          "pages.installationSettings.installerSettings.bufferTank.secondaryTitle"
        )}`}
        variant="md"
      />
      <SettingsSwitcher
        label={`${t(
          "pages.installationSettings.installerSettings.bufferTank.fieldsLabels.additionalBuffweTankHeationgSource"
        )}`}
        state={
          //   DHWSettingsData?.antiLegionella?.state === ActiveOrInactiveEnum.ACTIVE
          //     ? true
          //     : false
          true
        }
        changeState={(val) => {
          //   updateAntiLegionellaSettings(
          //     val ? ActiveOrInactiveEnum.ACTIVE : ActiveOrInactiveEnum.INACTIVE,
          //     "state"
          //   );
          {
          }
        }}
      />
      <NumberInput
        initialValue={bufferTankSettingsData?.inputPower.value}
        changeValue={(val) =>
          updateSettings("inputPower", {
            ...bufferTankSettingsData?.inputPower,
            value: val,
          })
        }
        decimalPlaces={1}
        unit={"kW"}
        step={0.5}
        hint={`${t(
          "pages.installationSettings.installerSettings.dhw.fieldsHints.inputPower"
        )}`}
        label={`${t(
          "pages.installationSettings.installerSettings.dhw.fieldsLabels.inputPower"
        )}`}
        min={0}
        max={10000}
        inputWidth="105px"
      />
      <UnsavedChangesModal
        tabName={t("pages.installationSettings.tabsNames.installerBufferTank")}
        condition={isSomethingChanged}
      />
      {isConfirmChangesModalOpen && (
        <ConfirmChangesModal
          isOpen={isConfirmChangesModalOpen}
          onCancel={() => setIsConfirmChangesModalOpen(false)}
          onOk={() => onConfirmSettigsChanges()}
          page={`${t(
            "pages.installationSettings.tabsNames.installerBufferTank"
          )}`}
          block={`${t(
            "pages.installationSettings.tabsNames.installerSettings"
          )}`}
          // showHeatPumpNote={differencesToShow.state}
          icon={
            <Tool02
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        >
          <>
            {differencesToShow.state && (
              <Typography
                sx={{
                  ...theme.typography[".text-md-semibold"],
                  color: theme.colors.colorsTextTextPrimary,
                  mb: theme.spacing.spacingXl,
                }}
              >
                {t(
                  "pages.installationSettings.installerSettings.bufferTank.mainTitle"
                )}
              </Typography>
            )}
            {differencesToShow?.state && (
              <DefaultChange
                changedValue={differencesToShow?.state.newValue}
                previousValue={differencesToShow?.state.previousValue}
                title={`${t(
                  "pages.installationSettings.installerSettings.bufferTank.mainTitle"
                )}`}
              />
            )}
            {differencesToShow.state && (
              <Divider
                sx={{
                  width: "100%",
                  borderColor: theme.colors.colorsBorderBorderSecondary,
                }}
              />
            )}
            {differencesToShow.inputPower && (
              <Typography
                sx={{
                  ...theme.typography[".text-md-semibold"],
                  color: theme.colors.colorsTextTextPrimary,
                  mb: theme.spacing.spacingXl,
                  mt: theme.spacing.spacingXl,
                }}
              >
                {t(
                  "pages.installationSettings.installerSettings.bufferTank.secondaryTitle"
                )}
              </Typography>
            )}
            {differencesToShow?.inputPower && (
              <DefaultChange
                changedValue={
                  differencesToShow?.inputPower.value.newValue + "kW"
                }
                previousValue={
                  differencesToShow?.inputPower.value.previousValue + "kW"
                }
                title={`${t(
                  "pages.installationSettings.installerSettings.dhw.fieldsLabels.inputPower"
                )}`}
              />
            )}
          </>
        </ConfirmChangesModal>
      )}
      {isNotificationModalOpen && (
        <NotificationModal
          isOpen={isNotificationModalOpen}
          onOkPress={() => setIsNotificationModalOpen(false)}
          icon={
            <Tool02
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        />
      )}
    </TabWrapper>
  );
}
