import React, { useState } from "react";
import MainContainer from "../../../components/MainContainer/MainContainer";
import {
  Box,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import AcceptNewOrganization from "./AcceptNewOrganization";
import AcceptExistOrganization from "./AcceptExistOrganization";
import { useTranslation } from "react-i18next";
import useTheme from "../../../theme/hooks/useTheme";
import { Button } from "../../../components/Button/Button";
import { CheckCircle } from "untitledui-js-base";

export default function AcceptInvitation() {
  const { companyType, token } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("cloud_ui", { keyPrefix: "pages.company_organizations" });
  const { t: tActions } = useTranslation("cloud_ui", { keyPrefix: "actions" });
  const theme = useTheme();

  const [isSuccess, showSuccess] = useState(false);

  const goToOrganizations = () => {
    showSuccess(false);
    navigate("/admin");
  };

  return (
    <MainContainer>
      <CssBaseline />
      <Box
        sx={{
          margin: "4em auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: isSuccess ? "360px" : "440px",
        }}
      >
        <img src="/logomark.svg" alt={"Energy Save logo"} style={{ maxWidth: "300px" }} />
        {companyType === "new" ? (
          <>
            <Typography
              sx={{
                ...theme.typography[".display-sm-semibold"],
                textAlign: "center",
                paddingBottom: theme.spacing.spacing3xl,
              }}
            >
              {t("accept_new_organization.title")}
            </Typography>
            <AcceptNewOrganization token={token} onSuccess={() => showSuccess(true)} />
          </>
        ) : (
          <>
            <Typography
              sx={{
                ...theme.typography[".display-sm-semibold"],
                paddingBottom: theme.spacing.spacingXl,
                textAlign: "center",
              }}
            >
              {t("accept_exist_organization.title")}
            </Typography>
            <Typography
              sx={{
                ...theme.typography[".text-sm-regular"],
                paddingBottom: theme.spacing.spacing3xl,
                textAlign: "center",
              }}
            >
              {t("accept_exist_organization.description")}
            </Typography>
            <AcceptExistOrganization token={token} onSuccess={() => showSuccess(true)} />
          </>
        )}
      </Box>
      <Dialog open={isSuccess} fullWidth={true} sx={{ "& .MuiPaper-root": { maxWidth: "400px" } }}>
        <DialogTitle
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            marginBottom: theme.spacing.spacing4xl,
            padding: `${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl} 0`,
          }}
        >
          <Box
            sx={{
              width: "48px",
              height: "48px",
              borderRadius: "50%",
              backgroundColor: theme.colors.componentColorsUtilitySuccessUtilitySuccess100,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: theme.spacing.spacingXl,
            }}
          >
            <CheckCircle
              size="20"
              style={{
                color: theme.colors.componentColorsUtilitySuccessUtilitySuccess600,
              }}
              strokeWidth={2}
            />
          </Box>
          <Typography sx={{ ...theme.typography[".text-lg-semibold"] }}>
            {t("accept_success.title")}
          </Typography>
        </DialogTitle>
        <DialogActions
          sx={{
            padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Button
            label={`${tActions("ok")}`}
            onClick={goToOrganizations}
            sx={{
              display: "flex",
              ml: "0 !important",
              width: `calc(100% - ${theme.spacing.spacingLg} / 2)`,
            }}
          />
        </DialogActions>
      </Dialog>
    </MainContainer>
  );
}
