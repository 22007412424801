import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { SwitchHorizontal01 } from "untitledui-js-base";
import useTheme from "../../../../../../../theme/hooks/useTheme";
import NumberInput from "../../../components/NumberInput/NumberInput";

export interface IFixedFlowPointSection {
  flowTemperature: number;
  setFlowTemperature: (val: number) => void;
  updateTypeToCurve: () => void;
  maxFlowTemp: number;
  minFlowTemp: number;
}

export default function FixedFlowPointSection({
  flowTemperature,
  setFlowTemperature,
  updateTypeToCurve,
  minFlowTemp,
  maxFlowTemp,
}: IFixedFlowPointSection) {
  const { t } = useTranslation("cloud_ui");
  const theme = useTheme();

  const [flowTemperatureValue, setFlowTemperatureValue] =
    useState<number>(flowTemperature);

  const [minTemp, setMinTemp] = useState<number>(minFlowTemp || -50);
  const [maxTemp, setMaxTemp] = useState<number>(maxFlowTemp || 50);

  useEffect(() => {
    setFlowTemperatureValue(flowTemperature);
  }, [flowTemperature]);

  useEffect(() => {
    setMinTemp(minFlowTemp);
    if (minFlowTemp > flowTemperatureValue) {
      setFlowTemperature(minFlowTemp);
    }
  }, [minFlowTemp]);

  useEffect(() => {
    setMaxTemp(maxFlowTemp);
    if (maxFlowTemp < flowTemperatureValue) {
      setFlowTemperature(maxFlowTemp);
    }
  }, [maxFlowTemp]);

  return (
    <>
      <NumberInput
        initialValue={flowTemperatureValue}
        changeValue={(val) => {
          setFlowTemperature(val);
        }}
        min={minTemp}
        max={maxTemp}
        decimalPlaces={2}
        step={0.5}
        hint={`${t(
          "pages.installationSettings.zones.fieldHints.flowTemperature",
          { from: minTemp, to: maxTemp }
        )}`}
      />
      {/* <Box
        component={"button"}
        onClick={updateTypeToCurve}
        sx={{
          mb: theme.spacing.spacingXl,
          border: "none",
          height: "36px",
          width: "fit-content",
          padding: `${theme.spacing.spacingMd} ${theme.spacing.spacingLg}`,
          color:
            theme.colors
              .componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorFg,
          backgroundColor: "initial",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          "&:hover": {
            color:
              theme.colors
                .componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorFgHover,
          },
        }}
      >
        <SwitchHorizontal01 size="20" strokeWidth={2} />
        <Typography
          sx={{
            ...theme.typography[".text-sm-semibold"],
            ml: theme.spacing.spacingXs,
          }}
        >
          {t("pages.installationSettings.zones.buttons.useCurve")}
        </Typography>
      </Box> */}
    </>
  );
}
