import React, { useEffect, useState } from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { DefaultTFuncReturn } from "i18next";
import { Box, SxProps, Typography } from "@mui/material";

export interface IRadioGroupItem {
  title?: DefaultTFuncReturn;
  description?: DefaultTFuncReturn;
  value: any;
}

export interface IRadioGroupProps {
  radioOptions: Array<IRadioGroupItem>;
  selectedValue: any;
  onChange: (value: any) => void;
}

export default function RadioGroup({
  onChange,
  radioOptions,
  selectedValue,
}: IRadioGroupProps) {
  const theme = useTheme();

  const baseRadioOptionStyles: SxProps = {
    display: "flex",
    padding: `${theme.spacing.spacingMd} 0`,
    backgroundColor: "initial",
    maxWidth: "590px",
    cursor: "pointer",
    position: "relative",
    boxSizing: "border-box",
    outline: "none",
    border: "none",
  };

  const baseRadioIconStyles: SxProps = {
    width: "16px",
    minWidth: "16px",
    height: "16px",
    border: `1px solid ${theme.colors.colorsBorderBorderPrimary}`,
    borderRadius: "50%",
    ml: "auto",
    mt: "3px",
    mr: theme.spacing.spacingXl,
  };

  const selectedRadioIconStyles: SxProps = {
    ...baseRadioIconStyles,
    border: `5px solid ${theme.colors.colorsBackgroundBgBrandSolid}`,
  };

  const [selected, setSelected] = useState<any>(selectedValue);

  useEffect(() => {
    setSelected(selectedValue);
  }, [selectedValue]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mb: theme.spacing.spacingXl,
      }}
    >
      {radioOptions.map((option, index) => {
        return (
          <Box
            key={`radio-item-${index}`}
            sx={{
              ...baseRadioOptionStyles,
              cursor: "pointer",
            }}
            component={"button"}
            onClick={() => onChange(option.value)}
          >
            <Box
              sx={
                selected === option.value
                  ? { ...selectedRadioIconStyles }
                  : {
                      ...baseRadioIconStyles,
                    }
              }
            />
            {(option.title || option.description) && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                {option.title && (
                  <Typography
                    sx={{
                      ...theme.typography[".text-md-medium"],
                      color: theme.colors.colorsTextTextSecondary,
                      textAlign: "start",
                    }}
                  >
                    {option.title}
                  </Typography>
                )}
                {option.description && (
                  <Typography
                    sx={{
                      ...theme.typography[".text-sm-regular"],
                      color: theme.colors.colorsTextTextTertiary,
                      textAlign: "start",
                    }}
                  >
                    {option.description}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
}
