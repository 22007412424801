import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useTheme from "../../../theme/hooks/useTheme";
import { Alert, Box, InputLabel, Link, Paper, TextField, Typography } from "@mui/material";
import { Button } from "../../../components/Button/Button";
import { Eye, EyeOff } from "untitledui-js-base";
import { useNavigate } from "react-router";
import AuthenticationAPI from "../../../api/AuthenticationAPI";
import AuthenticationRequest from "../../../api/requests/AuthenticationRequest";
import { AxiosError } from "axios";
import { useAcceptOrganizationInvitationMutation } from "../../../store/services/organizations";

type AcceptExistOrganizationProps = {
  token?: string;
  onSuccess: () => void;
};

export default function AcceptExistOrganization({
  token,
  onSuccess,
}: AcceptExistOrganizationProps) {
  const { t } = useTranslation("cloud_ui", { keyPrefix: "pages.company_organizations" });
  const { t: tErrors } = useTranslation("cloud_ui", { keyPrefix: "errors" });
  const theme = useTheme();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [securePass, setSecurePass] = useState(true);
  const [error, setError] = useState("");
  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const [acceptOrganizationInvitation, { isLoading: isLoadingAccept }] =
    useAcceptOrganizationInvitationMutation();

  const renderShowPassBtn = () => {
    if (securePass) {
      return (
        <EyeOff
          size="13"
          onClick={() => setSecurePass(false)}
          color={theme.colors.colorsForegroundFgQuinary}
          style={{ cursor: "pointer" }}
        />
      );
    } else {
      return (
        <Eye
          size="13"
          onClick={() => setSecurePass(true)}
          color={theme.colors.colorsForegroundFgQuinary}
          style={{ cursor: "pointer" }}
        />
      );
    }
  };

  const acceptInvitation = () => {
    setError("");
    setIsLoginLoading(true);
    const api = new AuthenticationAPI(navigate);
    api
      .login(new AuthenticationRequest(email, password))
      .then((response) => {
        window.localStorage.setItem("auth_token", response.data.token);
        window.localStorage.setItem("ROLE", response.data.role);
        if (token) {
          acceptOrganizationInvitation({
            token: token!,
          })
            .unwrap()
            .then(() => {
              onSuccess();
            })
            .catch(() => setError(tErrors("somethingWentWrong") as string));
        }
      })
      .catch((error: AxiosError) => {
        if (error.response && error.response.status === 401) {
          setError(tErrors("login") as string);
        } else {
          setError(tErrors("somethingWentWrong") as string);
        }
      })
      .finally(() => setIsLoginLoading(false));
  };

  return (
    <Paper
      className="paper-form"
      sx={{
        padding: `${theme.spacing.spacing4xl} !important`,
        width: "100%",
      }}
    >
      {error && (
        <Alert severity="error" sx={{ marginBottom: theme.spacing.spacing3xl }}>
          {error}
        </Alert>
      )}
      <Box>
        <InputLabel
          shrink
          sx={{
            color: theme.colors.colorsTextTextSecondary,
            fontWeight: 500,
          }}
        >
          {t("accept_exist_organization.fields.labels.email")}
        </InputLabel>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          autoComplete="off"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoFocus
          placeholder={`${t("accept_exist_organization.fields.placeholders.email")}`}
          sx={{
            margin: `0 0 ${theme.spacing.spacing2xl}`,
            "& .MuiInputBase-root": {
              height: 44,
              padding: "0 14px",
              input: {
                padding: 0,
                ":placeholder-shown": {
                  color: theme.colors.colorsTextTextPlaceholder,
                },
              },
            },
          }}
        />
      </Box>
      <Box>
        <InputLabel
          shrink
          sx={{
            color: theme.colors.colorsTextTextSecondary,
            fontWeight: 500,
          }}
        >
          {t("accept_exist_organization.fields.labels.password")}
        </InputLabel>
        <TextField
          margin="normal"
          required
          fullWidth
          id="password"
          autoComplete="off"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoFocus
          type={securePass ? "password" : "text"}
          placeholder={`${t("accept_exist_organization.fields.placeholders.password")}`}
          InputProps={{
            endAdornment: renderShowPassBtn(),
          }}
          sx={{
            margin: `0 0 ${theme.spacing.spacing2xl}`,
            "& .MuiInputBase-root": {
              height: 44,
              padding: "0 14px",
              input: {
                padding: 0,
                ":placeholder-shown": {
                  color: theme.colors.colorsTextTextPlaceholder,
                },
              },
            },
          }}
        />
      </Box>
      <Link
        target="_blank"
        onClick={() => navigate("/users/password/forgot")}
        sx={{
          textDecoration: "none",
          ":hover": {
            textDecoration: "underline",
          },
        }}
      >
        <Typography
          sx={{
            ...theme.typography[".text-sm-semibold"],
            paddingBottom: theme.spacing.spacingXl,
            color: theme.colors.componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorFg,
            cursor: "pointer",
          }}
        >
          {t("accept_exist_organization.forgot_pass")}
        </Typography>
      </Link>
      <Box
        sx={{
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <Button
          label={`${t("accept_exist_organization.submitButton")}`}
          disabled={!email || !password}
          onClick={acceptInvitation}
          loadingInProgress={isLoginLoading || isLoadingAccept}
          sx={{
            pointerEvents: isLoginLoading || isLoadingAccept ? "none" : "initial",
            ml: "0 !important",
            width: "100%",
          }}
        />
      </Box>
    </Paper>
  );
}
