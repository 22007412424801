import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NumberInput from "../../../components/NumberInput/NumberInput";
import useTheme from "../../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import {
  IPointValues,
  PointKey,
} from "../../../../../../../store/services/models/installations/installationSettings";

export interface IPointInputsRowProps {
  points: { [key in PointKey]?: IPointValues };
  index: number;
  value: IPointValues;
  changePointsValues: (
    val: any,
    property: PointKey,
    type: "flow" | "outdoor"
  ) => void;
  minFlow: number;
  maxFlow: number;
  title: string;
}

export default function PointInputsRow({
  points,
  changePointsValues,
  index,
  value,
  maxFlow,
  minFlow,
  title,
}: IPointInputsRowProps) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");

  const [initialValue, setInitialValue] = useState<IPointValues>(value);
  const [minOutdoorTemp, setMinOutdoorTemp] = useState<number>(-50);
  const [maxOutdoorTemp, setMaxOutdoorTemp] = useState<number>(46);

  useEffect(() => {
    setInitialValue(value);
  }, [value]);

  useEffect(() => {
    if (index !== 0 && points) {
      setMinOutdoorTemp(
        points[`point${index}` as PointKey]?.outdoorTemp.value! + 1
      );
    }

    let nextPoint = points[`point${index + 2}` as PointKey];
    if (nextPoint && nextPoint.outdoorTemp.value <= value.outdoorTemp.value) {
      changePointsValues(
        value.outdoorTemp.value + 1,
        `point${index + 2}` as PointKey,
        "outdoor"
      );
    }

    setInitialValue(value);
  }, [points, index, value.outdoorTemp.value]);

  useEffect(() => {
    if (title === "Heating") {
      setMaxOutdoorTemp(index + 46);
    } else {
      setMaxOutdoorTemp(index + 48);
    }
  }, [index]);

  useEffect(() => {
    if (minFlow !== undefined && initialValue?.flowTemp.value) {
      if (minFlow > initialValue.flowTemp.value) {
        const property = `point${index + 1}`;
        changePointsValues(minFlow, property as PointKey, "flow");
      }
    }
  }, [minFlow, initialValue.flowTemp.value]);

  useEffect(() => {
    if (maxFlow !== undefined && initialValue?.flowTemp.value) {
      if (maxFlow < initialValue.flowTemp.value) {
        const property = `point${index + 1}`;
        changePointsValues(maxFlow, property as PointKey, "flow");
      }
    }
  }, [maxFlow, initialValue.flowTemp.value]);

  return (
    <Box
      key={`point_${index}`}
      sx={{
        display: "flex",
        alignItems: "center",
        width: "408px",
        justifyContent: "space-between",
      }}
    >
      <Typography
        sx={{
          ...theme.typography[".text-sm-medium"],
          color: theme.colors.colorsTextTextTertiary,
        }}
      >
        {`${t("pages.installationSettings.zones.fieldsLabels.point")} ${
          index + 1
        }`}
      </Typography>
      <NumberInput
        initialValue={initialValue?.outdoorTemp?.value}
        min={minOutdoorTemp}
        max={maxOutdoorTemp}
        changeValue={(val) => {
          const property = `point${index + 1}`;
          changePointsValues(val, property as PointKey, "outdoor");
        }}
        decimalPlaces={2}
        step={0.5}
        labelColor={theme.colors.colorsTextTextQuaternary}
        label={`${t(
          "pages.installationSettings.zones.fieldsLabels.outdoorTempereature"
        )} ${index + 1}`}
      />
      <NumberInput
        initialValue={initialValue?.flowTemp?.value}
        min={minFlow}
        max={maxFlow}
        changeValue={(val) => {
          const property = `point${index + 1}`;
          changePointsValues(val, property as PointKey, "flow");
        }}
        decimalPlaces={2}
        step={0.5}
        labelColor={theme.colors.colorsTextTextQuaternary}
        label={`${t(
          "pages.installationSettings.zones.fieldsLabels.flowTemperature"
        )} ${index + 1}`}
      />
    </Box>
  );
}
