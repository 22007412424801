import {
  ActiveOrInactiveEnum,
  IInstallationBaseValue,
  OnOrOffEnum,
  ZoneOperationsKey,
} from "./installationsCommon";

export enum InstallationZoneOperationModeEnum {
  NONE = "NONE",
  HEATING = "HEATING",
  COOLING = "COOLING",
  BOTH = "BOTH",
}

export enum InstallationZoneOperationControllerEnum {
  CURVE = "CURVE",
  FIXED = "FIXED",
}

export enum InstallationOperationSpaceModeEnum {
  AUTO = "AUTO",
  HEATING = "HEATING",
  COOLING = "COOLING",
  OFF = "OFF",
}

export enum InstallationOperationHPStateEnum {
  STANDBY = "STANDBY",
  AUTOMATIC = "AUTOMATIC",
  USER_SPECIFIC = "USER_SPECIFIC",
}

export enum InstallationComissioningZoneCircuitTypeEnum {
  MIXING = "MIXING",
  DIRECT = "DIRECT",
}

export enum StageStateEnum {
  OFF = "OFF",
  ON_COMPRESSOR_OFF = "ON_COMPRESSOR_OFF",
  AVAILABLE = "AVAILABLE",
}

export enum AdditionalSourcePriorityForDhwEnum {
  INLINE = "INLINE",
  DHW_TANK = "DHW_TANK",
}

export enum AdditionalSourcePriorityForHeatingEnum {
  INLINE = "INLINE",
  BUFFER_TANK = "BUFFER_TANK",
}

export enum SeasonSwitchEnum {
  OUTDOOR_SENSOR = "OUTDOOR_SENSOR",
  DIGITAL_SIGNAL = "DIGITAL_SIGNAL",
}

export interface IPointValues {
  outdoorTemp: IInstallationBaseValue;
  flowTemp: IInstallationBaseValue;
}

export interface ISetPointValues {
  outdoorTemp: number;
  flowTemp: number;
}

export type PointKey = "point1" | "point2" | "point3" | "point4" | "point5";

export interface IInstallationZoneOperation {
  heatingController: InstallationZoneOperationControllerEnum;
  coolingController: InstallationZoneOperationControllerEnum;
  preferredRoomTemp: IInstallationBaseValue;
  preferredFixedFlowHeatingTemp: IInstallationBaseValue;
  preferredFixedFlowCoolingTemp: IInstallationBaseValue;
  heatingCurve: {
    [key in PointKey]?: IPointValues;
  };
  coolingCurve: {
    [key in PointKey]?: IPointValues;
  };
}

export interface IInstallationDHWAntoLegionella {
  state: ActiveOrInactiveEnum;
  temp: IInstallationBaseValue;
  duration: number;
  startHour: number;
  endHour: number;
  interval: number;
}

export interface IInstallationDHWSettings {
  preferredTemp: IInstallationBaseValue;
  deltaDeviationTemp: IInstallationBaseValue;
  antiLegionella: IInstallationDHWAntoLegionella;
}

export interface IInstallationsOperationSettings {
  spaceMode: InstallationOperationSpaceModeEnum;
  hpState: InstallationOperationHPStateEnum;
  dhwState: OnOrOffEnum;
}

export interface IInstallationUserSettingsVacationMode {
  state: ActiveOrInactiveEnum;
  endDate: string;
  reduceHeatingDeltaTemp: IInstallationBaseValue;
  reduceDHWDeltaTemp: IInstallationBaseValue;
}

export interface IInstallationUserSettings {
  paired: boolean;
  vacationMode: IInstallationUserSettingsVacationMode;
  heatingSeasonStartTemp: IInstallationBaseValue;
  coolingSeasonStartTemp: IInstallationBaseValue;
}

export type ZoneComissioningKey = "zone1" | "zone2" | "zone3" | "zone4";

export interface IZoneComissioningValues {
  circuitType: InstallationComissioningZoneCircuitTypeEnum;
  mode: InstallationZoneOperationModeEnum;
  minFlowHeatTemp: IInstallationBaseValue;
  maxFlowHeatTemp: IInstallationBaseValue;
  minFlowCoolTemp: IInstallationBaseValue;
  maxFlowCoolTemp: IInstallationBaseValue;
  minIndoorHeatTemp: IInstallationBaseValue;
  maxIndoorHeatTemp: IInstallationBaseValue;
  minIndoorCoolTemp: IInstallationBaseValue;
  maxIndoorCoolTemp: IInstallationBaseValue;
}

export interface IAdditionalDhwHeatingSource {
  state: ActiveOrInactiveEnum;
  inputPower: IInstallationBaseValue;
  outdoorThresholdTemp: IInstallationBaseValue;
}

export interface IAdditionalInlineHeatingSource {
  state: ActiveOrInactiveEnum;
  inputPower: IInstallationBaseValue;
  stage1?: StageStateEnum;
  stage2?: StageStateEnum;
  stage3?: StageStateEnum;
  outdoorThresholdTemp: IInstallationBaseValue;
  heatBalanceForDwh: IInstallationBaseValue;
  heatBalanceForHeating: IInstallationBaseValue;
}

export interface IBufferTank {
  state: ActiveOrInactiveEnum;
  inputPower: IInstallationBaseValue;
  outdoorThresholdTemp: IInstallationBaseValue;
}

export interface IPriorities {
  heatingMinTemp: IInstallationBaseValue;
  heatingDhwMinTemp: IInstallationBaseValue;
  coolingMinTemp: IInstallationBaseValue;
  coolingDhwMinTemp: IInstallationBaseValue;
  additionalSourcePriorityForHeating: AdditionalSourcePriorityForHeatingEnum;
  additionalSourcePriorityForDhw: AdditionalSourcePriorityForDhwEnum;
}

export interface IInstallationInstallerSettingsComissioningDHW {
  dhwMinTemp: IInstallationBaseValue;
  dhwMaxTemp: IInstallationBaseValue;
}

export interface IInstallationInstallerSettingsComissioning {
  zoneOperations: {
    [key in ZoneOperationsKey]?: IZoneComissioningValues;
  };
  additionalDhwHeatingSource: IAdditionalDhwHeatingSource;
  additionalInlineHeatingSource: IAdditionalInlineHeatingSource;
  bufferTank: IBufferTank;
  priorities: IPriorities;
  dhw: IInstallationInstallerSettingsComissioningDHW;
}

export interface IWaterPumpP0Speed {
  heating: IInstallationBaseValue;
  cooling: IInstallationBaseValue;
  dhw: IInstallationBaseValue;
}

export interface IInstallationInstallerSettingsOthers {
  seasonSwitch: SeasonSwitchEnum;
  waterPumpP0Speed: IWaterPumpP0Speed;
}

export interface IInstallationInstallerSettings {
  commissioning: IInstallationInstallerSettingsComissioning;
  others: IInstallationInstallerSettingsOthers;
}

//SET HELPERS MODELS
export interface ISetOperationZonesParams {
  heatingController: InstallationZoneOperationControllerEnum;
  coolingController: InstallationZoneOperationControllerEnum;
  preferredRoomTemp: number;
  preferredFixedFlowHeatingTemp: number;
  preferredFixedFlowCoolingTemp: number;
  heatingCurve: {
    [key in PointKey]?: ISetPointValues;
  };
  coolingCurve: {
    [key in PointKey]?: ISetPointValues;
  };
}

export interface ISetAntilegionellaSettings {
  state: ActiveOrInactiveEnum;
  temp: number;
  duration: number;
  startHour: number;
  endHour: number;
  interval: number;
}

export interface ISetDhwSettings {
  enabled: boolean;
  preferredTemp: number;
  deltaDeviationTemp: number;
  antiLegionella: ISetAntilegionellaSettings;
}

export interface ISetOperationSettings {
  spaceMode: InstallationOperationSpaceModeEnum;
  hpState: InstallationOperationHPStateEnum;
  dhwState: OnOrOffEnum;
}

export interface ISetVacationMode {
  state: ActiveOrInactiveEnum;
  startDate: string; //"2024-08-04" Schedule start date in user timezone. Timezone will be picked up from device datetime.
  endDate: string; //"2024-08-04" Schedule end date in user timezone. Timezone will be picked up from device datetime.
  reduceHeatingDeltaTemp: number;
  reduceDHWDeltaTemp: number;
}

export interface ISetUserSettings {
  vacationMode: ISetVacationMode;
  heatingSeasonStartTemp: number;
  coolingSeasonStartTemp: number;
  language: string; //ISO-639 language code
  dateTime: string; //($date-time) example: 2007-12-03T10:15:30+01:00 ISO-8601 date-time with offset
}

export interface ISetInstallerSettignsComissioningZoneOperation {
  circuitType: InstallationComissioningZoneCircuitTypeEnum;
  mode: InstallationZoneOperationModeEnum;
  minFlowHeatTemp: number;
  maxFlowHeatTemp: number;
  minFlowCoolTemp: number;
  maxFlowCoolTemp: number;
}

export interface ISetInstallerSettignsComissioningAdditionalDHWHeatingSource {
  state: ActiveOrInactiveEnum;
  inputPower: number;
  outdoorThresholdTemp: number;
}

export interface ISetInstallerSettignsComissioningAdditionalInlineHeatingSource {
  inputPower: number;
  stage1?: StageStateEnum;
  stage2?: StageStateEnum;
  stage3?: StageStateEnum;
  additionalSourcePriorityForDhw: AdditionalSourcePriorityForDhwEnum;
  additionalSourcePriorityForHeating: AdditionalSourcePriorityForHeatingEnum;
  outdoorThresholdTemp: number;
  heatBalanceThresholdForDwh: number;
  heatBalanceThresholdForHeating: number;
}

export interface ISetInstallerSettignsComissioningBufferTank {
  state: ActiveOrInactiveEnum;
  inputPower: number;
  outdoorThresholdTemp: number;
}

export interface ISetInstallerSettignsComissioningPriorities {
  heatingMinTemp: number;
  heatingDhwMinTemp: number;
  coolingMinTemp: number;
  coolingDhwMinTemp: number;
}

export interface ISetComissioningIstallerSettings {
  zoneOperations: {
    [key in ZoneOperationsKey]?: ISetInstallerSettignsComissioningZoneOperation;
  };
  additionalDhwHeatingSource: ISetInstallerSettignsComissioningAdditionalDHWHeatingSource;
  additionalInlineHeatingSource: ISetInstallerSettignsComissioningAdditionalInlineHeatingSource;
  bufferTank: ISetInstallerSettignsComissioningBufferTank;
  priorities: ISetInstallerSettignsComissioningPriorities;
}

export interface ISetOthersInstallerSettings {
  seasonSwitch: SeasonSwitchEnum;
  waterPumpP0Speed: {
    heating: number;
    cooling: number;
    dhw: number;
  };
}

export interface ISetInstallerSettings {
  commissioning: ISetComissioningIstallerSettings;
  others: ISetOthersInstallerSettings;
}

//main
//GET
export interface IInstallationSettingsByIdResponse {
  lastUpdatedAt: string;
  zonesCount: IInstallationBaseValue;
  zoneOperations: {
    [key in ZoneOperationsKey]?: IInstallationZoneOperation;
  };
  dhw: IInstallationDHWSettings;
  operation: IInstallationsOperationSettings;
  userSettings: IInstallationUserSettings;
  installerSettings: IInstallationInstallerSettings;
}

//SET
export interface ISetInstallationSettings {
  zonesCount?: number;
  zoneOperations?: {
    [key in ZoneOperationsKey]?: ISetOperationZonesParams;
  };
  dhw: ISetDhwSettings;
  operation: ISetOperationSettings;
  userSettings: ISetUserSettings;
  installerSettings: ISetInstallerSettings;
}
