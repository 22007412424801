import React, { useState, useEffect, useRef } from "react";
import { APIProvider, useMapsLibrary } from "@vis.gl/react-google-maps";
import { Box } from "@mui/material";
import useTheme from "../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { SearchLG, XClose } from "untitledui-js-base";
import useResize from "../../hooks/useResize";

// const API_KEY = "AIzaSyC_Sni4YHxJpB1O3Chc9uNWzjfpBb9UBUg";

const AddressAutocompleteComponent = ({
  onAddressSelect,
  address,
  placeholder,
  mobileInnerInput,
}: {
  onAddressSelect: (address: string, country?: string) => void;
  address: string | null;
  placeholder: string;
  mobileInnerInput?: boolean;
}) => {
  const API_KEY = process.env.REACT_APP_ESCLOUD_GOOGLE_MAPS_API_KEY || "";
  const [selectedPlace, setSelectedPlace] = useState<
    google.maps.places.PlaceResult | null | undefined
  >(null);

  const [currentAddress, setCurrentAddress] = useState<string | null>("");

  useEffect(() => {
    setCurrentAddress(address);
  }, [address]);

  useEffect(() => {
    const countryPlace = selectedPlace?.address_components?.find((a) =>
      a.types.includes("country")
    );
    onAddressSelect(
      selectedPlace?.formatted_address as string,
      countryPlace?.short_name
    );
  }, [selectedPlace]);

  return (
    <APIProvider
      apiKey={API_KEY}
      solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"
    >
      <PlaceAutocomplete
        onPlaceSelect={setSelectedPlace}
        address={currentAddress}
        placeholder={placeholder}
        mobileInnerInput={mobileInnerInput}
      />
    </APIProvider>
  );
};

interface PlaceAutocompleteProps {
  onPlaceSelect: (
    place: google.maps.places.PlaceResult | null | undefined
  ) => void;
  address: string | null;
  placeholder: string;
  mobileInnerInput?: boolean;
}

const PlaceAutocomplete = ({
  onPlaceSelect,
  address,
  placeholder,
  mobileInnerInput,
}: PlaceAutocompleteProps) => {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");

  const { width, height } = useResize();

  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary("places");

  const [inputValue, setInputValue] = useState<string | null | undefined>("");
  const [previousSelectedAddress, setPreviousSelectedAddress] =
    useState<any>("");

  const [isMobileScreenActive, setIsMobileScreenActive] =
    useState<boolean>(false);

  useEffect(() => {
    if (mobileInnerInput) {
      inputRef.current?.focus();
    }
  }, [mobileInnerInput]);

  useEffect(() => {
    setInputValue(address);
    setPreviousSelectedAddress(address);
  }, [address]);

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ["geometry", "name", "formatted_address", "address_components"],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  const onInputChange = (e: any) => {
    setInputValue(e.target.value);

    if (!e.target.value) {
      onPlaceSelect(undefined);
    }
  };

  const onInputBlur = () => {
    setInputValue(previousSelectedAddress);
  };

  const onWrapperClick = () => {
    if (!mobileInnerInput && width < 700) {
      inputRef.current?.blur();
      setIsMobileScreenActive(true);
    }
  };

  return (
    <>
      <Box
        onClick={() => onWrapperClick()}
        sx={{
          width: "100%",
          position: "relative",
          input: {
            boxSizing: "border-box",
            height: "44px",
            width: "100%",
            padding: "0 15px 0 30px",
            borderRadius: theme.radius.radiusMd,
            border: `1px solid ${theme.colors.colorsBorderBorderPrimary}`,
            fontFamily: `"Inter", "Roboto", "Arial", sans-serif`,
            fontSize: "1rem",
            lineHeight: "1.4375em",
            color: theme.colors.colorsTextTextPrimary,
            "&::placeholder": {
              color: theme.colors.colorsTextTextPlaceholder,
              opacity: ".5",
            },
            "&:hover": {
              borderColor: theme.colors.colorsTextTextPrimary,
            },
            "&:focus-visible": {
              outline: "-webkit-focus-ring-color auto 1px",
              outlineColor: "#6c6b6a",
            },
          },
        }}
      >
        <SearchLG
          style={{
            position: "absolute",
            top: "14px",
            left: "10px",
            color: theme.colors.colorsTextTextPlaceholder,
            opacity: ".5",
          }}
          size="16"
          strokeWidth={2}
        />
        <input
          type="text"
          value={inputValue || ""}
          ref={inputRef}
          placeholder={placeholder}
          onChange={onInputChange}
          onBlur={onInputBlur}
        />
      </Box>
      {isMobileScreenActive && (
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: theme.colors.colorsBackgroundBgPrimary,
            zIndex: 3,
          }}
        >
          <Box
            sx={{
              maxWidth: "500px",
              width: "100%",
              m: "0 auto",
              p: theme.spacing.spacingXl,
            }}
          >
            <Box
              component={"button"}
              sx={{
                cursor: "pointer",
                border: "none",
                backgroundColor: "initial",
                display: "flex",
                alignItems: "center",
                mb: theme.spacing.spacingXl,
              }}
              onClick={() => setIsMobileScreenActive(false)}
            >
              <XClose
                strokeWidth={2}
                size="20"
                style={{ color: theme.colors.colorsBorderBorderBrandSolid }}
              />
            </Box>
            <PlaceAutocomplete
              address={address}
              onPlaceSelect={onPlaceSelect}
              placeholder={placeholder}
              mobileInnerInput
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default AddressAutocompleteComponent;
