import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SwitchHorizontal01 } from "untitledui-js-base";
import useTheme from "../../../../../../../theme/hooks/useTheme";
import NumberInput from "../../../components/NumberInput/NumberInput";
import {
  IPointValues,
  PointKey,
} from "../../../../../../../store/services/models/installations/installationSettings";
import PointInputsRow from "./PointInputsRow";

export interface ISetPointSectionProps {
  points: { [key in PointKey]?: IPointValues };
  changePointsValues: (
    val: any,
    property: PointKey,
    type: "flow" | "outdoor"
  ) => void;
  updateTypeToFixedFlow: () => void;
  maxFlowTemp: number;
  minFlowTemp: number;
  title: string;
}

export default function CurveSetPointSection({
  points,
  changePointsValues,
  updateTypeToFixedFlow,
  minFlowTemp,
  maxFlowTemp,
  title,
}: ISetPointSectionProps) {
  const { t } = useTranslation("cloud_ui");
  const theme = useTheme();

  const [minFlow, setMinFlow] = useState<number>(minFlowTemp);
  const [maxFlow, setMaxFlow] = useState<number>(maxFlowTemp);

  useEffect(() => {
    if (minFlowTemp) {
      setMinFlow(minFlowTemp);
    }
  }, [minFlowTemp]);

  useEffect(() => {
    if (maxFlowTemp) {
      setMaxFlow(maxFlowTemp);
    }
  }, [maxFlowTemp]);

  return (
    <>
      {Object.entries(points).map(([key, value], index) => {
        return value ? (
          <PointInputsRow
            points={points}
            title={title}
            key={`point_${index}`}
            changePointsValues={changePointsValues}
            index={index}
            maxFlow={maxFlow}
            minFlow={minFlow}
            value={value}
          />
        ) : null;
      })}
      {/* <Box
        component={"button"}
        onClick={updateTypeToFixedFlow}
        sx={{
          mb: theme.spacing.spacingXl,
          border: "none",
          height: "36px",
          width: "fit-content",
          padding: `${theme.spacing.spacingMd} ${theme.spacing.spacingLg}`,
          color:
            theme.colors
              .componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorFg,
          backgroundColor: "initial",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          "&:hover": {
            color:
              theme.colors
                .componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorFgHover,
          },
        }}
      >
        <SwitchHorizontal01 size="20" strokeWidth={2} />
        <Typography
          sx={{
            ...theme.typography[".text-sm-semibold"],
            ml: theme.spacing.spacingXs,
          }}
        >
          {t(
            "pages.installationSettings.zones.buttons.useFixedFlowTemperature"
          )}
        </Typography>
      </Box> */}
    </>
  );
}
